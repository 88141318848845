import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

import GatewayChart from 'components/dashboard/chart/GatewayChart';
import SensorChart from 'components/dashboard/chart/SensorChart';
import FacilityChart from './chart/FacilityChart';
import DataTraffic from './chart/DataTraffic';

import ClusterMap from './chart/ClusterMap';
import EventChart from './chart/EventChart';
import PredictiveAnalysis from './predictive-analysis';
import ProjectChart from './chart/ProjectChart';
import { MapsContext } from 'context/Context';
import AppContext, {
  CourseContext,
  LoginUserContext,
  SocketContext
} from 'context/Context';
import classNames from 'classnames';
import Background from '../common/Background';
import SoftBadge from '../common/SoftBadge';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import moment from 'moment/moment';
import IconButton from 'components/common/IconButton';
import { getColor } from '../../helpers/utils';
import TopArea from './component/TopArea';
import ClusterMapbox from './chart/ClusterMapbox';
import SubsidenceOccurrenceCityChart from './chart/SubsidenceOccurrenceCityChart';
import SubsidenceOccurrenceSubCityChart from './chart/SubsidenceOccurrenceSubCityChart';
import SubsidenceRiskPredictionChart from './chart/SubsidenceRiskPredictionChart';
import SubsidenceRiskPredictionResultChart from './chart/SubsidenceRiskPredictionResultChart';
import WithAuthorization from '../common/WithAuthorization';

const Dashboard = () => {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  const { setSelectedProject, setSelectedRegion, setSendRegion } =
    useContext(MapsContext);
  const { setMiniMapSelectedSensor } = useContext(MapsContext);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);

    setSelectedProject({});
    setSelectedRegion({});
    setSendRegion({});

    setMiniMapSelectedSensor('');

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  return (
    <>
      {/*<Row className="g-3 mb-3">*/}
      {/*  <Col sm={12}>*/}
      {/*    <TopArea />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row className="g-3 mb-3">
        <Col xl={6} lg={6}>
          {/*<ClusterMap className="h-100" />*/}
          <ClusterMapbox className="h-100" />
        </Col>
        <Col xl={6} lg={6}>
          <Row className="g-3 mb-3">
            <Col xl={3} xs={6}>
              <ProjectChart />
            </Col>
            <Col xl={3} xs={6}>
              <FacilityChart />
            </Col>
            <Col xl={3} xs={6}>
              <GatewayChart />
            </Col>
            <Col xl={3} xs={6}>
              <SensorChart />
            </Col>
          </Row>
          <Row className="g-3">
            <Col xl={3} xs={6}>
              <SubsidenceOccurrenceCityChart />
            </Col>
            <Col xl={3} xs={6}>
              <SubsidenceOccurrenceSubCityChart />
            </Col>
            <Col xl={3} xs={6}>
              <SubsidenceRiskPredictionChart />
            </Col>
            <Col xl={3} xs={6}>
              <SubsidenceRiskPredictionResultChart />
            </Col>
          </Row>
          {/*<Row className="g-3">*/}
          {/*  <Col>*/}
          {/*    <EventChart />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xl={7} xs={12}>
          {/*<DataTraffic className="h-100" />*/}
          <EventChart className="h-100" />
        </Col>
        <Col xl={5} xs={12}>
          <PredictiveAnalysis />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
