import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppContext, {
  ChatContext,
  LoginUserContext,
  SocketContext
} from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import CourseProvider from 'components/app/e-learning/CourseProvider';

import { Cookies } from 'react-cookie';

import MapsProvider from 'components/maps/MapsProvider';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ActionProvider from '../components/event/process/ActionProvider';
import SensorProvider from '../components/management-target/sensor/SensorProvider';

const cookies = new Cookies();

const MainLayout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  // const isKanban = pathname.includes('kanban');
  const isKanban = true;
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  const { setLoginUser, isLoginLoading, setIsLoginLoading } =
    useContext(LoginUserContext);
  const { stompEventClient, stompChatClient } = useContext(SocketContext);
  const { setIsAllReadChat, getChatRoom, currentChatRoom, isOpenChatRoom } =
    useContext(ChatContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);

    let isAuthorized = window.sessionStorage.getItem('isAuthorized');

    if (!isAuthorized || !cookies.get('Authorization')) {
      navigate('/login', { state: pathname });
      return;
    } else {
      setLoginUser(JSON.parse(window.sessionStorage.getItem('loginUser')));
      setIsLoginLoading(true);
    }

    stompEventClient.connect({}, frame => {
      // console.log('frame : ', frame);
      stompEventClient.subscribe('/topic/event', message => {
        // console.log('MainLayout /topic/event : ', message);
        queryClient.invalidateQueries(['getBottomEvents']);

        let receivedMsg = JSON.parse(message.body);
        // console.log('MainLayout /topic/event parse : ', receivedMsg);

        if (parseInt(receivedMsg.eventLevel) <= 3) {
          toast.error(`${receivedMsg.content}`, {
            theme: 'colored'
          });
        }
      });
    });

    stompChatClient.connect({}, frame => {
      // console.log('frame : ', frame);
      stompChatClient.subscribe('/chat', message => {
        // console.log('MainLayout /chat : ', message);
        let receivedMsg = JSON.parse(message.body);
        // console.log('MainLayout /chat receivedMsg : ', receivedMsg);
        let loginUser = JSON.parse(window.sessionStorage.getItem('loginUser'));
        if (receivedMsg.sender.key !== loginUser.userId) {
          setIsAllReadChat(false);
        }

        queryClient.invalidateQueries(['getChatrooms']);

        if (
          receivedMsg.sender.key === 'SYSTEM' &&
          receivedMsg.message.includes('이벤트 상태가 ') &&
          receivedMsg.chatroom.key ===
            window.sessionStorage.getItem('currentChatRoomId')
        ) {
          getChatRoom(receivedMsg.chatroom.key);
        }
      });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    isLoginLoading && (
      <div className={isFluid ? 'container-fluid' : 'container'}>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <ActionProvider>
          <SensorProvider>
            <MapsProvider>
              <ProductProvider>
                <CourseProvider>
                  <div className={classNames('content', { 'pb-0': isKanban })}>
                    <NavbarTop />
                    {/*------ Main Routes ------*/}
                    <Outlet />
                    {!isKanban && <Footer />}
                  </div>
                </CourseProvider>
              </ProductProvider>
            </MapsProvider>
          </SensorProvider>
        </ActionProvider>
      </div>
    )
  );
};

export default MainLayout;
