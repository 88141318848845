import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { getColor } from '../../../../../helpers/utils';
import mapboxgl from 'mapbox-gl';
import mapMarker from '../../../../../assets/img/icons/map-marker.png';
import FieldInfo from './FieldInfo';
import RoadInfo from './RoadInfo';
import PropTypes from 'prop-types';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const RoadFacilitiesDetailBaseInfo = ({ facility }) => {
  const roadFacilityMapRef = useRef();
  const [roadFacilityMapInstance, setRoadFacilityMapInstance] = useState(null);

  useEffect(() => {
    MakeRoadFacilityMapboxMap();
  }, []);

  useEffect(() => {
    if (facility?.id !== undefined) {
      if (roadFacilityMapInstance !== null) {
        roadFacilityMapInstance.setCenter([
          facility?.location.longitude,
          facility?.location.latitude
        ]);
        MakeMapboxRoadFacilityMarker(facility);
      }
    }
  }, [facility]);

  const MakeRoadFacilityMapboxMap = () => {
    const map = new mapboxgl.Map({
      container: roadFacilityMapRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [126.7901852, 37.6385557],
      zoom: 17,
      attributionControl: false
    });

    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    setRoadFacilityMapInstance(map);
  };

  const MakeMapboxRoadFacilityMarker = facility => {
    // Remove existing markers
    const markers = document.getElementsByClassName('mapboxgl-marker');
    while (markers.length > 0) {
      markers[0].parentNode.removeChild(markers[0]);
    }

    new mapboxgl.Marker({ element: createMarkerElement(facility?.name) })
      .setLngLat([facility?.location.longitude, facility?.location.latitude])
      .addTo(roadFacilityMapInstance);
  };

  const createMarkerElement = name => {
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = `url(${mapMarker})`;
    el.style.width = '30px';
    el.style.height = '30px';
    el.style.backgroundSize = '100%';

    const label = document.createElement('div');
    label.className = 'marker-label';
    label.textContent = name;
    label.style.whiteSpace = 'nowrap'; // Ensure text is in a single line
    el.appendChild(label);

    return el;
  };

  return (
    <Card>
      <Card.Header>
        <h6>도로·시설물</h6>
      </Card.Header>
      <Card.Body style={{ backgroundColor: getColor('gray-100') }}>
        <Row xs={12}>
          <Col md={3} xl={3}>
            <div
              ref={roadFacilityMapRef}
              id="roadFacilityMap"
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                minHeight: '260px'
              }}
              className={'border rounded'}
            ></div>
          </Col>
          <Col md={9} xl={9} className={'px-4'}>
            {facility.type === 'FD' ? (
              <FieldInfo facility={facility} />
            ) : (
              <RoadInfo facility={facility} />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

RoadFacilitiesDetailBaseInfo.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.shape({
      longitude: PropTypes.number,
      latitude: PropTypes.number
    }),
    name: PropTypes.string,
    type: PropTypes.string,
    address: PropTypes.string
  })
};

export default RoadFacilitiesDetailBaseInfo;

// import React, { useEffect, useRef, useState } from 'react';
// import { Card, Col, Row } from 'react-bootstrap';
// import { getColor } from '../../../../../helpers/utils';
// import Flex from '../../../../common/Flex';
// import { fromLonLat } from 'ol/proj';
// import { Feature, Map, View } from 'ol';
// import { defaults } from 'ol/control';
// import { Group, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
// import { XYZ } from 'ol/source';
// import { Point } from 'ol/geom';
// import { Fill, Icon, Style, Text } from 'ol/style';
// import mapMarker from '../../../../../assets/img/icons/map-marker.png';
// import VectorSource from 'ol/source/Vector';
// import FieldInfo from './FieldInfo';
// import RoadInfo from './RoadInfo';

// const RoadFacilitiesDetailBaseInfo = ({ facility }) => {
//   const roadFacilityMapRef = useRef();
//   const roadFacilityMapTooltipRef = useRef();
//   const [roadFacilityMapInstance, setRoadFacilityMapInstance] = useState(null);

//   useEffect(() => {
//     MakeRoadFacilityVworldMap();
//   }, []);

//   useEffect(() => {
//     if (facility?.id !== undefined) {
//       if (roadFacilityMapInstance !== null) {
//         roadFacilityMapInstance
//           .getView()
//           .setCenter(
//             fromLonLat([
//               facility?.location.longitude,
//               facility?.location.latitude
//             ])
//           );
//         MakeVworldRoadFacilityMarker(facility);
//       }
//     }
//   }, [facility]);

//   const MakeRoadFacilityVworldMap = () => {
//     const miniMap = new Map({
//       controls: defaults({ zoom: false, rotate: false }),
//       // layers: [vworldLayers],
//       target: 'roadFacilityMap',
//       view: new View({
//         center: fromLonLat([126.7901852, 37.6385557]),
//         // center: fromLonLat([
//         //   facilityInfo.location.longitude,
//         //   facilityInfo.location.latitude
//         // ]),
//         maxZoom: 19,
//         minZoom: 19,
//         zoom: 19
//       })
//     });

//     const baseLayerGroup = new Group({
//       layers: [
//         new TileLayer({
//           source: new XYZ({
//             url: 'https://api.vworld.kr/req/wmts/1.0.0/BB2A44B3-37F9-31C5-8C81-8DBFF739A3E4/Base/{z}/{y}/{x}.png'
//           }),
//           title: 'base-vworld-base',
//           visible: true,
//           // properties: { name: 'base-vworld-base' },
//           minZoom: 6,
//           maxZoom: 19,
//           zIndex: 2
//           // preload: Infinity
//         })
//       ]
//     });
//     miniMap.addLayer(baseLayerGroup);

//     setRoadFacilityMapInstance(miniMap);
//   };

//   const MakeVworldRoadFacilityMarker = facility => {
//     roadFacilityMapInstance.getLayers().forEach(layer => {
//       if (layer.get('name') && layer.get('name') === 'facilityMarker') {
//         roadFacilityMapInstance.removeLayer(layer);
//       }
//     });

//     let marker = new Feature({
//       geometry: new Point(
//         fromLonLat([facility?.location.longitude, facility?.location.latitude])
//       ),
//       name: facility?.name,
//       type: facility?.type,
//       address: facility?.address,
//       id: facility?.id
//     });

//     let myStyle = new Style({
//       image: new Icon({
//         anchor: [0.5, 1],
//         src: mapMarker,
//         scale: 0.6
//       }),
//       text: new Text({
//         text: marker.get('name'),
//         font: 'bold 12px Pretendard',
//         offsetX: 0,
//         offsetY: 10,
//         fill: new Fill({
//           color: 'black'
//         })
//       })
//     });
//     marker.setStyle(myStyle);
//     // create a vector layer and add the marker feature to it
//     let markerLayer = new VectorLayer({
//       source: new VectorSource({
//         features: [marker]
//       }),
//       zIndex: 99,
//       visible: true,
//       name: 'facilityMarker'
//     });

//     // add myLayer
//     roadFacilityMapInstance.addLayer(markerLayer);
//   };

//   return (
//     <Card>
//       <Card.Header>
//         <h6>도로·시설물</h6>
//       </Card.Header>
//       <Card.Body style={{ backgroundColor: getColor('gray-100') }}>
//         <Row xs={12}>
//           <Col md={3} xl={3}>
//             <div
//               ref={roadFacilityMapRef}
//               id="roadFacilityMap"
//               style={{
//                 position: 'relative',
//                 width: '100%',
//                 height: '100%',
//                 minHeight: '260px'
//                 // maxWidth: '268px'
//               }}
//               className={'border rounded'}
//             >
//               <div
//                 ref={roadFacilityMapTooltipRef}
//                 id="info"
//                 style={{
//                   position: 'absolute',
//                   display: 'inline-block',
//                   height: 'auto',
//                   width: 'auto',
//                   zIndex: '100',
//                   backgroundColor: '#333',
//                   color: '#fff',
//                   textAlign: 'center',
//                   borderRadius: '4px',
//                   padding: '5px',
//                   left: '50%',
//                   transform: 'translateX(3%)',
//                   visibility: 'hidden',
//                   pointerEvents: 'none'
//                 }}
//               ></div>
//             </div>
//           </Col>
//           <Col md={9} xl={9} className={'px-4'}>
//             {facility.type === 'FD' ? (
//               <FieldInfo facility={facility} />
//             ) : (
//               <RoadInfo facility={facility} />
//             )}
//           </Col>
//         </Row>
//       </Card.Body>
//       {/*<Card.Footer*/}
//       {/*  as={Flex}*/}
//       {/*  justifyContent="end"*/}
//       {/*  style={{ backgroundColor: getColor('gray-100') }}*/}
//       {/*>*/}
//       {/*  /!*<IconButton variant="falcon-default" transform="shrink-3" size="sm">*!/*/}
//       {/*  /!*  수정*!/*/}
//       {/*  /!*</IconButton>*!/*/}
//       {/*  <IconButton variant="falcon-default" transform="shrink-3" size="sm">*/}
//       {/*    목록*/}
//       {/*  </IconButton>*/}
//       {/*</Card.Footer>*/}
//     </Card>
//   );
// };

// export default RoadFacilitiesDetailBaseInfo;
