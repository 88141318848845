import React, { useRef, useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import moment from 'moment/moment';
import { chainAxios } from 'helpers/chainAxios';
import { useFacilityPredictedSafety } from '../../hook/useMapsDetail';
import FalconCardHeader from '../../../../../common/FalconCardHeader';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ value, borderColor, name }, index) =>
        `<span class='text-800'>${name}</span><br/><div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600 fs--1'>${
                value === '4'
                  ? 'A등급'
                  : value === '3'
                  ? 'B등급'
                  : value === '2'
                  ? 'C등급'
                  : value === '1'
                  ? 'D등급'
                  : 'E등급'
              }</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['safetyClass'],
    show: false
  },
  xAxis: {
    type: 'category',
    data: data.dates,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YY-MM-DD')
    }
  },
  yAxis: {
    type: 'value',
    min: 0,
    max: 4,
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 8,
      formatter: val =>
        val === 4
          ? 'A등급'
          : val === 3
          ? 'B등급'
          : val === 2
          ? 'C등급'
          : val === 1
          ? 'D등급'
          : 'E등급'
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  // dataZoom: [
  //   {
  //     show: true,
  //     type: 'inside',
  //     filterMode: 'none',
  //     xAxisIndex: [0],
  //     startValue: -20,
  //     endValue: 20
  //   }
  //   // {
  //   //   show: true,
  //   //   type: 'inside',
  //   //   filterMode: 'none',
  //   //   yAxisIndex: [0],
  //   //   startValue: -20,
  //   //   endValue: 20
  //   // }
  // ],
  series: [
    {
      name: '등급',
      type: 'line',
      data: data.safetyClass,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      },
      animation: true,
      animationDuration: 1000, // 애니메이션의 지속 시간 설정
      animationDurationUpdate: 1000, // 업데이트 시 애니메이션의 지속 시간 설정
      animationEasing: 'linear', // 애니메이션의 진행 방식 설정
      animationEasingUpdate: 'linear' // 업데이트 시 애니메이션의 진행 방식 설정
    }
  ],
  grid: {
    right: '18px',
    left: '40px',
    bottom: '15%',
    top: '5%',
    containLabel: false
  }
});

const FacilityPredictedSafetyChart = ({ facilityId }) => {
  const {
    data: facilityPredictedSafetyDatas,
    setFacilityPredictedSafetyFacilityId,
    setFacilityPredictedSafetySearchPeriod
  } = useFacilityPredictedSafety();

  const [
    facilityPredicatedSafetyChartData,
    setFacilityPredicatedSafetyChartData
  ] = useState({});

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('최근 1개월');

  useEffect(() => {
    if (!!facilityId) {
      setFacilityPredictedSafetyFacilityId(facilityId);
    }
  }, [facilityId]);

  useEffect(() => {
    if (
      !!facilityPredictedSafetyDatas &&
      JSON.stringify(facilityPredictedSafetyDatas) !== '[]'
    ) {
      // console.table(realDataSendDatas);
      makeFacilityPredicatedSafetyChartData(facilityPredictedSafetyDatas);
    }
  }, [facilityPredictedSafetyDatas]);

  const makeFacilityPredicatedSafetyChartData = chartDatas => {
    let orginDates = [];
    let orginValues = [];

    chartDatas.chartData.forEach(data => {
      orginDates.push(data.x);
      orginValues.push(data.y1);
    });

    setFacilityPredicatedSafetyChartData({
      dates: orginDates,
      safetyClass: orginValues
    });
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }

    setFacilityPredictedSafetySearchPeriod(value);
  };

  return (
    <>
      {/*<Card className="mb-3">*/}
      <FalconCardHeader
        // title="시설물 예측 안전 등급"
        light
        titleTag="h6"
        endEl={
          <Dropdown onSelect={onSelectSearchPeriod}>
            <Dropdown.Toggle variant="falcon-default disabled" size="sm">
              {!!selectedSearchPeriod ? selectedSearchPeriod : '조회 개월 수'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item key="1" eventKey="1W">
                1주
              </Dropdown.Item>
              <Dropdown.Item key="2" eventKey="1M">
                1개월
              </Dropdown.Item>
              <Dropdown.Item key="3" eventKey="3M">
                3개월
              </Dropdown.Item>
              <Dropdown.Item key="4" eventKey="6M">
                6개월
              </Dropdown.Item>
              <Dropdown.Item key="5" eventKey="1Y">
                1년
              </Dropdown.Item>
              <Dropdown.Item key="6" eventKey="ALL">
                설치일부터
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      {/*<Card.Body>*/}
      <ReactEChartsCore
        echarts={echarts}
        option={getOptions(facilityPredicatedSafetyChartData)}
        className={'ps-4'}
      />
      {/*  </Card.Body>*/}
      {/*</Card>*/}
    </>
  );
};

export default FacilityPredictedSafetyChart;
