import React, { useState, useContext } from 'react';
import Flex from 'components/common/Flex';

import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import ItemDetail from '../detail';
import classNames from 'classnames';
import { MapsContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';
import { useMutation, useQueryClient } from 'react-query';
import SoftBadge from 'components/common/SoftBadge';
import { useEventStatus } from 'components/dashboard/hook/useDashboard';
import PropTypes from 'prop-types';
import { isEmpty } from 'helpers/utils';

const ProjectItems = ({ project, searchText }) => {
  // BEFORE : 예정,
  // FINISH : 완료
  // ON_ISSUE : 점��요함
  // NO_ISSUE : 진행중
  const queryClient = useQueryClient();

  const { selectedProject, setSelectedProject, setIsMapRefresh } =
    useContext(MapsContext);
  const [marked, setMarked] = useState(project.favorite);
  const { data: eventStatus } = useEventStatus(project.id);

  const onClickProjectItem = project => {
    if (!isEmpty(selectedProject) && project.id === selectedProject.id) {
      console.log('onClickProjectItem selectedProject Match');
      setIsMapRefresh(true);
    }
    setSelectedProject(project);
  };

  const changeFavoriteProject = useMutation(
    project => {
      let value = 'N';
      if (!marked) {
        value = 'Y';
      }
      let url = '/api/project/' + project.id + '?favorite=' + value;
      // console.log(url);
      chainAxios.put(url);
    },
    {
      onSuccess: () => {
        setMarked(!marked);
        queryClient.invalidateQueries(['getProjects', searchText]);
      }
    }
  );

  const makeSubTitle = () => {
    // RD : 도로 / FC : 시설물 / FD : 공사현장
    let subTitle = '도로: 0 시설물: 0 공사현장: 0';
    if (project.facilities) {
      let road = 0;
      let facility = 0;
      let field = 0;
      project.facilities.forEach(f => {
        if (f.type === 'RD') {
          road += 1;
        }
        if (f.type === 'FC') {
          facility += 1;
        }
        if (f.type === 'FD') {
          field += 1;
        }
      });
      subTitle =
        '도로: ' +
        road.toString() +
        ' 시설물: ' +
        facility.toString() +
        ' 공사현장: ' +
        field.toString();
    }
    return subTitle;
  };

  const makeStatusBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';
    if (eventStatus.PRE > 0) {
      badgeName = '점검요함';
      badgeColor = 'danger';
    } else if (
      project.id === '3e278d82-0fd8-43a8-a661-9dc3d738660f' ||
      project.id === '0821cb40-ff5b-4ab4-9716-0fcc6509253f'
    ) {
      badgeName = '완료';
      badgeColor = 'success';
    } else {
      badgeName = '진행중';
      badgeColor = 'primary';
    }
    return <SoftBadge bg={badgeColor}>{badgeName}</SoftBadge>;
  };

  return (
    <Nav.Link
      eventKey={project.id}
      className={classNames(`chat-contact hover-actions-trigger`, {
        'unread-message': false,
        'read-message': false
      })}
      style={{
        height: 'auto',
        border: 0,
        paddingLeft: 5,
        paddingRight: 0
      }}
      // onClick={() => onClickProjectItem(project)}
    >
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        // className="py-3"
      >
        <Flex direction="row" alignItems="center" style={{ width: '100%' }}>
          <div className="pe-3">
            <FontAwesomeIcon
              onClick={async () => {
                await changeFavoriteProject.mutate(project);
              }}
              icon={marked ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                'ms-1',
                { 'text-warning': marked, 'text-300': !marked },
                'cursor-pointer'
              )}
            />
          </div>
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="around"
            style={{ cursor: 'pointer', width: '100%' }}
            onClick={() => onClickProjectItem(project)}
          >
            <Flex
              alignItems="end"
              direction="row"
              justifyContent="between"
              style={{ width: '100%' }}
            >
              <div className="fs--0 text-700 fw-semi-bold">{project.name}</div>
              {makeStatusBadge()}
            </Flex>
            <div className="fs--1 text-900">{makeSubTitle()}</div>
          </Flex>
        </Flex>
      </Flex>
    </Nav.Link>
  );
};

ProjectItems.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    favorite: PropTypes.bool.isRequired,
    facilities: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  searchText: PropTypes.string
};

export default ProjectItems;
