import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutContent from 'components/authentication/LogoutContent';
import { Cookies } from 'react-cookie';
import { LoginUserContext } from 'context/Context';

const cookies = new Cookies();
const Logout = () => {
  const navigate = useNavigate();

  const { setLoginUser, isLoginLoading, setIsLoginLoading } =
    useContext(LoginUserContext);

  useEffect(() => {
    window.sessionStorage.clear();
    setIsLoginLoading(false);
    console.log('logout : Authorization');
    cookies.set('Authorization', '', 0);

    navigate('/login');
  }, []);

  return <></>;

  // return <LogoutContent layout={'simple'} />;
};

export default Logout;
