import Map, {
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
  Source,
  Layer,
  Marker,
  Popup
} from 'react-map-gl';

import facilityMarker4 from 'assets/img/icons/facility-marker4.svg';
import facilityMarker4Warning from 'assets/img/icons/facility-marker4-warning.svg';
import facilityMarker3 from 'assets/img/icons/facility-marker3.svg';
import facilityMarker3Warning from 'assets/img/icons/facility-marker3-warning.svg';
import facilityMarker2 from 'assets/img/icons/facility-marker2.svg';
import facilityMarker2Warning from 'assets/img/icons/facility-marker2-warning.svg';
import facilityMarker1 from 'assets/img/icons/facility-marker1.svg';
import facilityMarker1Warning from 'assets/img/icons/facility-marker1-warning.svg';
import facilityMarker0 from 'assets/img/icons/facility-marker0.svg';
import facilityMarker0Warning from 'assets/img/icons/facility-marker0-warning.svg';

import accGeoMarker from 'assets/img/icons/danger_7720588.svg';
import EqkGeoMarker from 'assets/img/icons/location_6772188.svg';
import asosStnGeoMarker from 'assets/img/icons/windsock_9473091.svg';
import ncwStnGeoMarker from 'assets/img/icons/lens_5584773.svg';

import boreHoleMarker from 'assets/img/icons/borehole-marker.png';

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  FaBuilding, // 건물
  FaTent, // 시설물
  FaShop, //
  FaRulerVertical,
  FaMap,
  FaCrosshairs,
  FaIcicles,
  FaArrowsDownToLine,
  FaBolt,
  FaTowerObservation,
  FaWandMagic
} from 'react-icons/fa6';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getColor, isEmpty, isJSON } from 'helpers/utils';
import {
  useMarkerBoreHoles,
  useMarkerFacilities,
  useMarkerSensors,
  useMarkerSensorHoles
} from './hook/useCenterArea';
import { MapsContext } from 'context/Context';
import { debounce } from 'lodash';
import * as turf from '@turf/turf';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import MapboxItemDetail from '../left-area/detail/MapboxDetail';
import SensorDetail from '../left-area/sensor-detail';
import PredictDetail from './predict-detail';

import { cityDistrictData } from 'data/maps/goyang';
import { SiGu } from 'data/maps/kintex/sigu';
import { guGoyangData } from 'data/maps/kintex/guGoyang';
import { dongGoyangData } from 'data/maps/kintex/dongGoyang';
import { accGeoData } from 'data/maps/accGeo';
import { eqkGeoData } from 'data/maps/eqkGeo';
import { asosStnGeoData } from 'data/maps/asosStnGeo';
import { ncwStnGeoData } from 'data/maps/ncwStnGeo';

const MapboxIndex = () => {
  const { data: facilities } = useMarkerFacilities();
  const { data: sensors } = useMarkerSensors();
  const { data: sensorHoles } = useMarkerSensorHoles();
  // console.log('sensor', sensors);
  // console.log('sensorHoles', sensorHoles);

  const {
    data: boreHoles,
    setMinLat,
    setMaxLat,
    setMinLng,
    setMaxLng
  } = useMarkerBoreHoles();

  const mapContainerRef = useRef(null);

  const {
    selectedProject,
    setSelectedRegion,
    sendRegion,
    isMapRefresh,
    setIsMapRefresh
  } = useContext(MapsContext);

  const [selectedMapStyle, setSelectedMapStyle] = useState('Streets');
  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );
  const [mapStyleName, setMapStyleName] = useState('streets-v12');

  const skyLayerStyle = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };
  const buildingLayerStyle = {
    id: '3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa', // 건물 색상
      'fill-extrusion-height': ['get', 'height'], // 건물 높이
      'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
      'fill-extrusion-opacity': 0.6 // 건물 투명도
    }
  };

  const clusterFacilitiesLayer = {
    id: 'cluster-Layers',
    type: 'circle',
    source: 'facilitiesSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#2c7be5',
        100,
        '#2c7be5',
        750,
        '#2c7be5'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterFacilitiesCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'facilitiesSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredFacilitiesPointLayer = {
    id: 'unclustered-point',
    type: 'symbol',
    source: 'facilitiesSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      // 'icon-image': 'custom-marker', // 이미지 속성 가져오기
      // feature의 속성값에 따라 아이콘을 동적으로 선택
      'icon-image': [
        'case',
        ['all', ['==', ['get', 'status'], '4'], ['>', ['get', 'pre'], 0]],
        'facilityMarker4Warning',
        ['all', ['==', ['get', 'status'], '4'], ['==', ['get', 'pre'], 0]],
        'facilityMarker4',
        ['all', ['==', ['get', 'status'], '3'], ['>', ['get', 'pre'], 0]],
        'facilityMarker3Warning',
        ['all', ['==', ['get', 'status'], '3'], ['==', ['get', 'pre'], 0]],
        'facilityMarker3',
        ['all', ['==', ['get', 'status'], '2'], ['>', ['get', 'pre'], 0]],
        'facilityMarker2Warning',
        ['all', ['==', ['get', 'status'], '2'], ['==', ['get', 'pre'], 0]],
        'facilityMarker2',
        ['all', ['==', ['get', 'status'], '1'], ['>', ['get', 'pre'], 0]],
        'facilityMarker1Warning',
        ['all', ['==', ['get', 'status'], '1'], ['==', ['get', 'pre'], 0]],
        'facilityMarker1',
        ['all', ['==', ['get', 'status'], '0'], ['>', ['get', 'pre'], 0]],
        'facilityMarker0Warning',
        ['all', ['==', ['get', 'status'], '0'], ['==', ['get', 'pre'], 0]],
        'facilityMarker0',
        'facilityMarker4'
      ],
      'icon-size': 0.4,
      'icon-offset': [0, -50], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    },
    paint: {
      'text-color': 'black' // 텍스트 색상을 빨간색으로 설정
    }
  };

  const clusterAccGeoLayer = {
    id: 'cluster-accgeo-Layers',
    type: 'circle',
    source: 'accGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#ff0000',
        100,
        '#ff0000',
        750,
        '#ff0000'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterAccGeoCountLayer = {
    id: 'cluster-accgeo-count',
    type: 'symbol',
    source: 'accGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredAccGeoPointLayer = {
    id: 'unclustered-accgeo-point',
    type: 'symbol',
    source: 'accGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'accGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const clusterEqkGeoLayer = {
    id: 'cluster-Eqkgeo-Layers',
    type: 'circle',
    source: 'EqkGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#c68a12',
        100,
        '#c68a12',
        750,
        '#c68a12'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterEqkGeoCountLayer = {
    id: 'cluster-Eqkgeo-count',
    type: 'symbol',
    source: 'EqkGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredEqkGeoPointLayer = {
    id: 'unclustered-Eqkgeo-point',
    type: 'symbol',
    source: 'EqkGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'EqkGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const clusterAsosStnGeoLayer = {
    id: 'cluster-asosstngeo-Layers',
    type: 'circle',
    source: 'asosStnGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#27bcfd',
        100,
        '#27bcfd',
        750,
        '#27bcfd'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterAsosStnGeoCountLayer = {
    id: 'cluster-asosstngeo-count',
    type: 'symbol',
    source: 'asosStnGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredAsosStnGeoPointLayer = {
    id: 'unclustered-asosstngeo-point',
    type: 'symbol',
    source: 'asosStnGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'asosStnGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const clusterNcwGeoLayer = {
    id: 'cluster-ncwgeo-Layers',
    type: 'circle',
    source: 'ncwGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#000000',
        100,
        '#000000',
        750,
        '#000000'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterNcwGeoCountLayer = {
    id: 'cluster-ncwgeo-count',
    type: 'symbol',
    source: 'ncwGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredNcwGeoPointLayer = {
    id: 'unclustered-ncwgeo-point',
    type: 'symbol',
    source: 'ncwGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'ncwGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const [facilityPolygons, setFacilityPolygons] = useState({});
  const [isVisibleBuildings, setIsVisibleBuildings] = useState(true);
  const [isVisibleFacilityMarker, setIsVisibleFacilityMarker] = useState(false);
  const [isVisibleFacilityBoundary, setIsVisibleFacilityBoundary] =
    useState(false);
  const [isVisibleSenserMarker, setIsVisibleSensorMarker] = useState(false);
  const [isVisibleBoreHoleMarker, setIsVisibleBoreHoleMarker] = useState(false);

  const [detailShow, setDetailShow] = useState(false);
  const [sensorDetailShow, setSensorDetailShow] = useState(false);
  const [predictDetailShow, setPredictDetailShow] = useState(false);
  const [predictDetailFeature, setPredictDetailFeature] = useState({});

  const [hoveredFacilityMarker, setHoveredFacilityMarker] = useState(null);
  const [hoveredSensorMarker, setHoveredSensorMarker] = useState(null);
  const [hoveredBoreHoleMarker, setHoveredBoreHoleMarker] = useState(null);

  const [selectedFacilitiesId, setSelectedFacilitiesId] = useState(null);
  const [selectedSensorId, setSelectedSensorId] = useState(null);

  const [isVisibleHazardMap, setIsVisibleHazardMap] = useState(false);

  const [popupInfo, setPopupInfo] = useState(null);
  const [safetyPopupInfo, setSafetyPopupInfo] = useState(null);
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

  const [isVisibleKintexSigu, setIsVisibleKintexSigu] = useState(true);
  const [isVisibleKintexGuGoyang, setIsVisibleKintexGuGoyang] = useState(false);
  const [isVisibleKintexDongGoyang, setIsVisibleKintexDongGoyang] =
    useState(false);

  const [selectedKintexDongGoyang, setSelectedKintexDongGoyang] = useState([]);
  const [selectedKintextDongGoyangLine, setSelectedKintextDongGoyangLine] =
    useState({});

  const [
    isVisibleFacilitiesClusterMarkers,
    setIsVisibleFacilitiesClusterMarkers
  ] = useState(false);
  const [facilitiesClusterMarkers, setFacilitiesClusterMarkers] = useState({});

  const [mapInstance, setMapInstance] = useState(null);

  const [selectedAccGeo, setSelectedAccGeo] = useState({
    type: 'FeatureCollection',
    features: []
  });
  const [selectedEqkGeo, setSelectedEqkGeo] = useState({
    type: 'FeatureCollection',
    features: []
  });
  const [selectedNcwGeo, setSelectedNcwGeo] = useState({
    type: 'FeatureCollection',
    features: []
  });
  const [selectedAsosGeo, setSelectedAsosGeo] = useState({
    type: 'FeatureCollection',
    features: []
  });

  const [isVisibleAccGeoClusterMarkers, setIsVisibleAccGeoClusterMarkers] =
    useState(false);
  const [hoveredAccGeoMarker, setHoveredAccGeoMarker] = useState(null);

  const [isVisibleEqkGeoClusterMarkers, setIsVisibleEqkGeoClusterMarkers] =
    useState(false);
  const [hoveredEqkGeoMarker, setHoveredEqkGeoMarker] = useState(null);

  const [isVisibleAsosStnGeoMarker, setIsVisibleAsosStnGeoMarker] =
    useState(false);
  const [hoveredAsosStnGeoMarker, setHoveredAsosStnGeoMarker] = useState(null);

  const [isVisibleNcwStnGeoMarker, setIsVisibleNcwStnGeoMarker] =
    useState(false);
  const [hoveredNcwStnGeoMarker, setHoveredNcwStnGeoMarker] = useState(null);

  useEffect(() => {
    if (!isEmpty(facilities)) {
      makeFaciliesClusterMarker(facilities);

      makeFacilityPolygons(facilities);

      if (mapContainerRef.current) {
        const bounds = mapContainerRef.current.getMap().getBounds();

        makeBoreHole(
          bounds.getSouth(),
          bounds.getNorth(),
          bounds.getWest(),
          bounds.getEast()
        );
      }
    }
  }, [facilities]);

  useEffect(() => {
    selectedProjectRefreshMap();
  }, [selectedProject]);

  useEffect(() => {
    if (isVisibleFacilitiesClusterMarkers) {
      clusterLayerFrontMove();
    }
  }, [isVisibleFacilitiesClusterMarkers]);

  useEffect(() => {
    if (isVisibleFacilityBoundary) {
      mapContainerRef.current.moveLayer('unclustered-point');
    }
  }, [isVisibleFacilityBoundary]);

  useEffect(() => {
    if (isVisibleKintexSigu && !isEmpty(mapInstance)) {
      clusterLayerFrontMove();
    }
  }, [isVisibleKintexSigu]);

  useEffect(() => {
    if (isVisibleKintexGuGoyang && !isEmpty(mapInstance)) {
      const guGoyangLayers = mapInstance.getLayer('guGoyang-Layers'); // Layer 존재

      if (guGoyangLayers !== undefined) {
        mapContainerRef.current.moveLayer('guGoyang-layer');
        mapContainerRef.current.moveLayer('guGoyang-layer-line');
        mapContainerRef.current.moveLayer('guGoyang-layer-hover-line');
        mapContainerRef.current.moveLayer('guGoyang-layer-labels');
      }

      clusterLayerFrontMove();
    }
  }, [isVisibleKintexGuGoyang]);

  useEffect(() => {
    if (isVisibleKintexDongGoyang && !isEmpty(mapInstance)) {
      const dongGoyangLayers = mapInstance.getLayer('dongGoyang-Layers'); // Layer 존재 여부 확인

      if (dongGoyangLayers !== undefined) {
        mapContainerRef.current.moveLayer('dongGoyang-layer');
        mapContainerRef.current.moveLayer('dongGoyang-layer-line');
        mapContainerRef.current.moveLayer('dongGoyang-layer-hover-line');
        mapContainerRef.current.moveLayer('dongGoyang-layer-labels');
      }

      clusterLayerFrontMove();
    }
  }, [isVisibleKintexDongGoyang]);

  useEffect(() => {
    if (isMapRefresh) {
      selectedProjectRefreshMap();
      setIsMapRefresh(false);
    }
  }, [isMapRefresh]);

  const filterGeoData = makeBbox => {
    const filterAccGeoData = accGeoData.features.filter(
      acc =>
        acc.geometry.coordinates[0] >= makeBbox[0] &&
        acc.geometry.coordinates[0] <= makeBbox[2] &&
        acc.geometry.coordinates[1] >= makeBbox[1] &&
        acc.geometry.coordinates[1] <= makeBbox[3]
    );
    // console.log('accGeoData : ', accGeoData);
    // console.log('filterAccGeoData : ', filterAccGeoData);

    setSelectedAccGeo({
      type: 'FeatureCollection',
      features: filterAccGeoData
    });

    const filterEqkGeoData = eqkGeoData.features.filter(
      Eqk =>
        Eqk.geometry.coordinates[0] >= makeBbox[0] &&
        Eqk.geometry.coordinates[0] <= makeBbox[2] &&
        Eqk.geometry.coordinates[1] >= makeBbox[1] &&
        Eqk.geometry.coordinates[1] <= makeBbox[3]
    );

    setSelectedEqkGeo({
      type: 'FeatureCollection',
      features: filterEqkGeoData
    });

    const filterAsosGeoData = asosStnGeoData.features.filter(
      asos =>
        asos.geometry.coordinates[0] >= makeBbox[0] &&
        asos.geometry.coordinates[0] <= makeBbox[2] &&
        asos.geometry.coordinates[1] >= makeBbox[1] &&
        asos.geometry.coordinates[1] <= makeBbox[3]
    );

    setSelectedAsosGeo({
      type: 'FeatureCollection',
      features: filterAsosGeoData
    });

    const filterNcwGeoData = ncwStnGeoData.features.filter(
      ncw =>
        ncw.geometry.coordinates[0] >= makeBbox[0] &&
        ncw.geometry.coordinates[0] <= makeBbox[2] &&
        ncw.geometry.coordinates[1] >= makeBbox[1] &&
        ncw.geometry.coordinates[1] <= makeBbox[3] &&
        ncw.properties.well === '암반'
    );

    setSelectedNcwGeo({
      type: 'FeatureCollection',
      features: filterNcwGeoData
    });
  };

  const clusterLayerFrontMove = () => {
    if (isEmpty(mapInstance)) {
      return;
    }

    clusterLayerFrontMoveParam(mapInstance);
  };

  const clusterLayerFrontMoveParam = instance => {
    const clusterLayer = instance.getLayer('cluster-Layers'); // Layer 존재 여부 확인
    if (clusterLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-Layers');
      mapContainerRef.current.moveLayer('cluster-count');
      mapContainerRef.current.moveLayer('unclustered-point');
    }

    const clusterAccGeoLayer = instance.getLayer('cluster-accgeo-Layers');
    if (clusterAccGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-accgeo-Layers');
      mapContainerRef.current.moveLayer('cluster-accgeo-count');
      mapContainerRef.current.moveLayer('unclustered-accgeo-point');
    }

    const clusterEqkGeoLayer = instance.getLayer('cluster-Eqkgeo-Layers');
    if (clusterEqkGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-Eqkgeo-Layers');
      mapContainerRef.current.moveLayer('cluster-Eqkgeo-count');
      mapContainerRef.current.moveLayer('unclustered-Eqkgeo-point');
    }

    const clusterAsosStnGeoLayer = instance.getLayer(
      'cluster-asosstngeo-Layers'
    );
    if (clusterAsosStnGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-asosstngeo-Layers');
      mapContainerRef.current.moveLayer('cluster-asosstngeo-count');
      mapContainerRef.current.moveLayer('unclustered-asosstngeo-point');
    }

    const clusterNcwGeoLayer = instance.getLayer('cluster-ncwgeo-Layers');
    if (clusterNcwGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-ncwgeo-Layers');
      mapContainerRef.current.moveLayer('cluster-ncwgeo-count');
      mapContainerRef.current.moveLayer('unclustered-ncwgeo-point');
    }
  };

  const onSelectMapStyle = value => {
    if (value === 'streets-v12') {
      setSelectedMapStyle('Streets');
    }
    if (value === 'outdoors-v12') {
      setSelectedMapStyle('Outdoors');
    }
    if (value === 'light-v11') {
      setSelectedMapStyle('Light');
    }
    if (value === 'dark-v11') {
      setSelectedMapStyle('Dark');
    }
    if (value === 'satellite-streets-v12') {
      setSelectedMapStyle('Satellite Streets');
    }
    if (value === 'navigation-day-v1') {
      setSelectedMapStyle('Navigation Day');
    }
    if (value === 'navigation-night-v1') {
      setSelectedMapStyle('Navigation Night');
    }
    if (value === 'standard') {
      setSelectedMapStyle('Standard');
    }

    setMapStyleName(value);
    setMapStyle(`mapbox://styles/mapbox/${value}`);
  };

  const makeBoreHole = useCallback(
    debounce(() => {
      // 현재 화면에 표시되는 지도의 최대, 최소 위도 경도 계산
      if (mapContainerRef.current) {
        const bounds = mapContainerRef.current.getMap().getBounds();

        setMinLng(bounds.getWest());
        setMaxLng(bounds.getEast());
        setMinLat(bounds.getSouth());
        setMaxLat(bounds.getNorth());
      }
    }, 500),
    []
  );

  const makeFacilityPolygons = facilities => {
    let polygons = [];

    facilities.forEach(facility => {
      if (facility.boundary === null) {
        return;
      }

      let lineCoordinates = [];

      facility.boundary.forEach(b => {
        lineCoordinates.push([b.longitude, b.latitude]);
      });

      polygons.push({
        id: facility.id,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [lineCoordinates]
        }
      });
    });

    // console.log({ type: 'FeatureCollection', features: polygons });
    setFacilityPolygons({ type: 'FeatureCollection', features: polygons });
  };

  const makeFaciliesClusterMarker = facilities => {
    let markers = [];

    facilities.forEach(facility => {
      markers.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [facility.location.longitude, facility.location.latitude]
        },
        properties: {
          id: facility.id,
          name: facility.name,
          status: facility.status,
          location: facility.location,
          pre: facility.eventStatus.PRE
        }
      });
    });

    // console.log({ type: 'FeatureCollection', features: markers });
    setFacilitiesClusterMarkers({
      type: 'FeatureCollection',
      features: markers
    });
  };

  // const handleSensorMarkerHover = sensorId => {
  //   const sensorMarker = sensors.find(m => m.id === sensorId);
  //   setHoveredSensorMarker(sensorMarker);
  // };

  const handleSensorMarkerHover = sensorId => {
    const sensorHoleMarker = sensorHoles.find(hole => hole.id === sensorId); // sensorHole 데이터
    const sensorMarker = sensors.find(sensor => sensor.id === sensorId); // sensors 데이터

    if (sensorHoleMarker) {
      setHoveredSensorMarker({
        ...sensorHoleMarker, // sensorHole 기본 정보
        status: sensorMarker?.status || 'N/A', // 센서에서 상태 정보
        images: sensorMarker?.images || [], // 센서에서 이미지 데이터
        battery: sensorMarker?.battery || 'N/A', // 센서에서 배터리 정보
        rawDate: sensorMarker?.properties?.SSRAWDATE || 'N/A', // 센서에서 RAW DATE
        properties: sensorMarker?.properties || {} // 센서의 properties 전체 추가
      });
    } else {
      setHoveredSensorMarker(null); // 초기화
    }
  };

  const handleSensorMarkerLeave = () => {
    setHoveredSensorMarker(null);
  };

  const handleBoreHoleMarkerHover = boreHoleId => {
    const boreHoleMarker = boreHoles.find(m => m.id === boreHoleId);
    setHoveredBoreHoleMarker(boreHoleMarker);
  };

  const handleBoreHoleMarkerLeave = () => {
    setHoveredBoreHoleMarker(null);
  };

  const handleZoom = useCallback(
    debounce(e => {
      console.log(e.viewState.zoom);
      setIsVisibleSensorMarker(e.viewState.zoom >= 18);
      setIsVisibleFacilityBoundary(e.viewState.zoom >= 18);
      if (e.viewState.zoom <= 18) {
        setIsVisibleBoreHoleMarker(false);
      }
    }, 300),
    []
  );

  const onRender = e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  };

  const handleMove = evt => {
    setViewport({
      latitude: evt.viewState.latitude,
      longitude: evt.viewState.longitude,
      zoom: evt.viewState.zoom,
      pitch: evt.viewState.pitch,
      bearing: evt.viewState.bearing
    });

    makeBoreHole();
  };

  const onClickFacilityMarker = facilityId => {
    console.log('click : onClickFacilityMarker');

    setSelectedFacilitiesId(facilityId);
    setDetailShow(true);
  };

  const onClickSenserMarker = sensorId => {
    console.log('click : onClickSenserMarker');

    setSelectedSensorId(sensorId);
    setSensorDetailShow(true);
  };

  const detailModalShowEvent = () => {
    setDetailShow(!detailShow);
    // setMiniMapSelectedSensor('');
  };

  const sensorDetailModalShowEvent = () => {
    setSensorDetailShow(!sensorDetailShow);
    // setMiniMapSelectedSensor('');
  };

  const predictDetailModalShowEvent = () => {
    setPredictDetailShow(!predictDetailShow);
  };

  const ShowCityDistricts = () => {
    setIsVisibleKintexSigu(!isVisibleKintexSigu);
    setIsVisibleKintexGuGoyang(false);
    setIsVisibleKintexDongGoyang(false);
    setSelectedKintextDongGoyangLine({});

    mapContainerRef.current?.flyTo({
      latitude: (37.74849 + 37.43348) / 2,
      longitude: (126.67036 + 126.92851) / 2,
      duration: 1500,
      zoom: 10.6,
      pitch: 10, // 카메라 각도 (기울기)
      bearing: 0 // 카메라 방향
    });
  };

  const clearSelectedLayer = () => {
    const map = mapContainerRef.current.getMap();

    setPopupInfo(null);

    if (isSourceExist('kintexSigu')) {
      SiGu.features.map(d => {
        map.setFeatureState(
          { source: 'kintexSigu', id: d.properties.rgnCode },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('kintexGuGoyang')) {
      guGoyangData.features.map(d => {
        map.setFeatureState(
          { source: 'kintexGuGoyang', id: d.properties.rgnCode },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('kintexDongGoyang')) {
      dongGoyangData.features.map(d => {
        map.setFeatureState(
          { source: 'kintexDongGoyang', id: d.properties.rgnCode },
          { hover: false, selected: false }
        );
      });
    }
  };

  // 새로운 함수: 특정 소스가 존재하는지 확인
  const isSourceExist = sourceId => {
    const map = mapContainerRef.current.getMap();
    return map.getSource(sourceId) !== undefined;
  };

  const handleMapClick = event => {
    const features = event.features;

    // const map = mapContainerRef.current.getMap();
    if (features && features.length > 0) {
      const feature = features[0];
      console.log('handleMapClick feature : ', feature);

      if (feature.layer.id === 'unclustered-point') {
        return;
      }

      if (feature.layer.id === 'cluster-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'facilitiesClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(false);

        setIsVisibleSensorMarker(true);
        setIsVisibleFacilityBoundary(true);

        return;
      }

      if (feature.layer.id === 'cluster-accgeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'accGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(false);

        return;
      }

      if (feature.layer.id === 'cluster-eqkgeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'EqkGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(false);

        return;
      }

      if (feature.layer.id === 'cluster-asosstngeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'asosStnGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(false);

        return;
      }

      if (feature.layer.id === 'cluster-ncwgeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'ncwGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(false);

        return;
      }
      // clearSelectedLayer();

      if (feature.layer.id === 'sigu-layer') {
        sidoLayerClick(feature);
      }

      if (feature.layer.id === 'guGoyang-layer') {
        // let rgnCode = feature.properties.rgnCode;
        let featureBbox = feature.properties.rgnBbox;
        // let bbox = featureBbox.replace('[', '').replace(']', '').split(',');
        let bbox = JSON.parse(featureBbox);
        mapContainerRef.current?.fitBounds(
          [
            // [126.67036, 37.74849],
            // [126.92851, 37.43348]
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]]
          ], // 남서쪽 및 북동쪽 좌표
          {
            padding: 50, // 20px 여백 추가
            duration: 1500 // 1초 동안 애니메이션
          }
        );

        let kintexDongGoyang = dongGoyangData.features.filter(
          d =>
            d.properties.rgnCode.substring(0, 5) === feature.properties.rgnCode
        );
        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(true);
        setSelectedKintexDongGoyang(kintexDongGoyang);
        setSelectedKintextDongGoyangLine({});
      }

      if (feature.layer.id === 'dongGoyang-layer') {
        let featureBbox = feature.properties.rgnBbox;
        // let bbox = featureBbox.replace('[', '').replace(']', '').split(',');
        let bbox = JSON.parse(featureBbox);
        mapContainerRef.current?.fitBounds(
          [
            // [126.67036, 37.74849],
            // [126.92851, 37.43348]
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]]
          ], // 남서쪽 및 북동쪽 좌표
          {
            padding: 50, // 20px 여백 추가
            duration: 1500 // 1초 동안 애니메이션
          }
        );

        setIsVisibleKintexSigu(false);
        setIsVisibleKintexGuGoyang(false);
        setIsVisibleKintexDongGoyang(false);

        setSelectedKintextDongGoyangLine({
          type: 'FeatureCollection',
          features: selectedKintexDongGoyang.filter(
            f => f.id === feature.id.toString()
          )
        });
      }
    } else {
      console.log('handleMapClick event : ', event);

      if (isVisibleHazardMap) {
        const features = mapContainerRef.current.queryRenderedFeatures(
          event.point,
          {
            layers: ['city-district-layer']
          }
        );

        if (features.length) {
          const feature = features[0];
          // console.log(feature);

          setPredictDetailFeature(feature);
          setPredictDetailShow(true);
        }
      } else if (sensorDetailShow) {
        return;
      } else {
        refreshMap();
      }
    }
  };

  const selectedProjectRefreshMap = () => {
    setIsVisibleFacilitiesClusterMarkers(false);
    setIsVisibleAccGeoClusterMarkers(false);
    setIsVisibleEqkGeoClusterMarkers(false);
    setIsVisibleAsosStnGeoMarker(false);
    setIsVisibleNcwStnGeoMarker(false);

    setIsVisibleFacilityBoundary(false);
    setIsVisibleSensorMarker(false);

    setIsVisibleKintexSigu(true);
    setIsVisibleKintexGuGoyang(false);
    setIsVisibleKintexDongGoyang(false);
    setSelectedKintextDongGoyangLine({});

    setSelectedKintexDongGoyang([]);

    if (!isEmpty(selectedProject)) {
      console.log('selectedProject : ', selectedProject);
      let makeBbox = [];

      // 고양시
      if (selectedProject.regions[0].toString() === '41280000') {
        makeBbox = [126.67036, 37.5738, 126.99436, 37.74849];
      }

      // 금천구
      if (selectedProject.regions[0].toString() === '11545102') {
        makeBbox = [126.87182, 37.43348, 126.92851, 37.48667];
      }

      // 안산시
      if (selectedProject.regions[0].toString() === '41270000') {
        makeBbox = [126.37884, 37.09911, 126.93959, 37.37744];
      }

      if (makeBbox.length === 4) {
        mapContainerRef.current?.fitBounds(
          [
            [makeBbox[0], makeBbox[1]],
            [makeBbox[2], makeBbox[3]]
          ], // 남서쪽 및 북동쪽 좌표
          {
            padding: 20, // 20px 여백 추가
            duration: 1500 // 1초 동안 애니메이션
          }
        );

        filterGeoData(makeBbox);

        setIsVisibleFacilitiesClusterMarkers(true);
        setIsVisibleAccGeoClusterMarkers(true);
        setIsVisibleEqkGeoClusterMarkers(true);
        setIsVisibleAsosStnGeoMarker(true);
        setIsVisibleNcwStnGeoMarker(true);
      } else {
        mapContainerRef.current?.flyTo({
          latitude: (37.09911 + 37.37744) / 2,
          longitude: (126.37884 + 126.93959) / 2,
          duration: 1500,
          zoom: 10.6,
          pitch: 10, // 카메라 각도 (기울기)
          bearing: 0 // 카메라 방향
        });

        setIsVisibleFacilitiesClusterMarkers(false);
        setIsVisibleAccGeoClusterMarkers(false);
        setSelectedAccGeo({
          type: 'FeatureCollection',
          features: []
        });
        setIsVisibleEqkGeoClusterMarkers(false);
        setSelectedEqkGeo({
          type: 'FeatureCollection',
          features: []
        });
      }
      setIsVisibleAsosStnGeoMarker(false);
      setSelectedAsosGeo({
        type: 'FeatureCollection',
        features: []
      });
      setIsVisibleNcwStnGeoMarker(false);
      setSelectedNcwGeo({
        type: 'FeatureCollection',
        features: []
      });
    }
  };

  const refreshMap = () => {
    setSelectedKintextDongGoyangLine({});
    setIsVisibleKintexDongGoyang(false);
    setIsVisibleKintexGuGoyang(false);
    setIsVisibleKintexSigu(true);

    mapContainerRef.current?.flyTo({
      latitude: (37.74849 + 37.43348) / 2,
      longitude: (126.67036 + 126.92851) / 2,
      duration: 1500,
      zoom: 10.6,
      pitch: 10, // 카메라 각도 (기울기)
      bearing: 0 // 카메라 방향
    });

    setIsVisibleFacilitiesClusterMarkers(false);
    setIsVisibleAccGeoClusterMarkers(false);
    setSelectedAccGeo({
      type: 'FeatureCollection',
      features: []
    });
    setIsVisibleEqkGeoClusterMarkers(false);
    setSelectedEqkGeo({
      type: 'FeatureCollection',
      features: []
    });
    setIsVisibleAsosStnGeoMarker(false);
    setSelectedAsosGeo({
      type: 'FeatureCollection',
      features: []
    });
    setIsVisibleNcwStnGeoMarker(false);
    setSelectedNcwGeo({
      type: 'FeatureCollection',
      features: []
    });
  };

  const sidoLayerClick = feature => {
    let rgnCode = feature.properties.rgnCode;
    let makeBox = [];

    if (isJSON(feature.properties.rgnBbox)) {
      makeBox = JSON.parse(feature.properties.rgnBbox);
    } else {
      let featureBbox = feature.properties.rgnBbox;

      makeBox = [
        featureBbox[0],
        featureBbox[1],
        featureBbox[2],
        featureBbox[3]
      ];
    }

    mapContainerRef.current?.fitBounds(
      [
        // [126.67036, 37.74849],
        // [126.92851, 37.43348]
        [makeBox[0], makeBox[1]],
        [makeBox[2], makeBox[3]]
      ], // 남서쪽 및 북동쪽 좌표
      {
        padding: 50, // 20px 여백 추가
        duration: 1500 // 1초 동안 애니메이션
      }
    );

    filterGeoData(makeBox);

    // 경기도 고양시
    if (rgnCode === '41280') {
      setIsVisibleKintexSigu(false);
      setIsVisibleKintexGuGoyang(true);
      setIsVisibleKintexDongGoyang(false);
    }

    setSelectedRegion(feature);
    setIsVisibleFacilitiesClusterMarkers(true);
    setIsVisibleAccGeoClusterMarkers(true);
    setIsVisibleEqkGeoClusterMarkers(true);
    setIsVisibleAsosStnGeoMarker(true);
    setIsVisibleNcwStnGeoMarker(true);

    setSelectedKintextDongGoyangLine({});
  };

  const handleLayerHover = useCallback(
    debounce(event => {
      const map = mapContainerRef.current.getMap();
      const overLayers = map.queryRenderedFeatures(event.point);

      if (overLayers.length > 0) {
        clearSelectedLayer();

        const firstLayer = overLayers[0];

        if (firstLayer.layer.id === 'sigu-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['sigu-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];

            map.setFeatureState(
              {
                source: 'kintexSigu',
                id: hoveredFeature.properties.rgnCode
              },
              { hover: true }
            );

            setHoveredFeatureId(hoveredFeature.properties.rgnCode);
            makeSafetyPopupInfo(hoveredFeature);
          }
        }

        if (firstLayer.layer.id === 'guGoyang-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['guGoyang-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            map.setFeatureState(
              {
                source: 'kintexGuGoyang',
                id: hoveredFeature.properties.rgnCode
              },
              { hover: true }
            );
            setHoveredFeatureId(hoveredFeature.properties.rgnCode);
            makeSafetyPopupInfo(hoveredFeature);
          }
        }

        if (firstLayer.layer.id === 'dongGoyang-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['dongGoyang-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            map.setFeatureState(
              {
                source: 'kintexDongGoyang',
                id: hoveredFeature.properties.rgnCode
              },
              { hover: true }
            );
            setHoveredFeatureId(hoveredFeature.properties.rgnCode);
            makeSafetyPopupInfo(hoveredFeature);
          }
        }
      }
    }, 0),
    []
  );

  const makeSafetyPopupInfo = feature => {
    const { rgnKo, predVal } = feature.properties; // 지역명과 예측 위험 점수 가져오기

    const parsePredVal = JSON.parse(predVal);
    // predVal의 마지막 값을 안전 점수로 사용
    const lastPredVal =
      Array.isArray(parsePredVal) && parsePredVal.length > 0
        ? parsePredVal[parsePredVal.length - 1]
        : null;

    const sfScore = predVal => parseInt(Math.ceil(-100 * predVal ** 2 + 100));
    const sfgrade = e =>
      e >= 90
        ? 'A'
        : e >= 75
        ? 'B'
        : e >= 50
        ? 'C'
        : e >= 30
        ? 'D'
        : e >= 10
        ? 'E'
        : 'F';

    const riskScore = lastPredVal !== null ? sfScore(lastPredVal) : 0;
    const grade = sfgrade(riskScore);

    // 팝업 정보 설정
    setSafetyPopupInfo({
      name: JSON.parse(rgnKo).join(' '),
      riskScore, // 변환된 점수
      grade, // 안전 등급
      longitude: JSON.parse(feature.properties.rgnCenter)[0],
      latitude: JSON.parse(feature.properties.rgnCenter)[1]
    });
  };

  const handleLayerLeave = () => {
    clearSelectedLayer();
    setHoveredFeatureId(null);
    setSafetyPopupInfo(null);
  };

  const handleMapLoad = event => {
    const map = event.target;
    console.log('Map loaded successfully'); // 지도가 로드되었는지 로그로 확인
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        line_string: true,
        polygon: true,
        trash: true
      }
    });
    map.addControl(draw, 'top-right'); // MapboxDraw 컨트롤을 추가
    // console.log('MapboxDraw added successfully'); // 성공 여부 확인 로그
    LoadMarkerImages(map);

    setMapInstance(mapContainerRef.current.getMap());
    // draw.create 이벤트를 통해 라인/폴리곤이 완성될 때 처리
    map.on('draw.create', e => {
      const feature = e.features[0]; // 그려진 도형
      const coordinates = feature.geometry.coordinates;

      if (feature.geometry.type === 'LineString') {
        const length = turf.length(feature, { units: 'kilometers' }); // km 단위 거리 계산
        const lastPoint = coordinates[coordinates.length - 1]; // 마지막 점 좌표

        setPopupInfo({
          type: 'length',
          value: `${length.toFixed(2)} km`, // 거리 소수점 2자리 표시 및 단위 추가
          longitude: lastPoint[0],
          latitude: lastPoint[1]
        });
      }

      if (feature.geometry.type === 'Polygon') {
        const area = turf.area(feature); // 면적 계산 (단위: 제곱미터)
        const lastPoint = coordinates[0][coordinates[0].length - 1]; // 마지막 점 좌표

        setPopupInfo({
          type: 'area',
          value: `${(area / 1000000).toFixed(2)} km²`, // 면적을 제곱킬로미터(km²)로 변환 및 단위 추가
          longitude: lastPoint[0],
          latitude: lastPoint[1]
        });
      }
    });

    // 클릭 이벤트 추가
    map.on('click', 'unclustered-point', e => {
      if (e.features.length > 0) {
        console.log('click : unclustered-point');
        onClickFacilityMarker(e.features[0].properties.id);
        // 기본 동작 방지
        e.preventDefault();
        console.log('click : e ', e);
        // 이벤트 전파 중단
        if (e.originalEvent) {
          e.originalEvent.stopPropagation();
        }
      }
    });

    // 마우스 hover 이벤트 추가
    map.on('mouseenter', 'unclustered-point', e => {
      map.getCanvas().style.cursor = 'pointer';
      // console.log('unclustered-point mouseenter : ', e.features);
      if (e.features.length > 0) {
        const facilityMarker = facilities.find(
          m => m.id === e.features[0].properties.id
        );
        setHoveredFacilityMarker(facilityMarker);
      }
    });

    map.on('mouseenter', 'unclustered-accgeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const accGeoMarker = accGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredAccGeoMarker(accGeoMarker[0]);
      }
    });
    map.on('mouseenter', 'unclustered-Eqkgeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const EqkGeoMarker = eqkGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredEqkGeoMarker(EqkGeoMarker[0]);
      }
    });
    map.on('mouseenter', 'unclustered-asosstngeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const asosGeoMarker = asosStnGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredAsosStnGeoMarker(asosGeoMarker[0]);
      }
    });
    map.on('mouseenter', 'unclustered-ncwgeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const ncwGeoMarker = ncwStnGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredNcwStnGeoMarker(ncwGeoMarker[0]);
      }
    });

    map.on('mouseleave', 'unclustered-accgeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredAccGeoMarker(null);
    });
    map.on('mouseleave', 'unclustered-Eqkgeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredEqkGeoMarker(null);
    });
    map.on('mouseleave', 'unclustered-asosstngeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredAsosStnGeoMarker(null);
    });
    map.on('mouseleave', 'unclustered-ncwgeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredNcwStnGeoMarker(null);
    });
    map.on('mouseleave', 'unclustered-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredFacilityMarker(null);
    });

    map.on('mouseenter', 'cluster-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-Layers', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'cluster-accgeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-accgeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'cluster-Eqkgeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-Eqkgeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'cluster-asosstngeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-asosstngeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'cluster-ncwgeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-ncwgeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });

    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    const mapboxglCtrlBottomRights = document.querySelectorAll(
      '.mapboxgl-ctrl-bottom-right'
    );
    if (mapboxglCtrlBottomRights.length > 0) {
      mapboxglCtrlBottomRights.forEach(bottomRight => {
        bottomRight.style.display = 'none';
      });
    }

    if (!isEmpty(selectedProject)) {
      clusterLayerFrontMoveParam(mapContainerRef.current.getMap());
    }

    if (!isEmpty(sendRegion)) {
      // sidoLayerClick(sendRegion);
      let rgnBbox = sendRegion.properties.rgnBbox;

      mapContainerRef.current?.fitBounds(
        [
          // [126.67036, 37.74849],
          // [126.92851, 37.43348]
          [rgnBbox[0], rgnBbox[1]],
          [rgnBbox[2], rgnBbox[3]]
        ], // 남서쪽 및 북동쪽 좌표
        {
          padding: 50, // 20px 여백 추가
          duration: 1500 // 1초 동안 애니메이션
        }
      );

      filterGeoData(rgnBbox);

      setIsVisibleFacilitiesClusterMarkers(true);
      setIsVisibleAccGeoClusterMarkers(true);
      setIsVisibleEqkGeoClusterMarkers(true);
      setIsVisibleAsosStnGeoMarker(true);
      setIsVisibleNcwStnGeoMarker(true);

      setSelectedRegion(sendRegion);
    }
  };

  // [126.67036, 37.74849],
  // [126.92851, 37.43348]

  const [viewport, setViewport] = useState({
    width: '100vw',
    height: '100vh',
    // latitude: 37.66461,
    // longitude: 126.83741,
    latitude: (37.09911 + 37.37744) / 2,
    longitude: (126.37884 + 126.93959) / 2,
    zoom: 10,
    pitch: 10, // 카메라 각도 (기울기)
    bearing: 0 // 카메라 방향
  });

  const LoadMarkerImages = map => {
    const img4 = new Image();
    img4.src = facilityMarker4;
    img4.onload = () => {
      map.addImage('facilityMarker4', img4);
    };

    const img4Warning = new Image();
    img4Warning.src = facilityMarker4Warning;
    img4Warning.onload = () => {
      map.addImage('facilityMarker4Warning', img4Warning);
    };

    const img3 = new Image();
    img3.src = facilityMarker3;
    img3.onload = () => {
      map.addImage('facilityMarker3', img3);
    };

    const img3Warning = new Image();
    img3Warning.src = facilityMarker3Warning;
    img3Warning.onload = () => {
      map.addImage('facilityMarker3Warning', img3Warning);
    };

    const img2 = new Image();
    img2.src = facilityMarker2;
    img2.onload = () => {
      map.addImage('facilityMarker2', img2);
    };

    const img2Warning = new Image();
    img2Warning.src = facilityMarker2Warning;
    img2Warning.onload = () => {
      map.addImage('facilityMarker2Warning', img2Warning);
    };

    const img1 = new Image();
    img1.src = facilityMarker1;
    img1.onload = () => {
      map.addImage('facilityMarker1', img1);
    };

    const img1Warning = new Image();
    img1Warning.src = facilityMarker1Warning;
    img1Warning.onload = () => {
      map.addImage('facilityMarker1Warning', img1Warning);
    };

    const img0Warning = new Image();
    img0Warning.src = facilityMarker0Warning;
    img0Warning.onload = () => {
      map.addImage('facilityMarker0Warning', img0);
    };

    const img0 = new Image();
    img0.src = facilityMarker0;
    img0.onload = () => {
      map.addImage('facilityMarker0', img0);
    };

    const accGeoIcon = new Image();
    accGeoIcon.src = accGeoMarker;
    accGeoIcon.onload = () => {
      map.addImage('accGeoMarker', accGeoIcon);
    };

    const EqkGeoIcon = new Image();
    EqkGeoIcon.src = EqkGeoMarker;
    EqkGeoIcon.onload = () => {
      map.addImage('EqkGeoMarker', EqkGeoIcon);
    };

    const asosGeoIcon = new Image();
    asosGeoIcon.src = asosStnGeoMarker;
    asosGeoIcon.onload = () => {
      map.addImage('asosStnGeoMarker', asosGeoIcon);
    };

    const ncwGeoIcon = new Image();
    ncwGeoIcon.src = ncwStnGeoMarker;
    ncwGeoIcon.onload = () => {
      map.addImage('ncwGeoMarker', ncwGeoIcon);
    };
  };

  const [blinkingSensors, setBlinkingSensors] = useState(new Set());

  // 깜빡거림 상태를 설정하는 효과
  useEffect(() => {
    if (!sensors && sensors.length === 0) {
      return;
    }

    const interval = setInterval(() => {
      setBlinkingSensors(prev => {
        const newBlinkingSet = new Set(prev);
        // 깜빡이는 센서의 상태를 토글
        sensors.forEach(sensor => {
          if (sensor.eventStatus.PRE > 0) {
            if (newBlinkingSet.has(sensor.id)) {
              newBlinkingSet.delete(sensor.id);
            } else {
              newBlinkingSet.add(sensor.id);
            }
          }
        });
        return newBlinkingSet;
      });
    }, 500); // 500ms 간격

    return () => clearInterval(interval);
  }, [sensors]);

  return (
    <>
      <Map
        onLoad={handleMapLoad} // 지도 로드 시 이벤트 핸들러 추가
        onRender={onRender}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{
          borderRadius: '5px',
          position: 'relative',
          width: '100%',
          height: '94vh',
          minHeight: '650px'
        }}
        mapStyle={mapStyle}
        ref={mapContainerRef}
        // terrain={{ source: 'mapbox-dem', exaggeration: 1 }}
        onZoom={e => {
          handleZoom(e);
        }}
        onClick={handleMapClick}
        interactiveLayerIds={[
          clusterFacilitiesLayer.id,
          clusterAccGeoLayer.id,
          clusterEqkGeoLayer.id,
          clusterNcwGeoLayer.id,
          'sigu-layer',
          'guGoyang-layer',
          'dongGoyang-layer',
          'unclustered-point'
        ]} // 클릭 가능한 레이어 ID 목록 추가
        // initialViewState={viewport}
        {...viewport}
        onViewportChange={nextViewport => setViewport(nextViewport)}
        onMove={handleMove}
        onMouseMove={handleLayerHover}
        onMouseLeave={handleLayerLeave}
        dragPan={true}
        dragRotate={true}
        scrollZoom={true}
        touchZoom={true}
        doubleClickZoom={true}
        touchRotate={true}
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
        <ScaleControl />
        {/*<AttributionControl />*/}

        {isVisibleFacilitiesClusterMarkers && (
          <Source
            id="facilitiesClusterSource"
            type="geojson"
            data={facilitiesClusterMarkers}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterFacilitiesLayer} />
            <Layer {...clusterFacilitiesCountLayer} />
            <Layer {...unclusteredFacilitiesPointLayer} />
          </Source>
        )}

        {isVisibleAccGeoClusterMarkers && (
          <Source
            id="accGeoClusterSource"
            type="geojson"
            data={selectedAccGeo}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterAccGeoLayer} />
            <Layer {...clusterAccGeoCountLayer} />
            <Layer {...unclusteredAccGeoPointLayer} />
          </Source>
        )}

        {isVisibleEqkGeoClusterMarkers && (
          <Source
            id="EqkGeoClusterSource"
            type="geojson"
            data={selectedEqkGeo}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterEqkGeoLayer} />
            <Layer {...clusterEqkGeoCountLayer} />
            <Layer {...unclusteredEqkGeoPointLayer} />
          </Source>
        )}

        {isVisibleAsosStnGeoMarker && (
          <Source
            id="asosStnGeoClusterSource"
            type="geojson"
            data={selectedAsosGeo}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterAsosStnGeoLayer} />
            <Layer {...clusterAsosStnGeoCountLayer} />
            <Layer {...unclusteredAsosStnGeoPointLayer} />
          </Source>
        )}

        {isVisibleNcwStnGeoMarker && (
          <Source
            id="ncwGeoClusterSource"
            type="geojson"
            data={selectedNcwGeo}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterNcwGeoLayer} />
            <Layer {...clusterNcwGeoCountLayer} />
            <Layer {...unclusteredNcwGeoPointLayer} />
          </Source>
        )}

        <div
          style={{
            position: 'relative',
            top: '16px',
            left: '16px',
            right: '16px',
            zIndex: '1'
          }}
        >
          <Flex direction={'row'} justifyContent={'start'} className={'pb-2'}>
            <Dropdown onSelect={onSelectMapStyle} className={'me-3'}>
              <Dropdown.Toggle variant="falcon-default" size="sm">
                {!selectedMapStyle ? selectedMapStyle : 'Map Style'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item key="1" eventKey="streets-v12">
                  Streets
                </Dropdown.Item>
                <Dropdown.Item key="2" eventKey="outdoors-v12">
                  Outdoors
                </Dropdown.Item>
                <Dropdown.Item key="3" eventKey="light-v11">
                  Light
                </Dropdown.Item>
                <Dropdown.Item key="4" eventKey="dark-v11">
                  Dark
                </Dropdown.Item>
                <Dropdown.Item key="5" eventKey="satellite-streets-v12">
                  Satellite Streets
                </Dropdown.Item>
                <Dropdown.Item key="6" eventKey="navigation-day-v1">
                  Navigation Day
                </Dropdown.Item>
                <Dropdown.Item key="6" eventKey="navigation-night-v1">
                  Navigation Night
                </Dropdown.Item>
                {/*<Dropdown.Divider />*/}
                {/*<Dropdown.Item key="7" eventKey="standard">*/}
                {/*  Standard*/}
                {/*</Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleBuildings ? '3D건물 OFF' : '3D건물 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleBuildings ? 'primary' : 'secondary'}
                onClick={() => setIsVisibleBuildings(!isVisibleBuildings)}
                className={'me-1'}
              >
                <FaBuilding />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleBuildings ? '시설물 OFF' : '시설물 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleFacilityMarker ? 'primary' : 'secondary'}
                onClick={() =>
                  setIsVisibleFacilityMarker(!isVisibleFacilityMarker)
                }
                className={'me-1'}
              >
                <FaTent />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleSenserMarker ? '센서 OFF' : '센서 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleSenserMarker ? 'primary' : 'secondary'}
                onClick={() => setIsVisibleSensorMarker(!isVisibleSenserMarker)}
                className={'me-1'}
              >
                <FaShop />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleFacilityBoundary ? '영역 OFF' : '영역 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleFacilityBoundary ? 'primary' : 'secondary'}
                onClick={() =>
                  setIsVisibleFacilityBoundary(!isVisibleFacilityBoundary)
                }
                className={'me-1'}
              >
                <FaIcicles />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleBoreHoleMarker ? '시추공 OFF' : '시추공 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleBoreHoleMarker ? 'primary' : 'secondary'}
                onClick={() =>
                  setIsVisibleBoreHoleMarker(!isVisibleBoreHoleMarker)
                }
                className={'me-1'}
              >
                <FaRulerVertical />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleKintexSigu ||
                  isVisibleKintexGuGoyang ||
                  isVisibleKintexDongGoyang
                    ? '행정구역 OFF'
                    : '행정구역 ON'}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={
                  isVisibleKintexSigu ||
                  isVisibleKintexGuGoyang ||
                  isVisibleKintexDongGoyang
                    ? 'primary'
                    : 'secondary'
                }
                onClick={ShowCityDistricts}
                className={'me-1'}
              >
                <FaMap />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleHazardMap ? '예측구역 OFF' : '예측구역 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleHazardMap ? 'primary' : 'secondary'}
                onClick={() => setIsVisibleHazardMap(!isVisibleHazardMap)}
                className={'me-1'}
              >
                <FaCrosshairs />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleAccGeoClusterMarkers
                    ? '지반침하사고 OFF'
                    : '지반침하사고 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={
                  isVisibleAccGeoClusterMarkers ? 'primary' : 'secondary'
                }
                onClick={() =>
                  selectedAccGeo.features.length > 0
                    ? setIsVisibleAccGeoClusterMarkers(
                        !isVisibleAccGeoClusterMarkers
                      )
                    : null
                }
                style={{
                  cursor:
                    selectedAccGeo.features.length > 0
                      ? 'pointer'
                      : 'not-allowed'
                }}
                className={'me-1'}
              >
                <FaArrowsDownToLine />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleEqkGeoClusterMarkers
                    ? '지진사고 OFF'
                    : '지진사고 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={
                  isVisibleEqkGeoClusterMarkers ? 'primary' : 'secondary'
                }
                onClick={() =>
                  selectedEqkGeo.features.length > 0
                    ? setIsVisibleEqkGeoClusterMarkers(
                        !isVisibleEqkGeoClusterMarkers
                      )
                    : null
                }
                style={{
                  cursor:
                    selectedEqkGeo.features.length > 0
                      ? 'pointer'
                      : 'not-allowed'
                }}
                className={'me-1'}
              >
                <FaBolt />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleAsosStnGeoMarker
                    ? '기상관측소 OFF'
                    : '기상관측소 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleAsosStnGeoMarker ? 'primary' : 'secondary'}
                onClick={() =>
                  selectedAsosGeo.features.length > 0
                    ? setIsVisibleAsosStnGeoMarker(!isVisibleAsosStnGeoMarker)
                    : null
                }
                style={{
                  cursor:
                    selectedAsosGeo.features.length > 0
                      ? 'pointer'
                      : 'not-allowed'
                }}
                className={'me-1'}
              >
                <FaTowerObservation />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleNcwStnGeoMarker ? '관측정 OFF' : '관측정 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleNcwStnGeoMarker ? 'primary' : 'secondary'}
                onClick={() =>
                  selectedNcwGeo.features.length > 0
                    ? setIsVisibleNcwStnGeoMarker(!isVisibleNcwStnGeoMarker)
                    : null
                }
                style={{
                  cursor:
                    selectedNcwGeo.features.length > 0
                      ? 'pointer'
                      : 'not-allowed'
                }}
                className={'me-1'}
              >
                <FaWandMagic />
              </Button>
            </OverlayTrigger>
          </Flex>
        </div>
        {popupInfo && (
          <Popup
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeButton={false}
            closeOnClick={false}
            anchor="top"
          >
            <div>{popupInfo.name}</div>
          </Popup>
        )}
        {popupInfo && (
          <Popup
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeButton={false}
            closeOnClick={false}
            anchor="top"
          >
            <div>
              <strong>{popupInfo.type === 'length' ? '길이' : '면적'}:</strong>{' '}
              {popupInfo.value}
            </div>
          </Popup>
        )}
        {safetyPopupInfo && (
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '45px',
              background: 'white',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
              zIndex: 1000
            }}
          >
            <div
              className="card text-center shadow-sm"
              style={{ width: '160px' }}
            >
              <div className="card-body p-3">
                {/* Location Name */}
                <h6 className="mb-3 text-dark fw-bold">
                  {safetyPopupInfo.name}
                </h6>

                {/* Safety Grade and Score */}
                <div className="d-flex justify-content-between">
                  {/* Safety Grade */}
                  <div className="text-center flex-fill">
                    <small className="fw-b">안전등급</small>
                    <div
                      className="display-4 fw-bold mt-1"
                      style={{
                        color:
                          safetyPopupInfo.grade === 'A'
                            ? '#27ae60'
                            : safetyPopupInfo.grade === 'B'
                            ? '#2980b9'
                            : safetyPopupInfo.grade === 'C'
                            ? '#f1c40f'
                            : safetyPopupInfo.grade === 'D'
                            ? '#e67e22'
                            : '#e74c3c'
                      }}
                    >
                      {safetyPopupInfo.grade}
                    </div>
                  </div>

                  {/* Safety Score */}
                  <div className="text-center flex-fill">
                    <small className="fw-b">안전점수</small>
                    <div className="display-4 fw-bold mt-1 text-secondary">
                      {safetyPopupInfo.riskScore}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isVisibleHazardMap && (
          <Source
            id="cities-district"
            type="geojson"
            // data={cityData}
            data={{
              type: 'FeatureCollection',
              features: cityDistrictData.features
            }}
          >
            <Layer
              id="city-district-layer"
              beforeId={'building'}
              type="fill"
              paint={{
                'fill-color': [
                  'step',
                  ['get', 'ITEMT'],
                  getColor('success'), // 기본값 (0 ~ 6 녹색)
                  7,
                  '#FFFF00', // ITEMT가 7일 때 노란색
                  8,
                  getColor('warning'), // ITEMT가 8일 때 주황색
                  9,
                  getColor('danger') // ITEMT가 9 이상일 때 빨간색
                ],
                'fill-opacity': 0.7
              }}
            />
            <Layer
              id="city-district-line-layer"
              beforeId={'building'}
              type="line"
              paint={{
                'line-color': '#FFF',
                'line-width': 2
              }}
            />
          </Source>
        )}
        {isVisibleKintexSigu && (
          <Source
            id="kintexSigu"
            type="geojson"
            // data={cityData}
            data={{ type: 'FeatureCollection', features: SiGu.features }}
          >
            <Layer
              id="sigu-layer"
              type="fill"
              paint={{
                'fill-color': [
                  'step',
                  ['at', 30, ['get', 'predVal']],
                  '#ffffff', // 기본 색상
                  0.5,
                  '#fff1f1', // 0.5 이상
                  0.801,
                  '#ffb5b5', // 0.801 이상
                  0.851,
                  '#ff7979', // 0.851 이상
                  0.901,
                  '#ff3c3c', // 0.901 이상
                  0.951,
                  '#ff0000' // 0.951 이상
                ],

                'fill-opacity': 0.7
              }}
            />
            <Layer
              id="sigu-layer-line"
              type="line"
              paint={{
                'line-color': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#999', // 호버 시 색상
                  '#FFF' // 기본 색상
                ],
                'line-width': 3
              }}
            />
            <Layer
              id="sigu-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point',
                'text-allow-overlap': false,
                'text-field': [
                  'concat',
                  ['at', 0, ['get', 'rgnKo']],
                  ' ',
                  ['at', 1, ['get', 'rgnKo']]
                ],
                'text-size': 14,
                'text-anchor': 'center',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
              }}
              paint={{
                'text-color': '#000000', // Text color
                'text-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  1, // Show text when hover
                  0 // Hide text when not hover
                ]
              }}
            />
          </Source>
        )}
        {isVisibleKintexGuGoyang && (
          <Source
            id="kintexGuGoyang"
            type="geojson"
            // data={cityData}
            data={{
              type: 'FeatureCollection',
              features: guGoyangData.features
            }}
          >
            <Layer
              id="guGoyang-layer"
              type="fill"
              paint={{
                'fill-color': [
                  'step',
                  ['at', 30, ['get', 'predVal']],
                  '#ffffff', // 기본 색상
                  0.5,
                  '#fff1f1', // 0.5 이상
                  0.801,
                  '#ffb5b5', // 0.801 이상
                  0.851,
                  '#ff7979', // 0.851 이상
                  0.901,
                  '#ff3c3c', // 0.901 이상
                  0.951,
                  '#ff0000' // 0.951 이상
                ],
                'fill-opacity': 0.7
              }}
            />
            <Layer
              id="guGoyang-layer-line"
              type="line"
              paint={{
                'line-color': '#FFF',
                'line-width': 3
              }}
            />
            <Layer
              id="guGoyang-layer-hover-line"
              type="line"
              filter={['==', ['get', 'rgnCode'], hoveredFeatureId]} // 호버한 구의 ID와 일치하는 경우에만 그리기
              paint={{
                'line-color': '#999',
                'line-width': 3
              }}
            />
            <Layer
              id="guGoyang-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point',
                'text-allow-overlap': false,
                'text-field': [
                  'concat',
                  ['at', 0, ['get', 'rgnKo']],
                  ' ',
                  ['at', 1, ['get', 'rgnKo']],
                  ' ',
                  ['at', 2, ['get', 'rgnKo']]
                ],
                'text-size': 14,
                'text-anchor': 'center',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
              }}
              paint={{
                'text-color': '#000000',
                'text-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  1, // Show text when hover
                  0 // Hide text when not hover
                ]
              }}
            />
          </Source>
        )}
        {isVisibleKintexDongGoyang && selectedKintexDongGoyang.length > 0 && (
          <Source
            id="kintexDongGoyang"
            type="geojson"
            data={{
              type: 'FeatureCollection',
              features: selectedKintexDongGoyang
            }}
          >
            <Layer
              id="dongGoyang-layer"
              type="fill"
              paint={{
                'fill-color': [
                  'step',
                  ['at', 30, ['get', 'predVal']],
                  '#ffffff', // 기본 색상
                  0.5,
                  '#fff1f1', // 0.5 이상
                  0.801,
                  '#ffb5b5', // 0.801 이상
                  0.851,
                  '#ff7979', // 0.851 이상
                  0.901,
                  '#ff3c3c', // 0.901 이상
                  0.951,
                  '#ff0000' // 0.951 이상
                ],
                'fill-opacity': 0.7
              }}
            />
            <Layer
              id="dongGoyang-layer-line"
              type="line"
              paint={{
                'line-color': '#FFF',
                'line-width': 3
              }}
            />
            <Layer
              id="dongGoyang-layer-hover-line"
              type="line"
              filter={['==', ['get', 'rgnCode'], hoveredFeatureId]}
              paint={{
                'line-color': '#999',
                'line-width': 3
              }}
            />
            <Layer
              id="dongGoyang-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point',
                'text-allow-overlap': false,
                'text-field': ['concat', ['at', 0, ['get', 'rgnKo']]],
                'text-size': 14,
                'text-anchor': 'center',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
              }}
              paint={{
                'text-color': '#000000'
              }}
            />
          </Source>
        )}

        {!isEmpty(selectedKintextDongGoyangLine) && (
          <Source
            id="selected-sub-districts"
            type="geojson"
            data={selectedKintextDongGoyangLine}
          >
            <Layer
              id="selected-sub-district-layer-line"
              type="line"
              paint={{
                'line-color': getColor('primary'),
                'line-width': 5
              }}
              //onClick={handleDistrictClick}
            />
            <Layer
              id="selected-sub-district-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point',
                'text-allow-overlap': false,
                'text-field': ['get', 'ADM_NM'],
                'text-size': 18,
                'text-anchor': 'center',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
              }}
              paint={{
                'text-color': '#ffffff'
              }}
            />
          </Source>
        )}

        {isVisibleBoreHoleMarker &&
          !isEmpty(boreHoles) &&
          boreHoles.map(boreHole => (
            <Marker
              key={boreHole.id}
              longitude={boreHole.longitude}
              latitude={boreHole.latitude}
              anchor="center"
            >
              <div
                onMouseEnter={() => handleBoreHoleMarkerHover(boreHole.id)}
                onMouseLeave={handleBoreHoleMarkerLeave}
                // onClick={() => onClickBoreHoleMarker(boreHole.id)}
              >
                <img
                  src={boreHoleMarker}
                  style={{
                    width: '45px',
                    height: '45px'
                  }}
                  alt={boreHole.boreHoleNo}
                />
              </div>
            </Marker>
          ))}

        {hoveredBoreHoleMarker && (
          <Popup
            longitude={hoveredBoreHoleMarker.longitude}
            latitude={hoveredBoreHoleMarker.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredBoreHoleMarker.boreholeNo}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                {hoveredBoreHoleMarker.exploration.value}
              </h6>
            </Flex>
          </Popup>
        )}

        {/* {isVisibleSenserMarker &&
          !isEmpty(sensors) &&
          sensors.map(sensor => (
            <Marker
              key={sensor.id}
              longitude={sensor.location.longitude}
              latitude={sensor.location.latitude}
              anchor="center"
            >
              <div
                onMouseEnter={() => handleSensorMarkerHover(sensor.id)}
                onMouseLeave={handleSensorMarkerLeave}
                onClick={() => onClickSenserMarker(sensor.id)}
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  backgroundColor:
                    sensor.status === 'ON'
                      ? getColor('primary')
                      : sensor.status === 'OFF'
                      ? getColor('warning')
                      : sensor.status === 'ERR'
                      ? getColor('danger')
                      : getColor('secondary'), // 센서 빠진 경우
                  opacity: blinkingSensors.has(sensor.id) ? 0.2 : 1, // 깜빡임 효과 적용
                  cursor: 'pointer'
                }}
              />
              <h6>{sensor.sensor.value}</h6>
            </Marker>
          ))} */}

        {isVisibleSenserMarker &&
          !isEmpty(sensorHoles) &&
          sensorHoles.map(sensorHole => {
            // 센서 데이터를 sensorHoles와 매핑
            const sensor = sensors.find(sensor => sensor.id === sensorHole.id);

            return (
              <Marker
                key={sensorHole.id}
                longitude={sensorHole.location?.longitude || 0} // 안전하게 접근
                latitude={sensorHole.location?.latitude || 0} // 안전하게 접근
                anchor="center"
              >
                <div
                  onMouseEnter={() => handleSensorMarkerHover(sensorHole.id)}
                  onMouseLeave={handleSensorMarkerLeave}
                  onClick={() => onClickSenserMarker(sensorHole.id)}
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor:
                      sensor?.sensorHole === null // sensorHole이 null인지 확인
                        ? getColor('secondary')
                        : sensor?.status === 'ON'
                        ? getColor('primary')
                        : sensor?.status === 'OFF'
                        ? getColor('warning')
                        : sensor?.status === 'ERR'
                        ? getColor('danger')
                        : getColor('secondary'),
                    opacity: blinkingSensors.has(sensorHole.id) ? 0.2 : 1, // 깜빡임 효과 적용
                    cursor: 'pointer'
                  }}
                />
                <h6>{sensorHole.name || 'N/A'}</h6>{' '}
              </Marker>
            );
          })}

        {hoveredBoreHoleMarker && (
          <Popup
            longitude={hoveredBoreHoleMarker.longitude}
            latitude={hoveredBoreHoleMarker.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredBoreHoleMarker.boreholeNo}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                {hoveredBoreHoleMarker.exploration.value}
              </h6>
            </Flex>
          </Popup>
        )}

        {hoveredFacilityMarker && (
          <Popup
            longitude={hoveredFacilityMarker.location.longitude}
            latitude={hoveredFacilityMarker.location.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredFacilityMarker.name}
                </h6>
                <SoftBadge
                  pill
                  bg={
                    hoveredFacilityMarker.status === '4'
                      ? 'primary'
                      : hoveredFacilityMarker.status === '3'
                      ? 'info'
                      : hoveredFacilityMarker.status === '2'
                      ? 'secondary'
                      : hoveredFacilityMarker.status === '1'
                      ? 'warning'
                      : hoveredFacilityMarker.status === '0'
                      ? 'danger'
                      : 'dark'
                  }
                  className="fs--2 text-800 ms-1"
                >
                  {hoveredFacilityMarker.status === '4'
                    ? 'A등급'
                    : hoveredFacilityMarker.status === '3'
                    ? 'B등급'
                    : hoveredFacilityMarker.status === '2'
                    ? 'C등급'
                    : hoveredFacilityMarker.status === '1'
                    ? 'D등급'
                    : hoveredFacilityMarker.status === '0'
                    ? 'E등급'
                    : '등급확인필요'}
                </SoftBadge>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                {hoveredFacilityMarker.address}
              </h6>
            </Flex>
          </Popup>
        )}

        {hoveredAsosStnGeoMarker && (
          <Popup
            longitude={hoveredAsosStnGeoMarker.geometry.coordinates[0]}
            latitude={hoveredAsosStnGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredAsosStnGeoMarker.properties.STN_KO}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                지상관측지점 생성일 : {hoveredAsosStnGeoMarker.properties.TM}
              </h6>
            </Flex>
          </Popup>
        )}

        {hoveredNcwStnGeoMarker && (
          <Popup
            longitude={hoveredNcwStnGeoMarker.geometry.coordinates[0]}
            latitude={hoveredNcwStnGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredNcwStnGeoMarker.properties.addr}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                데이터작성일 : {hoveredNcwStnGeoMarker.properties.DATE}
              </h6>
              <h6 className={'fs--2 text-700'}>
                관측정 : {hoveredNcwStnGeoMarker.properties.well}
              </h6>
            </Flex>
          </Popup>
        )}

        {hoveredEqkGeoMarker && (
          <Popup
            longitude={hoveredEqkGeoMarker.geometry.coordinates[0]}
            latitude={hoveredEqkGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredEqkGeoMarker.properties.eqPt}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                발생시간 : {hoveredEqkGeoMarker.properties.eqDate}
              </h6>
              <h6 className={'fs--2 text-700'}>
                규모 : {hoveredEqkGeoMarker.properties.magMl}
              </h6>
            </Flex>
          </Popup>
        )}

        {hoveredAccGeoMarker && (
          <Popup
            longitude={hoveredAccGeoMarker.geometry.coordinates[0]}
            latitude={hoveredAccGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredAccGeoMarker.properties.fullAdr}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                발생원인 : {hoveredAccGeoMarker.properties.sagoDetail}
              </h6>
              <h6 className={'fs--2 text-700'}>
                발생시간 : {hoveredAccGeoMarker.properties.sagoDate}
              </h6>
            </Flex>
          </Popup>
        )}

        {isVisibleSenserMarker && hoveredSensorMarker && (
          <Popup
            longitude={hoveredSensorMarker.location.longitude}
            latitude={hoveredSensorMarker.location.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'row'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              {!isEmpty(hoveredSensorMarker.images) &&
                hoveredSensorMarker.images.length > 0 && (
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    alignContent={'center'}
                    className={'pe-3'}
                  >
                    <img
                      style={{ width: '120px', height: '160px' }}
                      src={
                        process.env.REACT_APP_API_SERVER +
                        'api/file/' +
                        hoveredSensorMarker.images[0]
                      }
                    />
                  </Flex>
                )}
              <Flex
                direction={'column'}
                alignItems={'start'}
                alignContent={'center'}
              >
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '60px' }}
                  >
                    센서명:
                  </div>
                  <div className={'fs--2 text-700'} style={{ width: '100px' }}>
                    {hoveredSensorMarker.name}
                  </div>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '60px' }}
                  >
                    상태:
                  </div>
                  <SoftBadge
                    pill
                    bg={
                      hoveredSensorMarker.status === 'ON'
                        ? 'primary'
                        : hoveredSensorMarker.status === 'OFF'
                        ? 'warning'
                        : hoveredSensorMarker.status === 'ERR'
                        ? 'danger'
                        : 'dark'
                    }
                    className="fs--2 text-800"
                  >
                    {hoveredSensorMarker.status === 'ON'
                      ? 'ON'
                      : hoveredSensorMarker.status === 'OFF'
                      ? 'OFF'
                      : hoveredSensorMarker.status === 'ERR'
                      ? 'ERR'
                      : '등급확인필요'}
                  </SoftBadge>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '60px' }}
                  >
                    배터리:
                  </div>
                  <div className={'fs--2 text-700'} style={{ width: '100px' }}>
                    {!isEmpty(hoveredSensorMarker.battery)
                      ? hoveredSensorMarker.battery
                      : '-'}
                  </div>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '120px' }}
                  >
                    마지막 통신시간:
                  </div>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'center'}
                  alignContent={'end'}
                >
                  <div
                    className={'fs--2 text-700 text-end'}
                    style={{ width: '160px' }}
                  >
                    {hoveredSensorMarker.properties?.SSRAWDATE}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Popup>
        )}

        {isVisibleFacilityBoundary && !isEmpty(facilityPolygons) && (
          <Source id="polygons" type="geojson" data={facilityPolygons}>
            <Layer
              id="polygonLayer"
              type="fill"
              paint={{
                'fill-color': getColor('secondary'),
                'fill-opacity': 0.3
              }}
            />
            <Layer
              type="line"
              paint={{
                'line-color': getColor('danger'), // 테두리 선 색상
                'line-width': 2 // 테두리 선 두께
              }}
            />
          </Source>
        )}

        {isVisibleBuildings && mapStyleName !== 'standard' && (
          <>
            <Layer {...buildingLayerStyle} />
            <Layer {...skyLayerStyle} />
          </>
        )}
      </Map>
      {predictDetailShow && (
        <PredictDetail
          feature={predictDetailFeature}
          detailModalShow={predictDetailShow}
          detailModalShowEvent={predictDetailModalShowEvent}
        />
      )}
      {sensorDetailShow && (
        <SensorDetail
          sensosrId={selectedSensorId}
          detailModalShow={sensorDetailShow}
          detailModalShowEvent={sensorDetailModalShowEvent}
        />
      )}
      {detailShow && (
        <MapboxItemDetail
          facilityId={selectedFacilitiesId}
          detailModalShow={detailShow}
          detailModalShowEvent={detailModalShowEvent}
        />
      )}
    </>
  );
};

export default MapboxIndex;
