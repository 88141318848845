import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  getRealTimeDistance,
  getRealTimeAngle,
  getRealDataSend,
  getSlopeAngels,
  getSolidationsSubsidendces,
  getFluctuations,
  getFacilityPredictedSafety,
  getSubsidenceMapImages,
  getGeoLogicalCrossSectionImages
} from '../api';

export function useRealTimeDistance() {
  const [realTimeDistanceFacilityId, setRealTimeDistanceFacilityId] =
    useState('');
  const [realTimeDistanceSearchPeriod, setRealTimeDistanceSearchPeriod] =
    useState('1M');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    [
      'getRealTimeDistance',
      realTimeDistanceFacilityId,
      realTimeDistanceSearchPeriod
    ],
    () =>
      getRealTimeDistance(
        realTimeDistanceFacilityId,
        realTimeDistanceSearchPeriod
      ),
    {
      // select: selectFilterTabValue,
      staleTime: 0,
      cacheTime: 300000,
      refetchInterval: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    realTimeDistanceFacilityId,
    setRealTimeDistanceFacilityId,
    realTimeDistanceSearchPeriod,
    setRealTimeDistanceSearchPeriod
  };
}
export function useRealTimeAngle() {
  const [realTimeAngleFacilityId, setRealTimeAngleFacilityId] = useState('');
  const [realTimeAngleSearchPeriod, setRealTimeAngleSearchPeriod] =
    useState('1M');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getRealTimeAngle', realTimeAngleFacilityId, realTimeAngleSearchPeriod],
    () => getRealTimeAngle(realTimeAngleFacilityId, realTimeAngleSearchPeriod),
    {
      // select: selectFilterTabValue,
      staleTime: 0,
      cacheTime: 300000,
      refetchInterval: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    realTimeAngleFacilityId,
    setRealTimeAngleFacilityId,
    realTimeAngleSearchPeriod,
    setRealTimeAngleSearchPeriod
  };
}

export function useRealDataSend() {
  const [realDataSendFacilityId, setRealDataSendFacilityId] = useState('');
  const [realDataSendSearchPeriod, setRealDataSendSearchPeriod] =
    useState('1M');

  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    ['getRealDataSend', realDataSendFacilityId, realDataSendSearchPeriod],
    () => getRealDataSend(realDataSendFacilityId, realDataSendSearchPeriod),
    {
      // select: selectFilterTabValue,
      staleTime: 0,
      cacheTime: 300000,
      refetchInterval: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    realDataSendFacilityId,
    setRealDataSendFacilityId,
    realDataSendSearchPeriod,
    setRealDataSendSearchPeriod
  };
}

export function useFacilityPredictedSafety() {
  const [
    facilityPredictedSafetyFacilityId,
    setFacilityPredictedSafetyFacilityId
  ] = useState('');
  const [
    facilityPredictedSafetySearchPeriod,
    setFacilityPredictedSafetySearchPeriod
  ] = useState('1M');

  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery(
    [
      'getFacilityPredictedSafety',
      facilityPredictedSafetyFacilityId,
      facilityPredictedSafetySearchPeriod
    ],
    () =>
      getFacilityPredictedSafety(
        facilityPredictedSafetyFacilityId,
        facilityPredictedSafetySearchPeriod
      ),
    {
      // select: selectFilterTabValue,
      staleTime: 0,
      cacheTime: 300000,
      refetchInterval: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    facilityPredictedSafetyFacilityId,
    setFacilityPredictedSafetyFacilityId,
    facilityPredictedSafetySearchPeriod,
    setFacilityPredictedSafetySearchPeriod
  };
}

export function useSlopeAngles() {
  const [slopeAnglesSensorId, setSlopeAnglesSensorId] = useState('');
  const [slopeAnglesSearchPeriod, setSlopeAnglesSearchPeriod] = useState('1M');

  const { data = [] } = useQuery(
    ['getSlopeAngels', slopeAnglesSensorId, slopeAnglesSearchPeriod],
    () => getSlopeAngels(slopeAnglesSensorId, slopeAnglesSearchPeriod),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    slopeAnglesSensorId,
    setSlopeAnglesSensorId,
    slopeAnglesSearchPeriod,
    setSlopeAnglesSearchPeriod
  };
}

export function useSolidationsSubsidences() {
  const [solidationsSensorId, setSolidationsSensorId] = useState('');
  const [solidationsSearchPeriod, setSolidationsSearchPeriod] = useState('1M');

  const { data = [] } = useQuery(
    [
      'getSolidationsSubsidendces',
      solidationsSensorId,
      solidationsSearchPeriod
    ],
    () =>
      getSolidationsSubsidendces(solidationsSensorId, solidationsSearchPeriod),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    solidationsSensorId,
    setSolidationsSensorId,
    solidationsSearchPeriod,
    setSolidationsSearchPeriod
  };
}

export function useFluctuations() {
  const [fluctuationsSensorId, setFluctuationsSensorId] = useState('');
  const [fluctuationsSearchPeriod, setFluctuationsSearchPeriod] =
    useState('1M');

  const { data = [] } = useQuery(
    ['getFluctuations', fluctuationsSensorId, fluctuationsSearchPeriod],
    () => getFluctuations(fluctuationsSensorId, fluctuationsSearchPeriod),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    fluctuationsSensorId,
    setFluctuationsSensorId,
    fluctuationsSearchPeriod,
    setFluctuationsSearchPeriod
  };
}

export function useSubsidenceMapImages() {
  const [facilityId, setFacilityId] = useState('');
  const [startDate, setParamStartDate] = useState('');
  const [endDate, setParamEndDate] = useState('');

  const { data = [] } = useQuery(
    ['getSubsidenceMapImages', facilityId, startDate, endDate],
    () => getSubsidenceMapImages(facilityId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    facilityId,
    setFacilityId,
    startDate,
    setParamStartDate,
    endDate,
    setParamEndDate
  };
}

export function useGeoLogicalCrossSectionImages() {
  const [facilityId, setFacilityId] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [startDate, setParamStartDate] = useState('');
  const [endDate, setParamEndDate] = useState('');

  const { data = [] } = useQuery(
    [
      'getGeoLogicalCrossSectionImages',
      facilityId,
      sectionId,
      startDate,
      endDate
    ],
    () =>
      getGeoLogicalCrossSectionImages(
        facilityId,
        sectionId,
        startDate,
        endDate
      ),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    facilityId,
    setFacilityId,
    sectionId,
    setSectionId,
    startDate,
    setParamStartDate,
    endDate,
    setParamEndDate
  };
}
