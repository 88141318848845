import { Button, Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import RealTimeContent from './tab/RealTimeContent';
import EventContent from './tab/EventContent';
import React, { useContext, useEffect, useState } from 'react';
import { chainAxios } from 'helpers/chainAxios';
import { isEmpty } from 'helpers/utils';
import SoftBadge from 'components/common/SoftBadge';
import MapboxMinimap from './MapboxMinimap';
import EventCountChart from './chart/EventCountChart';
import SensorVerticalChart from './chart/SensorVerticalChart';
import ConsolidationVerticalChart from './chart/ConsolidationVerticalChart';
import IndicatorsVerticalChart from './chart/IndicatorsVerticalChart';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';
import BatterVerticalChart from './chart/BatterVerticalChart';
import MapboxSensorContent from '../sensor-detail/tab/MapboxSensorContent';
import SubsidenceMapContent from './tab/SubsidenceMapContent';
import CrossSectionContent from './tab/CrossSectionContent';
import ModelingContent from './tab/ModelingContent';
import ModelViewModal from './model-view/modal';
import { MapsContext } from '../../../../context/Context';

const MapboxItemDetail = ({
  facilityId,
  detailModalShow,
  detailModalShowEvent
}) => {
  const {
    isShowModelView,
    setIsShowModelView,
    modelViewArgs,
    setModelViewArgs
  } = useContext(MapsContext);

  const [facilityInfo, setFacilityInfo] = useState({});

  useEffect(() => {
    if (detailModalShow) {
      getFacilityInfo();
    }
  }, [detailModalShow]);
  const getFacilityInfo = () => {
    let url = '/api/facility/' + facilityId;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ItemDetail facilityInfo() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'MapboxItemDetail facilityInfo() : ',
          //   response.data.data.facility
          // );
          setFacilityInfo(response.data.data.facility);
        }
      })
      .catch(error => {
        console.log('facilityInfo list() : ', error);
      });
  };
  const makeTitle = () => {
    let projectName = '-';
    let facilityName = '-';
    if (
      !isEmpty(facilityInfo.project) &&
      !isEmpty(facilityInfo.project.value)
    ) {
      projectName = facilityInfo.project.value;
    }

    if (facilityInfo.name !== null && facilityInfo.name !== undefined) {
      facilityName = facilityInfo.name;
    }

    return '[' + projectName + '] ' + facilityName;
  };

  const makeFacilityBadge = () => {
    let badgeColor;
    let badgeName;

    if (facilityInfo.status === '0') {
      badgeName = 'E등급';
      badgeColor = 'danger';
    } else if (facilityInfo.status === '1') {
      badgeName = 'D등급';
      badgeColor = 'warning';
    } else if (facilityInfo.status === '2') {
      badgeName = 'C등급';
      badgeColor = 'secondary';
    } else if (facilityInfo.status === '3') {
      badgeName = 'B등급';
      badgeColor = 'info';
    } else if (facilityInfo.status === '4') {
      badgeName = 'A등급';
      badgeColor = 'primary';
    } else {
      badgeName = '등급확인필요';
      badgeColor = 'dark';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="ms-2 fs--0 text-800">
        {badgeName}
      </SoftBadge>
    );
  };

  const modelViewModelShowEvent = () => {
    setIsShowModelView(!isShowModelView);
  };

  return (
    <>
      {isShowModelView && (
        <ModelViewModal
          modelViewArgs={modelViewArgs}
          modelViewModelShow={isShowModelView}
          modelViewModelShowEvent={modelViewModelShowEvent}
        />
      )}
      <Modal
        dialogClassName="modal-65w"
        // size="xl"
        show={detailModalShow}
        onHide={detailModalShowEvent}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Flex direction="column">
            <Modal.Title id="example-custom-modal-styling-title">
              {makeTitle()}
              {makeFacilityBadge()}
            </Modal.Title>
            <div className="fs--1">
              {' '}
              {facilityInfo.address !== null ||
              facilityInfo.address !== undefined
                ? facilityInfo.address
                : ''}
            </div>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-3 pb-3">
            <Col xs={12} lg={4}>
              <Flex
                direction="column"
                justifyContent="start"
                className={'h-100'}
              >
                {/*<div className="h-100">*/}
                {/*<MiniMap facilityInfo={facilityInfo} />*/}
                <MapboxMinimap facilityInfo={facilityInfo} />
                {/*</div>*/}
              </Flex>
            </Col>
            <Col xs={12} lg={8}>
              <Row className="g-3 mb-3">
                <Col xs={12} md={6} lg={3}>
                  <SensorVerticalChart facilityId={facilityInfo.id} />
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <BatterVerticalChart facilityId={facilityInfo.id} />
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <ConsolidationVerticalChart
                    facilityId={facilityInfo.id}
                    facilityType={facilityInfo.type}
                  />
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <IndicatorsVerticalChart
                    facilityId={facilityInfo.id}
                    facilityType={facilityInfo.type}
                  />
                </Col>
              </Row>
              <Row className="g-3">
                <Col xs={12}>
                  <EventCountChart facilityInfo={facilityInfo} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {/*<Tabs*/}
              {/*  defaultActiveKey="REAL"*/}
              {/*  id="fill-tab-example"*/}
              {/*  // activeKey={selectedTabValue}*/}
              {/*  // onSelect={onTabChange}*/}
              {/*  // className="mb-3"*/}
              {/*  // fill*/}
              {/*>*/}
              <Card className={classNames('overflow-hidden')}>
                <Tab.Container id="audience-tab" defaultActiveKey="REAL">
                  <SimpleBarReact>
                    <Card.Header className="p-0 bg-light">
                      <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                        <Nav.Item>
                          <Nav.Link className="mb-0 py-3 px-4" eventKey="REAL">
                            <h6 className={'mb-0'}>실시간 정보</h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="mb-0 py-3 px-4"
                            eventKey="SENSOR"
                          >
                            <h6 className={'mb-0'}>개별 센서 정보</h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="mb-0 py-3 px-4" eventKey="EVENT">
                            <h6 className={'mb-0'}>이벤트 정보</h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="mb-0 py-3 px-4"
                            eventKey="SUBSIDENCE"
                          >
                            <h6 className={'mb-0'}>침하지도</h6>
                          </Nav.Link>
                        </Nav.Item>
                        {!isEmpty(facilityInfo) &&
                          facilityInfo.type !== 'FD' && (
                            <Nav.Item>
                              <Nav.Link
                                className="mb-0 py-3 px-4"
                                eventKey="CROSS"
                              >
                                <h6 className={'mb-0'}>지질단면도</h6>
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        {/*{!isEmpty(facilityInfo) &&*/}
                        {/*  facilityInfo.type === 'FD' && (*/}
                        {/*    <Nav.Item>*/}
                        {/*      <Nav.Link*/}
                        {/*        className="mb-0 py-3 px-4"*/}
                        {/*        eventKey="MODELING"*/}
                        {/*      >*/}
                        {/*        <h6 className={'mb-0'}>3D모델링</h6>*/}
                        {/*      </Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*  )}*/}
                      </Nav>
                    </Card.Header>
                  </SimpleBarReact>
                  <Card.Body className={'p-0'}>
                    <Tab.Content>
                      <Tab.Pane unmountOnExit eventKey="REAL">
                        <RealTimeContent facilityId={facilityId} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey="SENSOR">
                        {/*<SensorContent facilityId={facilityId} />*/}
                        <MapboxSensorContent facilityId={facilityId} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey="EVENT">
                        <EventContent facilityId={facilityId} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey="SUBSIDENCE">
                        <SubsidenceMapContent facilityInfo={facilityInfo} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey="CROSS">
                        <CrossSectionContent facilityInfo={facilityInfo} />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey="MODELING">
                        <ModelingContent facilityId={facilityId} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Tab.Container>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn_close"
            variant="secondary"
            onClick={detailModalShowEvent}
          >
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MapboxItemDetail;
