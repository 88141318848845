import { Card, Col, Form, Row } from 'react-bootstrap';
import Flex from '../../common/Flex';
import { getColor } from '../../../helpers/utils';
import React from 'react';

const StatisticsTotal = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xl={12} xxl={12}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>종합 통계</h5>
            </Card.Header>
            <Card.Body>
              <Row
                as={Flex}
                alignItems="center"
                justifyContent="end"
                className={'px-3 pt-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Row as={Flex} alignItems="center" justifyContent="end">
                  <Col xs={12} md={2} className={'pb-3'}>
                    <Form.Select size="sm" className="me-md-2">
                      <option value={'project_all'}>프로젝트 전체</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} md={2} className={'pb-3'}>
                    <Form.Select size="sm" className="me-md-2">
                      <option value={'facility_all'}>시설물 전체</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} md={2} className={'pb-3'}>
                    <Form.Select size="sm" className="me-md-2">
                      <option value={'level_all'}>이벤트 등급 전체</option>
                      <option value={'0'}>심각</option>
                      <option value={'1'}>경고</option>
                      <option value={'2'}>주의</option>
                      <option value={'3'}>관찰</option>
                      <option value={'4'}>알림</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Row>
            </Card.Body>
            <Card.Footer
              style={{ backgroundColor: getColor('gray-100') }}
            ></Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StatisticsTotal;
