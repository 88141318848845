import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { chainAxios } from 'helpers/chainAxios';
import AdvanceTableSearchBox from '../../common/advance-table/AdvanceTableSearchBox';
import moment from 'moment/moment';
import SoftBadge from '../../common/SoftBadge';
import IconButton from '../../common/IconButton';
import { useNavigate } from 'react-router-dom';
import { ActionContext, ChatContext } from '../../../context/Context';
import ResponsivePagination from 'react-responsive-pagination';
import { getColor, isEmpty } from '../../../helpers/utils';

const SensorEvents = ({ sensor }) => {
  const columnEvents = [
    // {
    //   accessor: 'no',
    //   Header: 'No',
    //   headerProps: { className: 'text-900' },
    //   Cell: rowData => {
    //     const { index } = rowData.row;
    //     return (
    //       <Flex alignItems="center">
    //         <div className="flex-1" style={{ width: '50px' }}>
    //           <h5 className="mb-0 fs--1">{totalElements - index}</h5>
    //         </div>
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'createDate',
      Header: '발생일시',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { createDate } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <div
              className={'fs--1 text-truncate text-center flex-1 text-800'}
              style={{ width: '140px' }}
            >
              {moment(createDate).format('YYYY-MM-DD HH:mm:ss')}
            </div>
            {/*<div className={'fs--1'} style={{ width: '100px' }}>*/}
            {/*  {moment(createDate).format('HH:mm:ss')}*/}
            {/*</div>*/}
          </Flex>
        );
      }
    },
    {
      accessor: 'title',
      Header: '이벤트 대상',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { title } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div
              className={'fs--1 text-truncate text-center flex-1 text-800'}
              style={{ width: '150px' }}
            >
              {title}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'eventLevel',
      Header: '이벤트등급',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { eventLevel } = rowData.row.original;
        let badgeColor;
        let badgeName;
        if (eventLevel === '0') {
          badgeName = '심각';
          badgeColor = 'danger';
        } else if (eventLevel === '1') {
          badgeName = '경고';
          badgeColor = 'warning';
        } else if (eventLevel === '2') {
          badgeName = '주의';
          badgeColor = 'info';
        } else if (eventLevel === '3') {
          badgeName = '관찰';
          badgeColor = 'success';
        } else if (eventLevel === '4') {
          badgeName = '알림';
          badgeColor = 'secondary';
        } else {
          badgeName = '-';
          badgeColor = 'dark';
        }

        return (
          <SoftBadge pill bg={badgeColor} className="me-2 fs--2 text-800">
            {badgeName}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'eventStatus',
      Header: '상태',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { eventStatus } = rowData.row.original;
        let value = '-';
        if (eventStatus === '0') {
          value = '점검요함';
        }
        if (eventStatus === '1') {
          value = '조치완료';
        }
        if (eventStatus === '2') {
          value = '재검필요';
        }
        if (eventStatus === '3') {
          value = '승인';
        }
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {value}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'content',
      Header: '알림내용',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { content } = rowData.row.original;
        // let displayContent = content;
        // if (content.length > 50) {
        //   displayContent = content.substring(0, 50) + '...';
        // }
        return (
          <div
            className={'fs--1 col-12 text-truncate text-start text-800'}
            style={{ width: '500px' }}
          >
            {content}
          </div>
        );
      }
    },
    {
      accessor: 'actions',
      Header: '조치내역',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { actions } = rowData.row.original;
        if (actions != null) {
          return (
            <IconButton
              variant="falcon-primary"
              className="mb-1"
              icon="link"
              transform="shrink-3"
              size="sm"
              onClick={() => onClickMoveProcess(rowData.row.original)}
            ></IconButton>
          );
        }
      }
    },
    {
      accessor: 'chatroomId',
      Header: '채팅내역',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { chatroomId } = rowData.row.original;
        if (!!chatroomId) {
          return (
            <IconButton
              variant="falcon-primary"
              className="mb-1"
              icon="comments"
              transform="shrink-3"
              size="sm"
              onClick={() => onClickMoveChatRoom(chatroomId)}
            ></IconButton>
          );
        }
      }
    }
  ];

  const navigate = useNavigate();

  const { setIsOpenChatRoom, getChatRoom } = useContext(ChatContext);
  const { setIsOpenActionDetail, setCurrentEvent } = useContext(ActionContext);

  const [sensorEvents, setSensorEvents] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 5;

  useEffect(() => {
    getSensorEvents();
  }, [sensor]);

  useEffect(() => {
    if (!isEmpty(sensorEvents)) {
      makePagingData(sensorEvents);
    }
  }, [currentPage]);

  const onClickMoveChatRoom = chatroomId => {
    // console.log('onClickMoveChatRoom : ', chatroomId);
    getChatRoom(chatroomId);
    // setIsOpenChatRoom(true);
  };

  const onClickMoveProcess = event => {
    setCurrentEvent(event);
    setIsOpenActionDetail(true);
  };

  const getSensorEvents = () => {
    let url = '/api/events?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log("EventManage getEvents : ", response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'EventManage getEvents : ',
          //   response.data.data.eventList.dataList
          // );
          let totalElements = response.data.data.eventList.totalElements;
          makeSensorEvents(response.data.data.eventList.dataList);
        } else {
          setTotalPages(1);
          setTotalElements(0);
          setSensorEvents([]);
          setDisplayEvents([]);
        }
      })
      .catch(error => {
        console.log('SensorEvents getSensorEvents : ', error);
      });
  };

  const makeSensorEvents = events => {
    // requirements.filter(({ id }) => id !== item.id)
    let filterData = events.filter(
      event => event.sensor !== null && event.sensor.key === sensor.id
    );
    filterData.sort((a, b) => {
      if (a.createDate < b.createDate) return 1;
      if (a.createDate > b.createDate) return -1;
    });

    setSensorEvents(filterData);
    setTotalPages(Math.ceil(filterData.length / _pageSize));
    setTotalElements(filterData.length);

    makePagingData(filterData);
  };

  const makePagingData = events => {
    if (events === undefined) {
      return;
    }
    // console.log(facilities);
    // console.log(currentPage);
    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= events.length) {
      endIndex = events.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      // console.log(startIndex);
      // console.log(facilities[startIndex]);
      displayValues.push(events[startIndex]);
    }

    setDisplayEvents(displayValues);
  };

  return (
    <Card>
      <Card.Header>
        <h6>센서 이벤트 목록</h6>
      </Card.Header>
      <Card.Body className={'pt-0'}>
        <Flex
          alignItems="center"
          direction="row"
          justifyContent="end"
          className={'px-3 py-3'}
          style={{ backgroundColor: getColor('gray-100') }}
        >
          <Flex alignItems="center" direction="row" justifyContent="end">
            <Flex alignItems="center" direction="row" justifyContent="start">
              <AdvanceTableSearchBox
                table
                globalFilter={''}
                setGlobalFilter={() => {}}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          direction="row"
          justifyContent="between"
          className={'px-3 pt-3'}
        >
          <Flex
            alignItems="end"
            alignContent={'end'}
            direction="column"
            justifyContent="start"
            className={'fs--1'}
          >
            총 {totalElements}건
          </Flex>
        </Flex>
        <AdvanceTableWrapper
          columns={columnEvents}
          data={displayEvents}
          sortable
          pagination
          perPage={_pageSize}
        >
          <AdvanceTable
            table
            headerClassName="text-nowrap align-middle text-center"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              hover: true,
              className: 'fs--1 mb-0 overflow-hidden text-center'
            }}
          />
          <div className="mt-3">
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage}
              maxWidth={350}
              extraClassName={'justify-content-center fs--2'}
              previousLabel="‹"
              nextLabel="›"
              // narrowBehaviour={combine(dropNav, dropEllipsis)}
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default SensorEvents;
