import React, { useState, useEffect, useContext } from 'react';

import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { chainAxios } from 'helpers/chainAxios';
import EventItems from 'components/maps/bottom-area/EventItems';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { ActionContext, ChatContext } from 'context/Context';
import Flex from 'components/common/Flex';
import { getColor, isEmpty } from 'helpers/utils';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import ResponsivePagination from 'react-responsive-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment/moment';
import SoftBadge from 'components/common/SoftBadge';
import IconButton from 'components/common/IconButton';

const EventContent = ({ facilityId }) => {
  const { setIsOpenChatRoom, getChatRoom } = useContext(ChatContext);
  const { setIsOpenActionDetail, setCurrentEvent } = useContext(ActionContext);

  const columns = [
    // {
    //   accessor: 'favorite',
    //   Header: '즐겨찾기',
    //   headerProps: { className: 'text-900 --falcon-gray-100' },
    //   Cell: rowData => {
    //     const { favorite } = rowData.row.original;
    //     return (
    //       <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
    //         <FontAwesomeIcon
    //           onClick={() => {}}
    //           icon={favorite ? 'star' : ['far', 'star']}
    //           // transform="down-2"
    //           className={classNames(
    //             { 'text-primary': favorite, 'text-300': !favorite },
    //             'cursor-pointer'
    //           )}
    //         />
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'createDate',
      Header: '발생일시',
      headerProps: { className: 'text-900', style: { width: '140px' } },
      Cell: rowData => {
        const { createDate } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <div className={'fs--1'} style={{ width: '140px' }}>
              {moment(createDate).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          </Flex>
        );
      }
    },
    // {
    //   accessor: 'eventCode',
    //   Header: '이벤트코드',
    //   headerProps: { className: 'text-900', style: { width: '80px' } },
    //   Cell: rowData => {
    //     const { eventCode } = rowData.row.original;
    //     return (
    //       <div
    //         className={'fs--1 text-truncate text-center'}
    //         style={{ width: '80px' }}
    //       >
    //         {eventCode}
    //       </div>
    //     );
    //   }
    // },
    {
      accessor: 'eventTarget',
      Header: '이벤트대상',
      headerProps: { className: 'text-900', style: { width: '80px' } },
      Cell: rowData => {
        const { eventCode } = rowData.row.original;
        let eventTypeName = '-';

        if (!isEmpty(eventCode) && eventCode.length > 3) {
          let eventType = eventCode.substring(0, 3);

          if (eventType === 'e00') {
            eventTypeName = '사용자';
          }
          if (eventType === 'e01') {
            eventTypeName = '프로젝트';
          }
          if (eventType === 'e02') {
            eventTypeName = '사이트';
          }
          if (eventType === 'e03') {
            eventTypeName = '게이트웨이';
          }
          if (eventType === 'e04') {
            eventTypeName = '센서';
          }
          if (eventType === 'e05') {
            eventTypeName = '센서분석';
          }
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            {eventTypeName}
          </div>
        );
      }
    },
    {
      accessor: 'eventType',
      Header: '이벤트분류',
      headerProps: { className: 'text-900', style: { width: '80px' } },
      Cell: rowData => {
        const { eventCode } = rowData.row.original;
        let eventTypeName = '-';
        if (!isEmpty(eventCode) && eventCode.length > 5) {
          let eventType = eventCode.substring(0, 5);

          if (
            eventType === 'e0000' ||
            eventType === 'e0100' ||
            eventType === 'e0302' ||
            eventType === 'e0405'
          ) {
            eventTypeName = '인증문제';
          }
          if (eventType === 'e0300' || eventType === 'e0400') {
            eventTypeName = '신호불량';
          }
          if (
            eventType === 'e0301' ||
            eventType === 'e0401' ||
            eventType === 'e0402' ||
            eventType === 'e0403'
          ) {
            eventTypeName = '상태불량';
          }
          if (
            eventType === 'e0200' ||
            eventType === 'e0500' ||
            eventType === 'e0501' ||
            eventType === 'e0502' ||
            eventType === 'e0503'
          ) {
            eventTypeName = '위험예측';
          }
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            {eventTypeName}
          </div>
        );
      }
    },
    {
      accessor: 'eventLevel',
      Header: '이벤트등급',
      headerProps: { className: 'text-900', style: { width: '80px' } },
      Cell: rowData => {
        const { eventLevel } = rowData.row.original;
        let badgeColor;
        let badgeName;
        if (eventLevel === '0') {
          badgeName = '심각';
          badgeColor = 'danger';
        } else if (eventLevel === '1') {
          badgeName = '경고';
          badgeColor = 'warning';
        } else if (eventLevel === '2') {
          badgeName = '주의';
          badgeColor = 'info';
        } else if (eventLevel === '3') {
          badgeName = '관찰';
          badgeColor = 'success';
        } else if (eventLevel === '4') {
          badgeName = '알림';
          badgeColor = 'secondary';
        } else {
          badgeName = '-';
          badgeColor = 'dark';
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
              {badgeName}
            </SoftBadge>
          </div>
        );
      }
    },
    {
      accessor: 'eventStatus',
      Header: '처리상태',
      headerProps: { className: 'text-900', style: { width: '60px' } },
      Cell: rowData => {
        const { eventStatus } = rowData.row.original;

        let badgeName = '-';
        let badgeColor = 'dark';

        if (eventStatus === '0') {
          badgeName = '대기';
          badgeColor = 'danger';
        }

        if (eventStatus === '1') {
          badgeName = '완료';
          badgeColor = 'primary';
        }

        if (eventStatus === '2') {
          badgeName = '진행';
          badgeColor = 'warning';
        }

        if (eventStatus === '3') {
          badgeName = '승인';
          badgeColor = 'success';
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '60px' }}
          >
            <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
              {badgeName}
            </SoftBadge>
          </div>
        );
      }
    },
    {
      accessor: 'targetName',
      Header: '발생위치',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      Cell: rowData => {
        const { site, facility, gateway, sensor } = rowData.row.original;
        // console.log(rowData.row.original);
        let targetName = '-';

        if (!isEmpty(site)) {
          targetName = '사이트 (' + site.value + ')';
        }
        if (!isEmpty(facility)) {
          targetName = '시설물 (' + facility.value + ')';
        }
        if (!isEmpty(gateway)) {
          targetName = '게이트웨이 (' + gateway.value + ')';
        }
        if (!isEmpty(sensor)) {
          targetName = '센서 (' + sensor.value + ')';
        }
        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '100px' }}
          >
            {targetName}
          </div>
        );
      }
    },
    {
      accessor: 'content',
      Header: '알림내용',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { content } = rowData.row.original;
        // let displayContent = content;
        // if (content.length > 50) {
        //   displayContent = content.substring(0, 50) + '...';
        // }
        return (
          <div
            className={'fs--1 col-12 text-truncate text-start'}
            style={{ width: '450px' }}
          >
            {content}
          </div>
        );
      }
    },
    {
      accessor: 'actions',
      Header: '조치내역',
      headerProps: { className: 'text-900', style: { width: '50px' } },
      Cell: rowData => {
        const { actions } = rowData.row.original;
        if (actions != null) {
          return (
            <div
              className={'fs--1 text-truncate text-center'}
              style={{ width: '50px' }}
            >
              <IconButton
                variant="falcon-primary"
                className="mb-1"
                icon="link"
                transform="shrink-3"
                size="sm"
                onClick={() => onClickMoveProcess(rowData.row.original)}
              ></IconButton>
            </div>
          );
        }
      }
    },
    {
      accessor: 'chatroomId',
      Header: '채팅내역',
      headerProps: { className: 'text-900', style: { width: '50px' } },
      Cell: rowData => {
        const { chatroomId } = rowData.row.original;
        if (!!chatroomId) {
          return (
            <div
              className={'fs--1 text-truncate text-center'}
              style={{ width: '50px' }}
            >
              <IconButton
                variant="falcon-primary"
                className="mb-1"
                icon="comments"
                transform="shrink-3"
                size="sm"
                onClick={() => onClickMoveChatRoom(chatroomId)}
              ></IconButton>
            </div>
          );
        }
      }
    }
  ];
  const [events, setEvents] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    getEvents();
  }, [currentPage]);

  const onClickMoveChatRoom = chatroomId => {
    // console.log('onClickMoveChatRoom : ', chatroomId);
    getChatRoom(chatroomId);
    // setIsOpenChatRoom(true);
  };

  const onClickMoveProcess = event => {
    // navigate('/event/process');
    setCurrentEvent(event);
    setIsOpenActionDetail(true);
  };

  const getEvents = () => {
    let url =
      '/api/facility/' +
      facilityId +
      '/events?pageNo=' +
      (currentPage - 1) +
      '&pageSize=' +
      _pageSize +
      '&sort=createDate$desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('EventContent getEvents() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(response.data.data.eventList.dataList);
          let totalElements = response.data.data.eventList.totalElements;
          setTotalPages(Math.ceil(totalElements / _pageSize));
          setTotalElements(totalElements);

          setEvents(response.data.data.eventList.dataList);
        }
      })
      .catch(error => {
        console.log('EventContent getEvents() : ', error);
      });
  };
  return (
    <Row className="g-3">
      <Col md={12}>
        <Card className="h-100">
          <Card.Body className={'pb-3'}>
            <Flex
              alignItems="center"
              direction="row"
              justifyContent="between"
              className={'px-3 py-3'}
              style={{ backgroundColor: getColor('gray-100') }}
            >
              <Flex alignItems="center" direction="row" justifyContent="start">
                {/*<Form.Select size="sm" className="me-3">*/}
                {/*  <option value={'status_all'}>상태 전체</option>*/}
                {/*  /!*{projectInfo.map((project, index) => (*!/*/}
                {/*  /!*  <option value={project.id}>{project.name}</option>*!/*/}
                {/*  /!*))}*!/*/}
                {/*  <option value={'BEFORE'}>예정</option>*/}
                {/*  <option value={'FINISH'}>완료</option>*/}
                {/*  <option value={'ON_ISSUE'}>점검요함</option>*/}
                {/*  <option value={'NO_ISSUE'}>진행중</option>*/}
                {/*</Form.Select>*/}
              </Flex>
              <Flex alignItems="center" direction="row" justifyContent="end">
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                >
                  <AdvanceTableSearchBox
                    table
                    globalFilter={''}
                    setGlobalFilter={() => {}}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              direction="row"
              justifyContent="between"
              className={'px-3'}
            >
              <Flex
                alignItems="end"
                alignContent={'end'}
                direction="column"
                justifyContent="start"
                className={'fs--1'}
              >
                총 {totalElements}건
              </Flex>
              {/*<Flex*/}
              {/*  alignItems={'end'}*/}
              {/*  alignContent={'end'}*/}
              {/*  direction="column"*/}
              {/*  justifyContent="end"*/}
              {/*  className={'fs--1'}*/}
              {/*>*/}
              {/*  <Form.Check*/}
              {/*    type="switch"*/}
              {/*    id="checkedSwitch"*/}
              {/*    label="즐겨찾기"*/}
              {/*    // defaultChecked*/}
              {/*  />*/}
              {/*</Flex>*/}
            </Flex>
            <AdvanceTableWrapper
              columns={columns}
              data={events}
              sortable
              pagination
              perPage={_pageSize}
            >
              <AdvanceTable
                table
                headerClassName="text-nowrap align-middle text-center"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  hover: true,
                  className: 'fs--1 mb-0 overflow-hidden text-center'
                }}
              />
            </AdvanceTableWrapper>
            {events.length !== _pageSize && (
              <div
                style={{
                  height: ((_pageSize - events.length) * 57.5).toString() + 'px'
                }}
              ></div>
            )}
            <div className="mt-3">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
                maxWidth={350}
                extraClassName={'justify-content-center fs--2'}
                previousLabel="‹"
                nextLabel="›"
                // narrowBehaviour={combine(dropNav, dropEllipsis)}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    // <div className={'pt-1'}>
    //   <AdvanceTableWrapper
    //     columns={columns}
    //     data={events}
    //     sortable
    //     pagination
    //     perPage={10}
    //   >
    //     <AdvanceTable
    //       table
    //       headerClassName="text-nowrap align-middle text-center"
    //       rowClassName="align-middle white-space-nowrap"
    //       tableProps={{
    //         hover: true,
    //         className: 'fs--1 mb-0 overflow-hidden text-center'
    //       }}
    //     />
    //     <div className="mt-3">
    //       <AdvanceTablePagination table />
    //     </div>
    //   </AdvanceTableWrapper>
    // </div>
  );
};

export default EventContent;
