import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Map, {
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
  Source,
  Layer
} from 'react-map-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { getColor } from 'helpers/utils';
import { waterpipeData } from 'data/maps/waterpipe';
import { wewerpipeData } from 'data/maps/wewerpipe';
import { heatpipeData } from 'data/maps/heatpipe';
import { gaspipeData } from 'data/maps/gaspipe';
import { escepipeData } from 'data/maps/esecpipe';
import { commupipeData } from 'data/maps/commupipe';
import { debounce } from 'lodash';
import Flex from '../../../../common/Flex';

const MapInfo = ({ feature, setDetailInfo }) => {
  const pipeWidth = 15;

  const mapContainerRef = useRef(null);

  const [isVisibleWater, setIsVisibleWater] = useState(false);
  const [isVisibleWewer, setIsVisibleWewer] = useState(false);
  const [isVisibleHeat, setIsVisibleHeat] = useState(false);
  const [isVisibleGas, setIsVisibleGas] = useState(false);
  const [isVisibleEsec, setIsVisibleEsec] = useState(false);
  const [isVisibleCommu, setIsVisibleCommu] = useState(false);

  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );

  const skyLayerStyle = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };
  const buildingLayerStyle = {
    id: '3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    paint: {
      'fill-extrusion-color': '#aaa', // 건물 색상
      'fill-extrusion-height': ['get', 'height'], // 건물 높이
      'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
      'fill-extrusion-opacity': 0.6 // 건물 투명도
    }
  };

  const [viewport, setViewport] = useState({
    width: '100vw',
    height: '100vh',
    latitude: 37.66461,
    longitude: 126.83741,
    zoom: 16,
    pitch: 25, // 카메라 각도 (기울기)
    bearing: 0 // 카메라 방향
  });

  useEffect(() => {
    // console.log('useEffect : ', feature.geometry);
    let centroid = calculateCentroid(feature);
    calculateBoundingBox(centroid, feature);
  }, [feature]);
  const onRender = e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  };

  // 중심 좌표를 계산하는 함수
  const calculateCentroid = polygon => {
    const coordinates = polygon.geometry.coordinates[0];
    const totalCoords = coordinates.length;

    let sumLat = 0;
    let sumLng = 0;

    coordinates.forEach(coord => {
      sumLat += coord[1];
      sumLng += coord[0];
    });

    return [sumLng / totalCoords, sumLat / totalCoords];
  };

  // 남서 및 북동 좌표를 계산하는 함수
  const calculateBoundingBox = (centroid, polygon) => {
    const coordinates = polygon.geometry.coordinates[0];

    let minLat = Number.POSITIVE_INFINITY;
    let maxLat = Number.NEGATIVE_INFINITY;
    let minLng = Number.POSITIVE_INFINITY;
    let maxLng = Number.NEGATIVE_INFINITY;

    coordinates.forEach(coord => {
      const [lng, lat] = coord;
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });

    const southwest = [minLng, minLat];
    const northeast = [maxLng, maxLat];

    setViewport({
      width: '100vw',
      height: '100vh',
      latitude: centroid[1],
      longitude: centroid[0],
      zoom: 16,
      pitch: 25, // 카메라 각도 (기울기)
      bearing: 0, // 카메라 방향
      maxBounds: [southwest, northeast] // 지도 이동 제한 설정
    });

    // return { southwest, northeast };
  };

  const handleMove = evt => {
    setViewport({
      latitude: evt.viewState.latitude,
      longitude: evt.viewState.longitude,
      zoom: evt.viewState.zoom,
      pitch: evt.viewState.pitch,
      bearing: evt.viewState.bearing
    });
  };

  const handleMapClick = event => {
    const features = event.features;
    if (features && features.length > 0) {
      const feature = features[0];

      let pipeName = '-';
      if (feature.layer.id === 'commu-pipe-layer') {
        pipeName = '통신관';
      }

      if (feature.layer.id === 'esec-pipe-layer') {
        pipeName = '전기관';
      }

      if (feature.layer.id === 'gas-pipe-layer') {
        pipeName = '가스관';
      }

      if (feature.layer.id === 'heat-pipe-layer') {
        pipeName = '열배관';
      }

      if (feature.layer.id === 'wewer-pipe-layer') {
        pipeName = '하수도관';
      }

      if (feature.layer.id === 'water-pipe-layer') {
        pipeName = '상수도관';
      }

      setDetailInfo({ name: pipeName, ...feature.properties });
    }
  };

  const handleLayerHover = useCallback(
    debounce(event => {
      const map = mapContainerRef.current.getMap();
      const overLayers = map.queryRenderedFeatures(event.point);

      if (overLayers.length > 0) {
        clearSelectedLayer();

        const firstLayer = overLayers[0];
        if (firstLayer.layer.id === 'commu-pipe-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['commu-pipe-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'commuPipe',
                id: hoveredFeature.properties.id
              },
              { hover: true }
            );
          }
        }

        if (firstLayer.layer.id === 'esec-pipe-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['esec-pipe-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'esecPipe',
                id: hoveredFeature.properties.id
              },
              { hover: true }
            );
          }
        }

        if (firstLayer.layer.id === 'gas-pipe-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['gas-pipe-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'gasPipe',
                id: hoveredFeature.properties.id
              },
              { hover: true }
            );
          }
        }

        if (firstLayer.layer.id === 'heat-pipe-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['heat-pipe-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'heatPipe',
                id: hoveredFeature.properties.id
              },
              { hover: true }
            );
          }
        }

        if (firstLayer.layer.id === 'wewer-pipe-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['wewer-pipe-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'wewerPipe',
                id: hoveredFeature.properties.id
              },
              { hover: true }
            );
          }
        }

        if (firstLayer.layer.id === 'water-pipe-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['water-pipe-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'waterPipe',
                id: hoveredFeature.properties.id
              },
              { hover: true }
            );
          }
        }
      }
    }, 0),
    []
  );

  const handleLayerLeave = event => {
    clearSelectedLayer();
  };

  const clearSelectedLayer = () => {
    const map = mapContainerRef.current.getMap();

    // setPopupInfo(null);

    if (isSourceExist('commuPipe')) {
      commupipeData.features.map(d => {
        map.setFeatureState(
          { source: 'commuPipe', id: d.properties.id },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('esecPipe')) {
      escepipeData.features.map(d => {
        map.setFeatureState(
          { source: 'esecPipe', id: d.properties.id },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('gasPipe')) {
      gaspipeData.features.map(d => {
        map.setFeatureState(
          { source: 'gasPipe', id: d.properties.id },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('heatPipe')) {
      heatpipeData.features.map(d => {
        map.setFeatureState(
          { source: 'heatPipe', id: d.properties.id },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('wewerPipe')) {
      wewerpipeData.features.map(d => {
        map.setFeatureState(
          { source: 'wewerPipe', id: d.properties.id },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('waterPipe')) {
      waterpipeData.features.map(d => {
        map.setFeatureState(
          { source: 'waterPipe', id: d.properties.id },
          { hover: false, selected: false }
        );
      });
    }
  };

  // 새로운 함수: 특정 소스가 존재하는지 확인
  const isSourceExist = sourceId => {
    const map = mapContainerRef.current.getMap();
    return map.getSource(sourceId) !== undefined;
  };

  return (
    <Card className="h-100">
      <FalconCardHeader title="지하매설도 지도" light titleTag="h6" />
      <Card.Body className={'p-0'}>
        <Row className="g-0 p-3 pt-0">
          <Map
            onRender={onRender}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            style={{
              borderRadius: '5px',
              position: 'relative',
              width: '100%',
              height: '28rem'
              // minHeight: '650px'
            }}
            mapStyle={mapStyle}
            ref={mapContainerRef}
            {...viewport}
            onViewportChange={nextViewport => setViewport(nextViewport)}
            onMove={handleMove}
            onClick={handleMapClick}
            onMouseMove={handleLayerHover}
            onMouseLeave={handleLayerLeave}
            interactiveLayerIds={[
              'water-pipe-layer',
              'wewer-pipe-layer',
              'heat-pipe-layer',
              'gas-pipe-layer',
              'esec-pipe-layer',
              'commu-pipe-layer'
            ]} // 클릭 가능한 레이어 ID 목록 추가
          >
            <GeolocateControl position="top-right" />
            <FullscreenControl position="top-right" />
            <NavigationControl position="top-right" />
            <ScaleControl />

            <div
              style={{
                position: 'relative',
                top: '16px',
                left: '16px',
                right: '16px',
                zIndex: '1'
              }}
            >
              <Flex
                direction={'row'}
                justifyContent={'start'}
                className={'pb-1'}
              >
                <Flex direction={'column'} className={'px-1'}>
                  <Button
                    onClick={() => {
                      setIsVisibleWater(!isVisibleWater);
                    }}
                  >
                    상수
                  </Button>
                </Flex>
                <Flex direction={'column'} className={'px-1'}>
                  <Button
                    onClick={() => {
                      setIsVisibleWewer(!isVisibleWewer);
                    }}
                  >
                    하수
                  </Button>
                </Flex>
                <Flex direction={'column'} className={'px-1'}>
                  <Button
                    onClick={() => {
                      setIsVisibleHeat(!isVisibleHeat);
                    }}
                  >
                    열
                  </Button>
                </Flex>
                <Flex direction={'column'} className={'px-1'}>
                  <Button
                    onClick={() => {
                      setIsVisibleGas(!isVisibleGas);
                    }}
                  >
                    가스
                  </Button>
                </Flex>
                <Flex direction={'column'} className={'px-1'}>
                  <Button
                    onClick={() => {
                      setIsVisibleEsec(!isVisibleEsec);
                    }}
                  >
                    전기
                  </Button>
                </Flex>
                <Flex direction={'column'} className={'px-1'}>
                  <Button
                    onClick={() => {
                      setIsVisibleCommu(!isVisibleCommu);
                    }}
                  >
                    통신
                  </Button>
                </Flex>
              </Flex>
            </div>
            <Source
              id="cities-district"
              type="geojson"
              // data={cityData}
              data={feature}
            >
              <Layer
                id="city-district-layer"
                beforeId={'building'}
                type="fill"
                paint={{
                  'fill-color': [
                    'step',
                    ['get', 'ITEMT'],
                    getColor('success'), // 기본값 (0 ~ 6 녹색)
                    7,
                    '#FFFF00', // ITEMT가 7일 때 노란색
                    8,
                    getColor('warning'), // ITEMT가 8일 때 주황색
                    9,
                    getColor('danger') // ITEMT가 9 이상일 때 빨간색
                  ],
                  'fill-opacity': 0.7
                }}
              />
              <Layer
                id="city-district-line-layer"
                beforeId={'building'}
                type="line"
                paint={{
                  'line-color': '#ffffff',
                  'line-width': 2
                }}
              />
            </Source>
            {isVisibleWater && (
              <Source
                id="waterPipe"
                type="geojson"
                // data={waterPipeData}
                data={{
                  type: 'FeatureCollection',
                  features: waterpipeData.features
                }}
              >
                <Layer
                  id="water-pipe-layer"
                  // beforeId={'building'}
                  type="line"
                  paint={{
                    'line-color': 'blue',
                    'line-width': pipeWidth,
                    'line-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      0.8, // Hover opacity
                      0.5 // Default opacity
                    ]
                  }}
                />
              </Source>
            )}
            {isVisibleWewer && (
              <Source
                id="wewerPipe"
                type="geojson"
                // data={waterPipeData}
                data={{
                  type: 'FeatureCollection',
                  features: wewerpipeData.features
                }}
              >
                <Layer
                  id="wewer-pipe-layer"
                  // beforeId={'building'}
                  type="line"
                  paint={{
                    'line-color': 'indigo',
                    'line-width': pipeWidth,
                    'line-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      0.8, // Hover opacity
                      0.5 // Default opacity
                    ]
                  }}
                />
              </Source>
            )}
            {isVisibleHeat && (
              <Source
                id="heatPipe"
                type="geojson"
                // data={waterPipeData}
                data={{
                  type: 'FeatureCollection',
                  features: heatpipeData.features
                }}
              >
                <Layer
                  id="heat-pipe-layer"
                  // beforeId={'building'}
                  type="line"
                  paint={{
                    'line-color': 'purple',
                    'line-width': pipeWidth,
                    'line-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      0.8, // Hover opacity
                      0.5 // Default opacity
                    ]
                  }}
                />
              </Source>
            )}
            {isVisibleGas && (
              <Source
                id="gasPipe"
                type="geojson"
                // data={waterPipeData}
                data={{
                  type: 'FeatureCollection',
                  features: gaspipeData.features
                }}
              >
                <Layer
                  id="gas-pipe-layer"
                  // beforeId={'building'}
                  type="line"
                  paint={{
                    'line-color': 'pink',
                    'line-width': pipeWidth,
                    'line-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      0.8, // Hover opacity
                      0.5 // Default opacity
                    ]
                  }}
                />
              </Source>
            )}
            {isVisibleEsec && (
              <Source
                id="esecPipe"
                type="geojson"
                // data={waterPipeData}
                data={{
                  type: 'FeatureCollection',
                  features: escepipeData.features
                }}
              >
                <Layer
                  id="esec-pipe-layer"
                  // beforeId={'building'}
                  type="line"
                  paint={{
                    'line-color': 'red',
                    'line-width': pipeWidth,
                    'line-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      0.8, // Hover opacity
                      0.5 // Default opacity
                    ]
                  }}
                />
              </Source>
            )}
            {isVisibleCommu && (
              <Source
                id="commuPipe"
                type="geojson"
                // data={waterPipeData}
                data={{
                  type: 'FeatureCollection',
                  features: commupipeData.features
                }}
              >
                <Layer
                  id="commu-pipe-layer"
                  // beforeId={'building'}
                  type="line"
                  paint={{
                    'line-color': 'orange',
                    'line-width': pipeWidth,
                    'line-opacity': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      0.8, // Hover opacity
                      0.5 // Default opacity
                    ]
                  }}
                />
              </Source>
            )}

            <Layer {...buildingLayerStyle} />
            <Layer {...skyLayerStyle} />
          </Map>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MapInfo;
