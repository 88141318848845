import { useMemo } from 'react';
import { isEmpty } from 'helpers/utils';
import mapMarker from 'assets/img/icons/map-marker.png';

export const useMapLayers = isDark => {
  // 클러스터 레이어
  const clusterLayer = useMemo(
    () => ({
      id: 'clusters',
      type: 'circle',
      source: 'projectsSource',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#2c7be5',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
      }
    }),
    []
  );

  // 클러스터 카운트 레이어
  const clusterCountLayer = useMemo(
    () => ({
      id: 'cluster-count',
      type: 'symbol',
      source: 'projectsSource',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-size': 14
      },
      paint: {
        'text-color': '#ffffff'
      }
    }),
    []
  );

  // 클러스터 되지 않은 포인트 레이어
  const unclusteredPointLayer = useMemo(
    () => ({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'projectsSource',
      filter: ['!', ['has', 'point_count']],
      feature: 'feature',
      layout: {
        'icon-image': 'custom-marker',
        'icon-size': 0.8,
        'text-offset': [0, 3],
        'text-field': ['get', 'name'],
        'text-size': 12,
        'text-font': ['Open Sans Bold']
      },
      paint: {
        'text-color': isDark ? '#ffffff' : 'black'
      }
    }),
    [isDark]
  );

  return { clusterLayer, clusterCountLayer, unclusteredPointLayer };
};

// 프로젝트 마커
export const useProjectMarkers = projects => {
  return useMemo(() => {
    if (isEmpty(projects)) return { type: 'FeatureCollection', features: [] };

    let markers = projects.map(project => {
      let road = 0;
      let facility = 0;
      let field = 0;

      if (!isEmpty(project.facilities) && project.facilities.length > 0) {
        project.facilities.forEach(facility => {
          if (facility.type === 'RD') road += 1;
          if (facility.type === 'FC') facility += 1;
          if (facility.type === 'FD') field += 1;
        });
      }

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [project.location.longitude, project.location.latitude]
        },
        properties: {
          id: project.id,
          name: project.name,
          status: project.status,
          sensors: project.sensors,
          location: project.location,
          sites: { road, facility, field },
          address: !isEmpty(project.properties)
            ? project.properties.PJADDRESS
            : '-',
          order: !isEmpty(project.properties)
            ? project.properties.PJORDER
            : '-',
          constructor: !isEmpty(project.properties)
            ? project.properties.PJCONSTRUCTOR
            : '-',
          manager: !isEmpty(project.properties)
            ? project.properties.PJMANAGER
            : '-',
          imageUrl: mapMarker
        }
      };
    });

    return { type: 'FeatureCollection', features: markers };
  }, [projects]);
};
