import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Card, Row } from 'react-bootstrap';
import { chainAxios } from 'helpers/chainAxios';
import { getColor, isEmpty } from 'helpers/utils';
import Map, {
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
  Marker
} from 'react-map-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AppContext from 'context/Context';
import SensorDetail from '../sensor-detail';
import PropTypes from 'prop-types';

const buildingLayerStyle = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 15,
  paint: {
    'fill-extrusion-color': '#aaa', // 건물 색상
    'fill-extrusion-height': ['get', 'height'], // 건물 높이
    'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
    'fill-extrusion-opacity': 0.6 // 건물 투명도
  }
};

const MapboxMinimap = ({ facilityInfo }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const mapContainerRef = useRef(null);

  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );

  const [facilitySensors, setFacilitySensors] = useState({});
  const [facilityPolygons, setFacilityPolygons] = useState({});

  const [selectedSensorId, setSelectedSensorId] = useState(null);
  const [sensorDetailShow, setSensorDetailShow] = useState(false);

  useEffect(() => {
    if (!isEmpty(facilityInfo)) {
      getMiniMapSensors();
      makeFacilityPolygons();

      console.log('facilityInfo : ', facilityInfo);

      mapContainerRef.current?.flyTo({
        center: [
          facilityInfo.location.longitude,
          facilityInfo.location.latitude
        ],
        duration: 2000
      });
    }
  }, [facilityInfo]);

  useEffect(() => {
    if (isDark) {
      setMapStyle(`mapbox://styles/mapbox/dark-v11`);
    } else {
      setMapStyle(`mapbox://styles/mapbox/streets-v12`);
    }
  }, [isDark]);

  const getMiniMapSensors = () => {
    // http://112.175.18.232:7000/api/facility/19274ac9-6dcd-4d2e-8454-dd33731d5803/sensors?pageNo=0&pageSize=20&sort=createDate%24desc
    let url =
      '/api/facility/' +
      facilityInfo.id +
      '/sensors?pageNo=0&pageSize=9999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getSensors : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getSensors : ', response.data.data.sensorList.dataList);
          setFacilitySensors(response.data.data.sensorList.dataList);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const makeFacilityPolygons = () => {
    let polygons = [];

    if (facilityInfo.boundary === null) {
      return;
    }
    // console.log('MakeFacilitiesBoundary : ', facilitie.boundary);
    let lineCoordinates = [];

    facilityInfo.boundary.forEach(b => {
      lineCoordinates.push([b.longitude, b.latitude]);
    });

    polygons.push({
      id: facilityInfo.id,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [lineCoordinates]
      }
    });

    // console.log({ type: 'FeatureCollection', features: polygons });
    setFacilityPolygons({ type: 'FeatureCollection', features: polygons });
  };

  const onRender = useCallback(e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  }, []);

  const onClickSenserMarker = sensorId => {
    setSelectedSensorId(sensorId);
    setSensorDetailShow(true);
  };

  const sensorDetailModalShowEvent = () => {
    setSensorDetailShow(!sensorDetailShow);
    // setMiniMapSelectedSensor('');
  };

  const handleMapLoad = () => {
    console.log('Map loaded successfully'); //

    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    const mapboxglCtrlBottomRights = document.querySelectorAll(
      '.mapboxgl-ctrl-bottom-right'
    );
    if (mapboxglCtrlBottomRights.length > 0) {
      mapboxglCtrlBottomRights.forEach(bottomRight => {
        bottomRight.style.display = 'none';
      });
    }
  };

  return (
    <>
      {sensorDetailShow && (
        <SensorDetail
          sensosrId={selectedSensorId}
          detailModalShow={sensorDetailShow}
          detailModalShowEvent={sensorDetailModalShowEvent}
        />
      )}
      <Card className="h-100">
        <FalconCardHeader title="미니맵" light titleTag="h6" />
        <Card.Body className={'p-0'}>
          <Row className="g-0 p-3 pt-0">
            <Map
              onLoad={handleMapLoad} // 지도 로드 시 이벤트 핸들러 추가
              onRender={onRender}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              initialViewState={{
                longitude: 126.7901852,
                latitude: 37.6385557,
                pitch: 45, // 카메라 각도 (기울기)
                bearing: 0, // 카메라 방향
                zoom: 18.5
              }}
              style={{
                position: 'relative',
                width: '100%',
                height: '380px',
                minHeight: '380px'
              }}
              mapStyle={mapStyle}
              ref={mapContainerRef}
              minZoom={18}
              // terrain={{ source: 'mapbox-dem', exaggeration: 0.1 }}
            >
              {/*<GeolocateControl position="top-left" />*/}
              {/*<FullscreenControl position="top-left" />*/}
              <NavigationControl position="top-left" />
              <ScaleControl />

              {!isEmpty(facilitySensors) &&
                facilitySensors.map(sensor => (
                  <Marker
                    key={sensor.id}
                    longitude={sensor.location.longitude}
                    latitude={sensor.location.latitude}
                    anchor="center"
                  >
                    <div
                      // onMouseEnter={() => handleSensorMarkerHover(sensor.id)}
                      // onMouseLeave={handleSensorMarkerLeave}
                      onClick={() => onClickSenserMarker(sensor.id)}
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        backgroundColor:
                          sensor.status === 'ON'
                            ? getColor('primary')
                            : sensor.status === 'OFF'
                            ? getColor('warning')
                            : getColor('danger'),
                        opacity: 0.5,
                        cursor: 'pointer'
                      }}
                    />
                    <h6>{sensor.sensorHole.value}</h6>
                  </Marker>
                ))}

              <Layer {...buildingLayerStyle} />
              <Source id="polygons" type="geojson" data={facilityPolygons}>
                <Layer
                  id="polygonLayer"
                  type="fill"
                  paint={{
                    'fill-color': getColor('secondary'),
                    'fill-opacity': 0.3
                  }}
                />
                <Layer
                  type="line"
                  paint={{
                    'line-color': getColor('danger'), // 테두리 선 색상
                    'line-width': 2 // 테두리 선 두께
                  }}
                />
              </Source>
            </Map>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

MapboxMinimap.propTypes = {
  facilityInfo: PropTypes.object.isRequired
};

export default MapboxMinimap;
