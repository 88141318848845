import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from '../../../../common/IconButton';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconItem from 'components/common/icon/IconItem';
import SoftBadge from 'components/common/SoftBadge';
import CountUp from 'react-countup';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'helpers/utils';

const EventCountChart = ({ facilityInfo }) => {
  const [level0, setLevel0] = useState(0);
  const [level1, setLevel1] = useState(0);
  const [level2, setLevel2] = useState(0);
  const [level3, setLevel3] = useState(0);
  const [level4, setLevel4] = useState(0);

  const [pre, setPre] = useState(0);
  const [wip, setWip] = useState(0);
  const [done, setDone] = useState(0);
  const [approved, setApproved] = useState(0);

  useEffect(() => {
    if (!isEmpty(facilityInfo)) {
      setLevel0(facilityInfo.eventStatus.LEVEL0);
      setLevel1(facilityInfo.eventStatus.LEVEL1);
      setLevel2(facilityInfo.eventStatus.LEVEL2);
      setLevel3(facilityInfo.eventStatus.LEVEL3);
      setLevel4(facilityInfo.eventStatus.LEVEL4);

      setPre(facilityInfo.eventStatus.PRE);
      setWip(facilityInfo.eventStatus.WIP);
      setDone(facilityInfo.eventStatus.DONE);
      setApproved(facilityInfo.eventStatus.APPROVED);
    }
  }, [facilityInfo]);

  return (
    <Card className="h-100">
      <FalconCardHeader title="이벤트 현황" light titleTag="h6" />
      <Card.Body className={'p-0'}>
        <Row className="g-0 py-3">
          <Col xs={12} md={6} className={'border-end px-3 py-3'}>
            <Flex
              direction={'row'}
              justifyContent={'around'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 position-static"
              >
                <IconItem
                  tag="div"
                  icon={'exclamation-triangle'}
                  bg={`soft-primary`}
                  color={'primary'}
                  size="sm"
                  iconClass="fs--1"
                  className="me-2 shadow-none"
                />
                <h6 className="mb-0 flex-1">{'발생현황'}</h6>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'danger'} className="fs--1 mb-1">
                  심각
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={level0}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'warning'} className="fs--1 mb-1">
                  경고
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={level1}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'info'} className="fs--1 mb-1">
                  주의
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={level2}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'success'} className="fs--1 mb-1">
                  관찰
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={level3}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'secondary'} className="fs--1 mb-1">
                  알림
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={level4}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
            </Flex>
          </Col>
          <Col xs={12} md={6} className={'px-3 py-3'}>
            <Flex
              direction={'row'}
              justifyContent={'around'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 position-static"
              >
                <IconItem
                  tag="div"
                  icon={'check'}
                  bg={`soft-primary`}
                  color={'primary'}
                  size="sm"
                  iconClass="fs--2"
                  className="me-2 shadow-none"
                />
                <h6 className="mb-0 flex-1">{'처리현황'}</h6>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'danger'} className="fs--1 mb-1">
                  대기
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={pre}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'warning'} className="fs--1 mb-1">
                  진행
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={wip}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'info'} className="fs--1 mb-1">
                  완료
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={done}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              {/* <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'success'} className="fs--1 mb-1">
                  승인
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={approved}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex> */}
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EventCountChart;
