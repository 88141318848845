import { chainAxios } from 'helpers/chainAxios';

export const getProjectStatus = async () => {
  let url = 'api/projects/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.projectStatus;
  }
  return [];
};

export const getFacilityStatus = async () => {
  let url = 'api/facility/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.facilityList;
  }
  return [];
};

export const getGatewayStatus = async () => {
  let url = 'api/gateway/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.gatewayStatus;
  }
  return [];
};

export const getSensorStatus = async () => {
  let url = 'api/sensor/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.sensorStatus;
  }
  return [];
};

// export const getProjectsNoSearch = async () => {
//   let url = 'api/projects?pageNo=0&pageSize=5&sort=createDate$desc';
//   const response = await chainAxios.get(url);
//   if (response.data.resultCode === 200) {
//     return response.data.data.projectList.dataList;
//   }
//   return [];
// };

export const getProjectsNoSearch = async () => {
  const loginUserData = window.sessionStorage.getItem('loginUser');
  if (!loginUserData) {
    console.error('로그인 사용자 데이터가 없습니다.');
    return []; // 빈 배열 반환
  }

  const loginUser = JSON.parse(loginUserData);
  if (!loginUser || !loginUser.regions) {
    console.error('유효하지 않은 로그인 사용자 데이터입니다.');
    return []; // 빈 배열 반환
  }

  const userRegions = loginUser.regions.map(region => region.key); // 사용자의 지역 키 목록

  let url = 'api/projects?pageNo=0&pageSize=5&sort=createDate$desc';
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    const allProjects = response.data.data.projectList.dataList;

    // 사용자 지역에 속하는 프로젝트만 필터링
    const filteredProjects = allProjects.filter(project =>
      project.regions.some(region => userRegions.includes(region))
    );

    return filteredProjects;
  }
  return [];
};

export const getFacilities = async () => {
  let url = '/api/facilities?pageNo=0&pageSize=99999&sort=createDate%24desc';
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.facilityList.dataList;
  } else {
    throw new Error('Failed to fetch facilities');
  }
};

export const getDataTraffic = async (startDate, endDate) => {
  let url =
    '/api/sensor/chart/traffic?startDate=' + startDate + '&endDate=' + endDate;
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart;
  }
  return [];
};

export const getSingleMultiAnalysis = async () => {
  let url = 'api/sensor/chart/sawStatus';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.status;
  }
  return [];
};

export const getSingleMultiAnalysis2 = async () => {
  let url = 'api/sensor/chart/sawStatus2';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.status;
  }
  return [];
};

export const getSubsidenceOccurrenceCity = async () => {
  let url = 'api/gateway/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return {
      three: 0,
      six: 0,
      year: 0
    };
  }
  return [];
};

export const getSubsidenceOccurrenceSubCity = async () => {
  let url = 'api/gateway/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return {
      three: 0,
      six: 0,
      year: 0
    };
  }
  return [];
};

export const getSubsidenceRiskPrediction = async () => {
  let url = 'api/gateway/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return {
      e: 10,
      d: 16,
      c: 20
    };
  }
  return [];
};

export const getSubsidenceRiskPredictionResult = async () => {
  let url = 'api/gateway/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return {
      a: 3,
      b: 7,
      c: 38
    };
  }
  return [];
};
