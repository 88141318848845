import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  FaArrowsDownToLine,
  FaBolt,
  FaMap,
  FaTowerObservation,
  FaWandMagic
} from 'react-icons/fa6';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Map, {
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
  Popup
} from 'react-map-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { debounce } from 'lodash';
import { MapsContext } from '../../context/Context';
import { useNavigate } from 'react-router-dom';
import Flex from '../common/Flex';

import accGeoMarker from 'assets/img/icons/danger_7720588.svg';
import eqkGeoMarker from 'assets/img/icons/location_6772188.svg';
import asosStnGeoMarker from 'assets/img/icons/windsock_9473091.svg';
import ncwStnGeoMarker from 'assets/img/icons/lens_5584773.svg';

import { accGeoData } from 'data/maps/accGeo';
import { eqkGeoData } from 'data/maps/eqkGeo';
import { asosStnGeoData } from 'data/maps/asosStnGeo';
import { ncwStnGeoData } from 'data/maps/ncwStnGeo';
import { SiGu } from 'data/maps/kintex/sigu';
import { guAnsanData } from 'data/sample-map/gu-data/ansan';

const createLayerSource = (data, layerName) => {
  const size =
    data?.features && data.features.length > 0
      ? data.features[0].properties.rgnSize
      : null;
  return {
    sourceName: `${layerName}Source`,
    sourceData: data?.features || [],
    layerId: layerName,
    lineId: `${layerName}Line`,
    labelId: `${layerName}Label`,
    hover: false,
    size
  };
};

const sigunSources = [createLayerSource(SiGu, 'sigunGyeonggiLayer')];

const guSources = [createLayerSource(guAnsanData, 'guAnsanLayer')];

const mergeLayerIds = [
  ...sigunSources.map(source => source.layerId),
  ...guSources.map(source => source.layerId)
]; // 비교할 ID 리스트

const SampleMap = ({
  mapStyle = 'mapbox://styles/mapbox/streets-v12',
  mode = 'fullscreen',
  forcingResize = false
}) => {
  const { setSendRegion } = useContext(MapsContext);
  const navigate = useNavigate();

  const mapContainerRef = useRef(null);

  const [isVisibleSidoLayer, setIsVisibleSidoLayer] = useState(true);

  const [isVisibleAccGeoClusterMarkers, setIsVisibleAccGeoClusterMarkers] =
    useState(false);
  const [hoveredAccGeoMarker, setHoveredAccGeoMarker] = useState(null);

  const [isVisibleEqkGeoClusterMarkers, setIsVisibleEqkGeoClusterMarkers] =
    useState(false);
  const [hoveredEqkGeoMarker, setHoveredEqkGeoMarker] = useState(null);

  const [isVisibleAsosStnGeoMarker, setIsVisibleAsosStnGeoMarker] =
    useState(false);
  const [hoveredAsosStnGeoMarker, setHoveredAsosStnGeoMarker] = useState(null);

  const [isVisibleNcwStnGeoMarker, setIsVisibleNcwStnGeoMarker] =
    useState(false);
  const [hoveredNcwStnGeoMarker, setHoveredNcwStnGeoMarker] = useState(null);

  const [selectedNcw, setSelectedNcw] = useState({
    type: 'FeatureCollection',
    features: []
  });

  const clusterAccGeoLayer = {
    id: 'cluster-accgeo-Layers',
    type: 'circle',
    source: 'accGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#ff0000',
        100,
        '#ff0000',
        750,
        '#ff0000'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterAccGeoCountLayer = {
    id: 'cluster-accgeo-count',
    type: 'symbol',
    source: 'accGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredAccGeoPointLayer = {
    id: 'unclustered-accgeo-point',
    type: 'symbol',
    source: 'accGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'accGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const clusterEqkGeoLayer = {
    id: 'cluster-eqkgeo-Layers',
    type: 'circle',
    source: 'eqkGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#c68a12',
        100,
        '#c68a12',
        750,
        '#c68a12'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterEqkGeoCountLayer = {
    id: 'cluster-eqkgeo-count',
    type: 'symbol',
    source: 'eqkGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredEqkGeoPointLayer = {
    id: 'unclustered-eqkgeo-point',
    type: 'symbol',
    source: 'eqkGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'eqkGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const clusterAsosStnGeoLayer = {
    id: 'cluster-asosstngeo-Layers',
    type: 'circle',
    source: 'asosStnGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#27bcfd',
        100,
        '#27bcfd',
        750,
        '#27bcfd'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterAsosStnGeoCountLayer = {
    id: 'cluster-asosstngeo-count',
    type: 'symbol',
    source: 'asosStnGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredAsosStnGeoPointLayer = {
    id: 'unclustered-asosstngeo-point',
    type: 'symbol',
    source: 'asosStnGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'asosStnGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  const clusterNcwGeoLayer = {
    id: 'cluster-ncwgeo-Layers',
    type: 'circle',
    source: 'ncwGeoSource',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#000000',
        100,
        '#000000',
        750,
        '#000000'
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };
  const clusterNcwGeoCountLayer = {
    id: 'cluster-ncwgeo-count',
    type: 'symbol',
    source: 'ncwGeoSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 16
    },
    paint: {
      'text-color': '#ffffff' // 텍스트 색상을 빨간색으로 설정
    }
  };
  const unclusteredNcwGeoPointLayer = {
    id: 'unclustered-ncwgeo-point',
    type: 'symbol',
    source: 'ncwGeoSource',
    filter: ['!', ['has', 'point_count']],
    feature: 'feature',
    layout: {
      'icon-image': 'ncwGeoMarker', // 이미지 속성 가져오기
      'icon-size': 0.1,
      'icon-offset': [0, -0], // 아이콘의 위치를 y축으로 위로 10px 이동
      // 'text-offset': [0, 3],
      // 'text-field': ['get', 'name'], // 이름 속성 가져오기
      'text-size': 12,
      'text-font': ['Open Sans Bold']
    }
  };

  // 안산시 기준으로 지도 중심 설정
  const [viewport, setViewport] = useState({
    width: '100vw',
    height: '100vh',
    latitude: (37.09911 + 37.37744) / 2,
    longitude: (126.37884 + 126.93959) / 2,
    zoom: 9,
    pitch: 10, // 카메라 각도 (기울기)
    bearing: 0 // 카메라 방향
  });

  const getMapHeight = () => (mode === 'fullscreen' ? '94vh' : '532px');

  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [selectedSggCd, setSelectedSggCd] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [predValIndex, setPredValIndex] = useState(30);
  const [isProcessing, setIsProcessing] = useState(false); // 버튼 활성화 여부 상태

  // History 버튼 클릭 이벤트 핸들러
  const handleHistoryClick = () => {
    setIsProcessing(true); // 버튼 비활성화
    let index = 0;

    const interval = setInterval(() => {
      setPredValIndex(index); // 상태 업데이트

      if (index >= 30) {
        clearInterval(interval); // 30번째 값까지 도달하면 중지
        setIsProcessing(false); // 작업 종료 후 버튼 다시 활성화
      } else {
        index++;
      }
    }, 500); // 500ms 간격으로 실행 (원하는 속도로 변경 가능)
  };

  // fitBounds 함수 정의
  // function fitBounds(
  //   map,
  //   features,
  //   padding = { top: 10, bottom: 10, left: 10, right: 10 }
  // ) {
  //   const fc = Array.isArray(features)
  //     ? turf.featureCollection(features)
  //     : features.type !== 'FeatureCollection'
  //     ? turf.featureCollection([features])
  //     : features;

  //   const bbox = turf.bbox(fc);
  //   map.fitBounds(
  //     [
  //       [bbox[0], bbox[1]],
  //       [bbox[2], bbox[3]]
  //     ],
  //     {
  //       padding,
  //       easing: t => 0.5 * t * (t + 1)
  //     }
  //   );
  // }

  const skyLayerStyle = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };

  const buildingLayerStyle = {
    id: '3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa',
      'fill-extrusion-height': ['get', 'height'],
      'fill-extrusion-base': ['get', 'min_height'],
      'fill-extrusion-opacity': 0.6
    }
  };

  useEffect(() => {
    if (forcingResize && mapContainerRef.current) {
      setTimeout(() => {
        mapContainerRef.current.resize(); // 지도 크기를 재조정
      }, 100); // 약간의 딜레이를 줘서 리사이즈를 확실하게 적용
    }
  }, [forcingResize]);

  // 지도 로드 후 MapboxDraw 추가하는 함수
  const handleMapLoad = event => {
    const map = event.target;
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: false
      }
    });

    map.addControl(draw, 'top-right'); // MapboxDraw 컨트롤을 추가

    console.log('MapboxDraw added successfully'); // 성공 여부 확인 로그

    const accGeoIcon = new Image();
    accGeoIcon.src = accGeoMarker;
    accGeoIcon.onload = () => {
      map.addImage('accGeoMarker', accGeoIcon);
    };

    const eqkGeoIcon = new Image();
    eqkGeoIcon.src = eqkGeoMarker;
    eqkGeoIcon.onload = () => {
      map.addImage('eqkGeoMarker', eqkGeoIcon);
    };

    const asosStnGeoIcon = new Image();
    asosStnGeoIcon.src = asosStnGeoMarker;
    asosStnGeoIcon.onload = () => {
      map.addImage('asosStnGeoMarker', asosStnGeoIcon);
    };

    const ncwGeoIcon = new Image();
    ncwGeoIcon.src = ncwStnGeoMarker;
    ncwGeoIcon.onload = () => {
      map.addImage('ncwGeoMarker', ncwGeoIcon);
    };

    setSelectedNcw({
      type: 'FeatureCollection',
      features: ncwStnGeoData.features.filter(
        ncw => ncw.properties.well === '암반'
      )
    });

    // draw.create 이벤트를 통해 라인/폴리곤이 완성될 때 처리
    map.on('draw.create', e => {
      const feature = e.features[0]; // 그려진 도형
      const coordinates = feature.geometry.coordinates;

      if (feature.geometry.type === 'LineString') {
        const length = turf.length(feature, { units: 'kilometers' }); // km 단위 거리 계산
        const lastPoint = coordinates[coordinates.length - 1]; // 마지막 점 좌표

        setPopupInfo({
          length: length.toFixed(2), // 거리 소수점 2자리 표시
          longitude: lastPoint[0],
          latitude: lastPoint[1]
        });
      }

      if (feature.geometry.type === 'Polygon') {
        const area = turf.area(feature); // 면적 계산 (단위: 제곱미터)
        const lastPoint = coordinates[0][coordinates[0].length - 1]; // 마지막 점 좌표

        setPopupInfo({
          area: (area / 1000000).toFixed(2), // 면적을 제곱킬로미터(km²)로 변환
          longitude: lastPoint[0],
          latitude: lastPoint[1]
        });
      }
    });

    map.on('mouseenter', 'cluster-accgeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-accgeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });
    map.on('mouseenter', 'unclustered-accgeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const accGeoMarker = accGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredAccGeoMarker(accGeoMarker[0]);
      }
    });
    map.on('mouseleave', 'unclustered-accgeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredAccGeoMarker(null);
    });

    map.on('mouseenter', 'cluster-eqkgeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-eqkgeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });
    map.on('mouseenter', 'unclustered-eqkgeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const eqkGeoMarker = eqkGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredEqkGeoMarker(eqkGeoMarker[0]);
      }
    });
    map.on('mouseleave', 'unclustered-eqkgeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredEqkGeoMarker(null);
    });

    map.on('mouseenter', 'cluster-asosstngeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-asosstngeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });
    map.on('mouseenter', 'unclustered-asosstngeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const asosStnGeoMarker = asosStnGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredAsosStnGeoMarker(asosStnGeoMarker[0]);
      }
    });
    map.on('mouseleave', 'unclustered-asosstngeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredAsosStnGeoMarker(null);
    });

    map.on('mouseenter', 'cluster-ncwgeo-Layers', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'cluster-ncwgeo-Layers', () => {
      map.getCanvas().style.cursor = '';
    });
    map.on('mouseenter', 'unclustered-ncwgeo-point', e => {
      map.getCanvas().style.cursor = 'default';
      if (e.features.length > 0) {
        const ncwStnGeoMarker = ncwStnGeoData.features.filter(
          m => m.id === e.features[0].id
        );
        setHoveredNcwStnGeoMarker(ncwStnGeoMarker[0]);
      }
    });
    map.on('mouseleave', 'unclustered-ncwgeo-point', () => {
      map.getCanvas().style.cursor = '';
      setHoveredNcwStnGeoMarker(null);
    });

    const clusterAccGeoLayer = map.getLayer('cluster-accgeo-Layers');
    if (clusterAccGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-accgeo-Layers');
      mapContainerRef.current.moveLayer('cluster-accgeo-count');
      mapContainerRef.current.moveLayer('unclustered-accgeo-point');
    }

    const clusterEqkGeoLayer = map.getLayer('cluster-Eqkgeo-Layers');
    if (clusterEqkGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-Eqkgeo-Layers');
      mapContainerRef.current.moveLayer('cluster-Eqkgeo-count');
      mapContainerRef.current.moveLayer('unclustered-Eqkgeo-point');
    }

    const clusterAsosStnGeoLayer = map.getLayer('cluster-asosstngeo-Layers');
    if (clusterAsosStnGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-asosstngeo-Layers');
      mapContainerRef.current.moveLayer('cluster-asosstngeo-count');
      mapContainerRef.current.moveLayer('unclustered-asosstngeo-point');
    }

    const clusterNcwGeoLayer = map.getLayer('cluster-ncwgeo-Layers');
    if (clusterNcwGeoLayer !== undefined) {
      mapContainerRef.current.moveLayer('cluster-ncwgeo-Layers');
      mapContainerRef.current.moveLayer('cluster-ncwgeo-count');
      mapContainerRef.current.moveLayer('unclustered-ncwgeo-point');
    }

    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    const mapboxglCtrlBottomRights = document.querySelectorAll(
      '.mapboxgl-ctrl-bottom-right'
    );
    if (mapboxglCtrlBottomRights.length > 0) {
      mapboxglCtrlBottomRights.forEach(bottomRight => {
        bottomRight.style.display = 'none';
      });
    }
  };

  const handleMove = evt => {
    setViewport({
      latitude: evt.viewState.latitude,
      longitude: evt.viewState.longitude,
      zoom: evt.viewState.zoom,
      pitch: evt.viewState.pitch,
      bearing: evt.viewState.bearing
    });
  };

  const handleLayerHover = useCallback(
    debounce(event => {
      const map = mapContainerRef.current.getMap();
      const features = map.queryRenderedFeatures(event.point, {
        layers: sigunSources.map(source => source.layerId)
      });

      if (features.length > 0) {
        const firstLayer = features[0];
        const hoveredFeature = features[0];
        const { rgnKo, predVal } = hoveredFeature.properties; // 지역명과 예측 위험 점수 가져오기

        const parsePredVal = JSON.parse(predVal);
        // predVal의 마지막 값을 안전 점수로 사용
        const lastPredVal =
          Array.isArray(parsePredVal) && parsePredVal.length > 0
            ? parsePredVal[parsePredVal.length - 1]
            : null;

        const sfScore = predVal =>
          parseInt(Math.ceil(-100 * predVal ** 2 + 100));
        const sfgrade = e =>
          e >= 90
            ? 'A'
            : e >= 75
            ? 'B'
            : e >= 50
            ? 'C'
            : e >= 30
            ? 'D'
            : e >= 10
            ? 'E'
            : 'F';

        const riskScore = lastPredVal !== null ? sfScore(lastPredVal) : 0;
        const grade = sfgrade(riskScore);

        // 팝업 정보 설정
        setPopupInfo({
          name: JSON.parse(rgnKo).join(' '),
          riskScore, // 변환된 점수
          grade, // 안전 등급
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat
        });

        if (mergeLayerIds.some(id => id === firstLayer.layer.id)) {
          const currentHoverLayer = sigunSources.filter(map => map.hover);
          if (
            currentHoverLayer.length > 0 &&
            currentHoverLayer[0].layerId !== firstLayer.layer.id
          ) {
            clearSelectedLayer();
          }

          const filterLayer = sigunSources.filter(
            map => map.layerId === firstLayer.layer.id
          );

          if (filterLayer.length > 0) {
            sigunSources.map(mapSource => {
              mapSource.hover = mapSource.layerId === firstLayer.layer.id;
            });

            const featureStateFeatures = map.queryRenderedFeatures(
              event.point,
              {
                layers: [firstLayer.layer.id]
              }
            );
            if (featureStateFeatures.length > 0) {
              const hoveredFeatureState = featureStateFeatures[0];
              map.setFeatureState(
                {
                  source: firstLayer.layer.id + 'Source',
                  id: hoveredFeatureState.properties.rgnCode
                },
                { hover: true }
              );
            }
          }
        } else {
          clearSelectedLayer();

          sigunSources.map(mapSource => {
            mapSource.hover = false;
          });
        }
      } else {
        setPopupInfo(null); // 마우스가 레이어 밖으로 나가면 팝업 정보 초기화
      }
    }, 0),
    []
  );

  // 시도 클릭 시 구 레이어로 전환하는 함수
  const handleMapClick = event => {
    // const { lng, lat } = event.lngLat;
    const map = mapContainerRef.current.getMap();

    // mapContainerRef.current.flyTo({
    //   center: [lng, lat],
    //   zoom: 10,
    //   duration: 1500,
    //   pitch: 30,
    //   bearing: 0
    // });

    const features = map.queryRenderedFeatures(event.point, {
      layers: sigunSources.map(source => source.layerId)
    });

    if (features && features.length > 0) {
      const feature = features[0];

      if (feature.layer.id === 'cluster-accgeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'accGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        return;
      }

      if (feature.layer.id === 'cluster-eqkgeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'eqkGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        return;
      }

      if (feature.layer.id === 'cluster-asosstngeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'asosStnGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });

        return;
      }

      if (feature.layer.id === 'cluster-ncwgeo-Layers') {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = mapContainerRef.current.getSource(
          'ncwGeoClusterSource'
        );
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapContainerRef.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: zoom + 3,
            pitch: 45, // 카메라 각도 (기울기)
            bearing: 0, // 카메라 방향
            duration: 1500
          });
        });
        return;
      }

      const clickedLayerId = feature.layer.id;
      setSelectedLayerId(clickedLayerId);

      const selectedSource = sigunSources.find(
        source => source.layerId === clickedLayerId
      );

      if (selectedSource) {
        // 클릭된 시도에 대한 구 레이어 필터링
        const selectedSggCd = feature.properties.rgnCode;
        setSelectedSggCd(selectedSggCd);

        let filterRegion = selectedSource.sourceData.filter(
          feature => feature.properties.rgnCode === selectedSggCd
        );

        if (filterRegion.length > 0) {
          setSendRegion(filterRegion[0]);
          navigate('/maps');
        }
      }
    }
  };

  const isSourceExist = sourceId => {
    const map = mapContainerRef.current.getMap();
    return map.getSource(sourceId) !== undefined;
  };

  const clearSelectedLayer = useCallback(
    debounce(() => {
      const map = mapContainerRef.current.getMap();

      setPopupInfo(null);

      sigunSources.map(mapSource => {
        if (isSourceExist(mapSource.sourceName)) {
          mapSource.sourceData.map(d => {
            map.setFeatureState(
              { source: mapSource.sourceName, id: d.properties.rgnCode },
              { hover: false, selected: false }
            );
          });
        }
      });
    }, 0),
    []
  );

  const onRender = e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  };

  return (
    <>
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle={mapStyle}
        onClick={handleMapClick}
        onLoad={handleMapLoad} // 지도 로드 시 이벤트 핸들러 추가
        onRender={onRender}
        style={{
          borderRadius: '5px',
          position: 'relative',
          width: '100%',
          height: getMapHeight()
        }}
        ref={mapContainerRef}
        {...viewport}
        onViewportChange={nextViewport => setViewport(nextViewport)}
        onMove={handleMove}
        onMouseMove={handleLayerHover}
        dragPan={true}
        dragRotate={true}
        scrollZoom={true}
        touchZoom={true}
        doubleClickZoom={true}
        touchRotate={true}
        interactiveLayerIds={[
          clusterAccGeoLayer.id,
          clusterEqkGeoLayer.id,
          clusterAsosStnGeoLayer.id,
          clusterNcwGeoLayer.id
        ]} // 클릭 가능한 레이어 ID 목록 추가
      >
        <div
          style={{
            position: 'relative',
            top: '16px',
            left: '16px',
            right: '16px',
            zIndex: '1'
          }}
        >
          <Flex direction={'row'} justifyContent={'start'} className={'pb-2'}>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleSidoLayer ? '행정구역 OFF' : '행정구역 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleSidoLayer ? 'primary' : 'secondary'}
                onClick={() => setIsVisibleSidoLayer(!isVisibleSidoLayer)}
                className={'me-1'}
              >
                <FaMap />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleAccGeoClusterMarkers
                    ? '지반침하사고 OFF'
                    : '지반침하사고 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={
                  isVisibleAccGeoClusterMarkers ? 'primary' : 'secondary'
                }
                onClick={() =>
                  setIsVisibleAccGeoClusterMarkers(
                    !isVisibleAccGeoClusterMarkers
                  )
                }
                className={'me-1'}
              >
                <FaArrowsDownToLine />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleEqkGeoClusterMarkers
                    ? '지진사고 OFF'
                    : '지진사고 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={
                  isVisibleEqkGeoClusterMarkers ? 'primary' : 'secondary'
                }
                onClick={() =>
                  setIsVisibleEqkGeoClusterMarkers(
                    !isVisibleEqkGeoClusterMarkers
                  )
                }
                className={'me-1'}
              >
                <FaBolt />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleAsosStnGeoMarker
                    ? '기상관측소 OFF'
                    : '기상관측소 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleAsosStnGeoMarker ? 'primary' : 'secondary'}
                onClick={() =>
                  setIsVisibleAsosStnGeoMarker(!isVisibleAsosStnGeoMarker)
                }
                className={'me-1'}
              >
                <FaTowerObservation />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top" // 툴팁 위치 (top, bottom, left, right 가능)
              overlay={
                <Tooltip id="button-tooltip">
                  {isVisibleNcwStnGeoMarker ? '관측정 OFF' : '관측정 ON'}{' '}
                  {/* 툴팁 내용 */}
                </Tooltip>
              }
            >
              <Button
                size={'sm'}
                variant={isVisibleNcwStnGeoMarker ? 'primary' : 'secondary'}
                onClick={() =>
                  setIsVisibleNcwStnGeoMarker(!isVisibleNcwStnGeoMarker)
                }
                // style={{
                //   cursor: selectedNcwGeo.length > 0 ? 'pointer' : 'not-allowed'
                // }}
                className={'me-1'}
              >
                <FaWandMagic />
              </Button>
            </OverlayTrigger>
          </Flex>
          <Button
            onClick={isVisibleSidoLayer ? handleHistoryClick : null}
            disabled={isVisibleSidoLayer && isProcessing}
            variant={
              isVisibleSidoLayer && isProcessing ? 'secondary' : 'primary'
            } // 비활성화 시 스타일 변경
            size="sm"
            style={{
              position: 'absolute',
              top: '35px',
              left: '0px',
              zIndex: 10,
              padding: '8px 20px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: isVisibleSidoLayer ? 'pointer' : 'not-allowed'
            }}
          >
            History ({predValIndex + 1})
          </Button>
        </div>
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
        <ScaleControl />
        <Layer {...buildingLayerStyle} />
        <Layer {...skyLayerStyle} />
        <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.mapbox-terrain-dem-v1"
          tileSize={512}
        />
        <Layer
          id="terrain"
          type="hillshade"
          source="mapbox-dem"
          paint={{
            'hillshade-exaggeration': 0.3
          }}
        />
        {isVisibleAccGeoClusterMarkers && (
          <Source
            id="accGeoClusterSource"
            type="geojson"
            data={accGeoData}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterAccGeoLayer} />
            <Layer {...clusterAccGeoCountLayer} />
            <Layer {...unclusteredAccGeoPointLayer} />
          </Source>
        )}
        {isVisibleEqkGeoClusterMarkers && (
          <Source
            id="eqkGeoClusterSource"
            type="geojson"
            data={eqkGeoData}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterEqkGeoLayer} />
            <Layer {...clusterEqkGeoCountLayer} />
            <Layer {...unclusteredEqkGeoPointLayer} />
          </Source>
        )}

        {isVisibleAsosStnGeoMarker && (
          <Source
            id="asosStnGeoClusterSource"
            type="geojson"
            data={asosStnGeoData}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterAsosStnGeoLayer} />
            <Layer {...clusterAsosStnGeoCountLayer} />
            <Layer {...unclusteredAsosStnGeoPointLayer} />
          </Source>
        )}

        {isVisibleNcwStnGeoMarker && (
          <Source
            id="ncwGeoClusterSource"
            type="geojson"
            data={selectedNcw}
            cluster={true}
            clusterMaxZoom={15}
            clusterRadius={50}
          >
            <Layer {...clusterNcwGeoLayer} />
            <Layer {...clusterNcwGeoCountLayer} />
            <Layer {...unclusteredNcwGeoPointLayer} />
          </Source>
        )}

        {/* 시도 레이어 표시 */}
        {isVisibleSidoLayer &&
          !selectedLayerId && // 시도가 선택되지 않았을 때만 렌더링
          sigunSources.map(mapSource => (
            <Source
              id={mapSource.sourceName}
              key={mapSource.layerId}
              type="geojson"
              // data={cityData}
              data={{
                type: 'FeatureCollection',
                features: mapSource.sourceData
              }}
            >
              <Layer
                id={mapSource.layerId}
                type="fill"
                paint={{
                  'fill-color': [
                    'step',
                    ['at', predValIndex, ['get', 'predVal']],
                    '#ffffff', // 기본 색상
                    0.5,
                    '#fff1f1', // 0.5 이상
                    0.801,
                    '#ffb5b5', // 0.801 이상
                    0.851,
                    '#ff7979', // 0.851 이상
                    0.901,
                    '#ff3c3c', // 0.901 이상
                    0.951,
                    '#ff0000' // 0.951 이상
                  ],
                  'fill-opacity': 0.9
                }}
                filter={['==', ['get', 'rgnCode'], '41270']} // 안산시만 표시
              />
              <Layer
                id={mapSource.lineId}
                type="line"
                paint={{
                  'line-color': '#ffffff',
                  'line-width': 2
                }}
                filter={['==', ['get', 'rgnCode'], '41270']} // 안산시만 표시
              />
              <Layer
                id={mapSource.labelId}
                type="symbol"
                layout={{
                  'symbol-placement': 'point',
                  'text-allow-overlap': false,
                  'text-field': [
                    'format',
                    [
                      'concat',
                      ['at', 0, ['array', ['get', 'rgnKo']]],
                      ' ',
                      ['coalesce', ['at', 1, ['array', ['get', 'rgnKo']]], '']
                    ],
                    {}
                  ],
                  'text-size': 14,
                  'text-anchor': 'center',
                  'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
                }}
                paint={{
                  'text-color': '#000',
                  'text-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1, // Show text when hover
                    0 // Hide text when not hover
                  ]
                }}
                filter={['==', ['get', 'rgnCode'], '41270']} // 안산시만 표시
              />
            </Source>
          ))}

        {/* 선택된 시도에 대한 구 레이어 표시 */}
        {selectedSggCd &&
          guSources
            .filter(guSource =>
              guSource.sourceData.some(
                guFeature => guFeature.properties.colCode === selectedSggCd
              )
            )
            .map(guSource => (
              <Source
                id={`guLayerSource-${guSource.layerId}-${selectedSggCd}`}
                key={guSource.layerId}
                type="geojson"
                data={{
                  type: 'FeatureCollection',
                  features: guSource.sourceData.filter(
                    guFeature => guFeature.properties.colCode === selectedSggCd
                  )
                }}
              >
                <Layer
                  id={`guLayer-fill-${guSource.layerId}-${selectedSggCd}`}
                  type="fill"
                  paint={{
                    'fill-color': [
                      'step',
                      ['at', 30, ['get', 'predVal']],
                      '#ffffff', // 기본 색상
                      0.5,
                      '#fff1f1', // 0.5 이상
                      0.801,
                      '#ffb5b5', // 0.801 이상
                      0.851,
                      '#ff7979', // 0.851 이상
                      0.901,
                      '#ff3c3c', // 0.901 이상
                      0.951,
                      '#ff0000' // 0.951 이상
                    ],
                    'fill-opacity': 0.9
                  }}
                />
                <Layer
                  id={`guLayer-line-${guSource.layerId}-${selectedSggCd}`}
                  type="line"
                  paint={{
                    'line-color': '#ffffff',
                    'line-width': 2
                  }}
                />
                <Layer
                  id={`guLayer-label-${guSource.layerId}-${selectedSggCd}`}
                  type="symbol"
                  layout={{
                    'text-field': [
                      'concat',
                      [
                        'case',
                        ['==', ['length', ['get', 'rgnKo']], 2], // 배열 길이가 2일 경우
                        ['at', 1, ['get', 'rgnKo']], // 두 번째 요소를 가져오기
                        ['at', 0, ['get', 'rgnKo']] // 배열 길이가 1일 경우 첫 번째 요소를 가져오기
                      ]
                    ],
                    'text-size': 16
                  }}
                  paint={{
                    'text-color': '#000'
                  }}
                />
              </Source>
            ))}

        {popupInfo && (
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '45px',
              background: 'white',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
              zIndex: 1000
            }}
          >
            <div
              className="card text-center shadow-sm"
              style={{ width: '160px' }}
            >
              <div className="card-body p-3">
                {/* Location Name */}
                <h6 className="mb-3 text-dark fw-bold">{popupInfo.name}</h6>

                {/* Safety Grade and Score */}
                <div className="d-flex justify-content-between">
                  {/* Safety Grade */}
                  <div className="text-center flex-fill">
                    <small className="fw-b">안전등급</small>
                    <div
                      className="display-4 fw-bold mt-1"
                      style={{
                        color:
                          popupInfo.grade === 'A'
                            ? '#27ae60'
                            : popupInfo.grade === 'B'
                            ? '#2980b9'
                            : popupInfo.grade === 'C'
                            ? '#f1c40f'
                            : popupInfo.grade === 'D'
                            ? '#e67e22'
                            : '#e74c3c'
                      }}
                    >
                      {popupInfo.grade}
                    </div>
                  </div>

                  {/* Safety Score */}
                  <div className="text-center flex-fill">
                    <small className="fw-b">안전점수</small>
                    <div className="display-4 fw-bold mt-1 text-secondary">
                      {popupInfo.riskScore}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {hoveredAccGeoMarker && (
          <Popup
            longitude={hoveredAccGeoMarker.geometry.coordinates[0]}
            latitude={hoveredAccGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredAccGeoMarker.properties.fullAdr}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                발생원인 : {hoveredAccGeoMarker.properties.sagoDetail}
              </h6>
              <h6 className={'fs--2 text-700'}>
                발생시간 : {hoveredAccGeoMarker.properties.sagoDate}
              </h6>
            </Flex>
          </Popup>
        )}
        {hoveredEqkGeoMarker && (
          <Popup
            longitude={hoveredEqkGeoMarker.geometry.coordinates[0]}
            latitude={hoveredEqkGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredEqkGeoMarker.properties.eqPt}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                발생시간 : {hoveredEqkGeoMarker.properties.eqDate}
              </h6>
              <h6 className={'fs--2 text-700'}>
                규모 : {hoveredEqkGeoMarker.properties.magMl}
              </h6>
            </Flex>
          </Popup>
        )}

        {hoveredAsosStnGeoMarker && (
          <Popup
            longitude={hoveredAsosStnGeoMarker.geometry.coordinates[0]}
            latitude={hoveredAsosStnGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredAsosStnGeoMarker.properties.STN_KO}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                지상관측지점 생성일 : {hoveredAsosStnGeoMarker.properties.TM}
              </h6>
            </Flex>
          </Popup>
        )}
        {hoveredNcwStnGeoMarker && (
          <Popup
            longitude={hoveredNcwStnGeoMarker.geometry.coordinates[0]}
            latitude={hoveredNcwStnGeoMarker.geometry.coordinates[1]}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredNcwStnGeoMarker.properties.addr}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                데이터작성일 : {hoveredNcwStnGeoMarker.properties.DATE}
              </h6>
              <h6 className={'fs--2 text-700'}>
                관측정 : {hoveredNcwStnGeoMarker.properties.well}
              </h6>
            </Flex>
          </Popup>
        )}
      </Map>
    </>
  );
};

// props 유형을 정의
SampleMap.propTypes = {
  mapStyle: PropTypes.string,
  forcingResize: PropTypes.bool,
  mode: PropTypes.oneOf(['tab', 'fullscreen'])
};

export default SampleMap;
