import React, { useContext } from 'react';
import { LoginUserContext } from 'context/Context';

/**
 * Higher-Order Component for role-based authorization
 * @param {React.ComponentType} Component - The component to wrap
 * @param {string[]} requiredRoles - Array of roles allowed to access this component
 * @returns {React.FC} - A component that renders conditionally based on the user's role
 */
const WithAuthorization = (Component, requiredRoles) => {
  return props => {
    const { loginUser } = useContext(LoginUserContext);

    console.log('WithAuthorization loginUser.roles : ', loginUser.roles);
    console.log('WithAuthorization requiredRoles : ', requiredRoles);

    // 사용자 roles 중 하나라도 requiredRoles에 포함되면 렌더링
    const hasPermission = loginUser.roles.some(role =>
      requiredRoles.includes(role)
    );

    if (hasPermission) {
      return <Component {...props} />;
    }
    return null; // 또는 fallback UI
  };
};

export default WithAuthorization;
