// React 관련
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom'; // 라우터 네비게이션 훅
import { Card, Dropdown, Tab, Tabs } from 'react-bootstrap'; // 부트스트랩 UI 컴포넌트
import Flex from 'components/common/Flex'; // 플렉스 박스
import Map, {
  Source,
  Layer,
  NavigationControl,
  ScaleControl,
  Popup
} from 'react-map-gl'; // 맵박스 컴포넌트
import MapboxLanguage from '@mapbox/mapbox-gl-language'; // 맵박스 언어 컨트롤
import { isEmpty } from 'helpers/utils';
import { chainAxios } from 'helpers/chainAxios'; // 체인 요청
import * as turf from '@turf/turf'; // 지리 공간 데이터 처리
import AppContext, { MapsContext } from 'context/Context'; // 컨텍스트
import mapMarker from 'assets/img/icons/map-marker.png'; // 마커 이미지
import SampleMap from 'components/sample-map'; // 샘플 맵 컴포넌트
import { useProjectMarkers, useMapLayers } from '../hook/mapHooks'; // 커스텀 훅

const ClusterMapbox = () => {
  // 컨텍스트와 전역 상태 관리
  const {
    config: { isDark }
  } = useContext(AppContext); // 앱 컨텍스트
  const { getProject } = useContext(MapsContext); // 맵 컨텍스트
  const navigate = useNavigate(); // 라우터 네비게이션
  const mapRef = useRef(null); // 맵 참조
  const [mapStyle, setMapStyle] = useState(
    // 맵 스타일 (다크/라이트 모드)
    'mapbox://styles/mapbox/streets-v12'
  );
  const [projects, setProjects] = useState([]); // 프로젝트 목록
  const [viewProject] = useState({}); // 현재 보고 있는 프로젝트
  const [selectedProjectName, setSelectedProjectName] = useState(''); // 선택된 프로젝트 이름
  const [selectedTabValue, setSelectedTabValue] = useState('SUB'); // 현재 선택된 탭
  const [page] = useState(0); // 페이지 상태 추가
  const [pageSize] = useState(100); // 페이지 크기 설정

  // 클러스터 레이어 설정 - 마커들을 그룹화하여 표시
  const { clusterLayer, clusterCountLayer, unclusteredPointLayer } =
    useMapLayers(isDark);

  // 초기 데이터 로드
  useEffect(() => {
    getProjectList(page, pageSize);
    return () => {};
  }, [page, pageSize]);

  // 다크모드 변경 시 맵 스타일 업데이트
  useEffect(() => {
    if (isDark) {
      setMapStyle(`mapbox://styles/mapbox/dark-v11`); // 다크 모드 스타일
    } else {
      setMapStyle(`mapbox://styles/mapbox/streets-v12`); // 라이트 모드 스타일
    }
  }, [isDark]);

  // 프로젝트 데이터 변경 시 마커 업데이트
  useEffect(() => {}, [projects]);

  // API를 통해 프로젝트 목록을 가져오는 함수
  const getProjectList = useCallback((page, pageSize) => {
    const loginUserData = sessionStorage.getItem('loginUser');
    if (!loginUserData) {
      console.error('로그인 사용자 데이터가 없습니다.');
      return;
    }

    console.log('ClusterMapbox loginUserData : ', loginUserData);

    const loginUser = JSON.parse(loginUserData);
    if (!loginUser || !loginUser.regions) {
      console.error('유효하지 않은 로그인 사용자 데이터입니다.');
      return;
    }

    console.log('ClusterMapbox loginUser : ', loginUser);

    const userRegions = loginUser.regions.map(region => region.key); // 사용자의 지역 키 목록
    console.log('ClusterMapbox userRegions : ', userRegions);

    let url = `/api/projects?pageNo=${page}&pageSize=${pageSize}&sort=createDate%24desc`;
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          const allProjects = response.data.data.projectList.dataList;
          console.log('ClusterMapbox allProjects : ', allProjects);
          const filteredProjects = allProjects.filter(project =>
            project.regions.some(region => userRegions.includes(region))
          );
          console.log('ClusterMapbox filteredProjects : ', filteredProjects);

          setProjects(prevProjects => [...prevProjects, ...filteredProjects]);
          console.log(filteredProjects);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  // 프로젝트 데이터를 지도에 표시할 수 있는 GeoJSON 형식으로 데이터 변환
  const projectMarkers = useProjectMarkers(projects);

  // 드롭다운에서 프로젝트 선택 시 해당 위치로 지도 이동
  const onSelectProject = value => {
    let project = JSON.parse(value);

    mapRef.current?.flyTo({
      center: [project.location.longitude, project.location.latitude],
      zoom: 18,
      duration: 2000
    });

    setSelectedProjectName(project.name);
  };

  // 지도 클릭 이벤트 처리 (클러스터 확대 또는 프로젝트 선택)
  const onClick = event => {
    const feature = event.features[0];
    if (isEmpty(feature)) {
      findNearFeature(event);
      return;
    }

    // 클러스터 확대 처리
    const clusterId = feature.properties.cluster_id;
    const mapboxSource = mapRef.current.getSource('projectsSource');
    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      // 클러스터 확대 애니메이션
      mapRef.current.flyTo({
        center: feature.geometry.coordinates,
        zoom: zoom + 2,
        duration: 1000
      });
    });
  };

  // 클릭한 위치 근처의 프로젝트 찾기 (반경 0.5km 이내)
  const findNearFeature = event => {
    const point = turf.point([event.lngLat.lng, event.lngLat.lat]); // 클릭한 위치를 포인트로 변환
    const radius = 0.5; // 0.5km 반경
    const nearbyFeatures = projectMarkers.features.filter(feature => {
      return turf.distance(point, feature) <= radius; // 포인트와 프로젝트 간의 거리 계산
    });

    if (nearbyFeatures.length > 0) {
      // 찾은 프로젝트가 있으면
      getProject(nearbyFeatures[0].properties.id);
      navigate('/maps'); // 프로젝트 상세 페이지로 이동
    }
  };

  // 지도 렌더링 시 한국어 설정 및 커스텀 마커 이미지 추가
  const onRender = useCallback(e => {
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));

    const img = new Image(); // 이미지 객체 생성
    img.onload = function () {
      // 이미지 로드 완료 시
      if (!e.target.hasImage('custom-marker')) {
        // 커스텀 마커 이미지가 없으면
        e.target.addImage('custom-marker', img, {
          // 이미지 추가
          width: img.width,
          height: img.height
        });
      }
    };
    img.src = mapMarker; // 마커 이미지 경로 설정
  }, []);

  // 탭 변경 시 지도 크기 재조정
  const handleTabSelect = selectedTab => {
    setSelectedTabValue(selectedTab);
    if (selectedTab === 'SUB' && mapRef.current) {
      setTimeout(() => {
        mapRef.current.resize(); // 지도 크기를 재조정
      }, 100); // 약간의 딜레이를 줘서 리사이즈를 확실하게 적용
    }
  };

  // 지도 초기 로드 시 마우스 이벤트 및 UI 요소 설정
  const handleMapLoad = event => {
    const map = event.target;
    console.log('Map loaded successfully'); // 지도 로드 성공 메시지

    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'unclustered-point', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'unclustered-point', () => {
      map.getCanvas().style.cursor = '';
    });

    // 맵박스 로고 숨김
    const mapboxglCtrlLogos = document.querySelectorAll('.mapboxgl-ctrl-logo');
    if (mapboxglCtrlLogos.length > 0) {
      mapboxglCtrlLogos.forEach(logo => {
        logo.style.display = 'none';
      });
    }

    // 맵박스 하단 우측 버튼 숨김
    const mapboxglCtrlBottomRights = document.querySelectorAll(
      '.mapboxgl-ctrl-bottom-right'
    );
    // 존재하면 숨김
    if (mapboxglCtrlBottomRights.length > 0) {
      mapboxglCtrlBottomRights.forEach(bottomRight => {
        bottomRight.style.display = 'none';
      });
    }
  };

  return (
    <Card className="h-100">
      <Tabs
        activeKey={selectedTabValue} // 현재 선택된 탭
        id="fill-project-tab" // 탭 아이디
        className="nav m-1" // 탭 스타일
        onSelect={handleTabSelect} // 탭 선택 시 이벤트 핸들러
        fill // 탭 너비 조절
      >
        <Tab eventKey="SUB" title={<h6>프로젝트 맵</h6>}>
          <Card.Body className="pt-3">
            {/* Main Map */}
            <Map
              onLoad={handleMapLoad} // 지도 로드 시 이벤트 핸들러 추가
              onRender={onRender}
              initialViewState={{
                latitude: 37.3219461, // 초기 위도
                longitude: 126.8312067, // 초기 경도
                zoom: 10 // 초기 줌 레벨
              }}
              mapStyle={mapStyle}
              style={{ minHeight: '532px' }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              interactiveLayerIds={[clusterLayer.id]} // 상호작용 레이어 아이디
              onClick={onClick} // 지도 클릭 시 이벤트 핸들러
              ref={mapRef} // 맵 참조
            >
              {/* 프로젝트 Select */}
              <div className="position-absolute top-0 start-0 p-2">
                <Dropdown onSelect={onSelectProject}>
                  <Dropdown.Toggle variant="falcon-default">
                    {selectedProjectName
                      ? selectedProjectName
                      : '프로젝트를 선택하세요.'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    {!isEmpty(projects) &&
                      projects.map((project, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={JSON.stringify(project)}
                        >
                          {project.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* 내비게이션 컨트롤 */}
              <NavigationControl position="top-right" />
              {/* 스케일 컨트롤 */}
              <ScaleControl />
              {/* 프로젝트 소스 */}
              <Source
                id="projectsSource"
                type="geojson"
                data={projectMarkers}
                cluster={true}
                clusterMaxZoom={18}
                clusterRadius={50}
              >
                <Layer {...clusterLayer} />
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredPointLayer} />
              </Source>
              {!isEmpty(viewProject) && (
                <Popup
                  longitude={viewProject.properties.location.longitude}
                  latitude={viewProject.properties.location.latitude}
                  anchor="left"
                  closeButton={false}
                  dynamicPosition={true}
                >
                  <Flex
                    direction={'column'}
                    alignItems={'start'}
                    alignContent={'center'}
                    className={'p-2'}
                  >
                    <Flex
                      direction={'row'}
                      alignItems={'center'}
                      alignContent={'center'}
                    >
                      <h6 className={'text-700 mt-1'}>{viewProject.name}</h6>
                    </Flex>
                    <h6 className={'fs--2 text-700'}>
                      {viewProject.properties.address}
                    </h6>
                  </Flex>
                </Popup>
              )}
            </Map>
          </Card.Body>
        </Tab>
        <Tab eventKey="ROAD" title={<h6>침하예측 맵</h6>}>
          <Card.Body className="pt-3">
            {/* Prediction Map */}
            <SampleMap
              style={{ minHeight: '532px' }}
              forcingResize={selectedTabValue === 'ROAD'}
              mode={'tab'}
            />
          </Card.Body>
        </Tab>
      </Tabs>
    </Card>
  );
};

export default React.memo(ClusterMapbox);
